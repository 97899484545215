import React, { LegacyRef } from 'react';
import GoogleAutocomplete from 'react-google-autocomplete';
import ErrorMessage from './ErrorMessage';

interface Props {
    autoCompleteRef?: LegacyRef<HTMLInputElement>;
    types: string[];
    fields: string[];
    componentRestrictions: {
        country: string;
    };
    placeholder?: string;
    customClass?: string;
    handlePlaceSelect: (place: any) => void;
    handleChange?: (value: string) => void;
    errorMsg?: string;
    strictSetting?: boolean;
    handleKeyDown?: any;
    onBlur?: () => void;
    defaultValue?: string;
}
/**
 * @returns this return google locations
 */
const AutoCompleteDropdown: React.FC<Props> = ({
    autoCompleteRef,
    componentRestrictions,
    fields,
    types,
    placeholder,
    customClass = 'form-control',
    handlePlaceSelect,
    errorMsg,
    handleChange = (value = '') => {},
    strictSetting,
    handleKeyDown,
    onBlur,
    defaultValue = '',
}) => {
    return (
        <>
            <GoogleAutocomplete
                ref={autoCompleteRef}
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                onPlaceSelected={handlePlaceSelect}
                onChange={(e) => handleChange(e.currentTarget.value)}
                onKeyDown={handleKeyDown}
                options={{
                    strictBounds: strictSetting, // Enable strict bounds
                    types,
                    fields,
                    componentRestrictions, // Change this to the country you want to limit the results to
                }}
                defaultValue={defaultValue}
                // @ts-ignore
                placeholder={placeholder}
                className={customClass}
                aria-invalid={errorMsg ? 'true' : 'false'}
                onBlur={onBlur}
            />
            <ErrorMessage errorMsg={errorMsg} />
        </>
    );
};

export default AutoCompleteDropdown;
