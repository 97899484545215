import { useEffect, useState } from 'react';
import { ConstNumber } from '../../../shared/constants/number.constant';
import {
    apiEventsApi,
    useGetExternalBusinessesQuery,
} from '../slices/slice.apiEvents';
import { ExternalBusiness } from '../constants/constants.apiEvents';
import { routeObj } from '../../../shared/constants/routes.constants';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../store/hooks';
import { FilterArg } from '../../../shared/types/type';
import moment from 'moment';
import { DateFormats } from '../../../shared/constants/application.constant';

const useExternalBusiness = (filter: FilterArg) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState<number>(ConstNumber.VALUE_1);
    const [externalBusinessess, setExternalBusinessess] = useState<
        ExternalBusiness[]
    >([]);
    const [isLoadingBusinessess, setIsLoadingBusinessess] =
        useState<boolean>(true);

    const { data, isLoading, isFetching, refetch } =
        useGetExternalBusinessesQuery({
            page,
            filters: {
                ...filter,
                endDate: filter.endDate
                    ? moment(filter.endDate).format(DateFormats.YYYY_MM_DD)
                    : '',
                startDate: filter.startDate
                    ? moment(filter.startDate).format(DateFormats.YYYY_MM_DD)
                    : '',
                city: filter?.city,
            },
        });
    const hasMore = data && data?.data.length > ConstNumber.VALUE_0;

    const handleFetchNextPage = () => {
        if (!isFetching && hasMore) {
            setPage((prevPage) => prevPage + ConstNumber.VALUE_1);
        }
    };

    const resetData = () => {
        setPage(ConstNumber.VALUE_1);
        setExternalBusinessess([]);
        dispatch(apiEventsApi.util.resetApiState());
    };

    useEffect(() => {
        setPage(ConstNumber.VALUE_1);
        setExternalBusinessess([]);
        refetch();
    }, [filter.city, filter.endDate, filter.startDate]);

    useEffect(() => {
        if (data) {
            setExternalBusinessess((prevData) => [...prevData, ...data.data]);
            setIsLoadingBusinessess(false);
        }
    }, [data]);

    useEffect(() => {
        // Reset data when component unmounts or changes
        return () => {
            resetData();
        };
    }, []);

    useEffect(() => {
        if (isLoading) {
            setIsLoadingBusinessess(true);
        }
    }, [isLoading]);

    const navigateToDetail = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const target = e.target as HTMLElement;
        if (target.id) {
            navigate(
                routeObj.ADMIN_API_BUSINESS_DETAIL.replace(':id', target.id)
            );
        }
    };

    return {
        externalBusinessess,
        hasMore,
        handleFetchNextPage,
        isLoading,
        isLoadingBusinessess,
        navigateToDetail,
        isFetching,
    };
};

export default useExternalBusiness;
