import { Col, Row, Form } from 'react-bootstrap';
import {
    Control,
    Controller,
    FieldErrors,
    UseFormGetValues,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { ConstNumber } from '../constants/number.constant';
import CustomInput, { TextArea } from './CustomInput';
import MultiSelect from './MultiSelect';
import PriceRangeRadio from './PriceRangeRadio';
import UploadMedia from './UploadMedia';
import { EventFormWarehouse } from '../../modules/events/constants/types.events';
import { Option } from '../utils/types.elements';
import { DateType } from '../../modules/specials/utils/types.specials';
import CategoryCard from '../../modules/businessInfo/elements/CategoryCard';

type Props = {
    register: UseFormRegister<any>;
    errors: FieldErrors<EventFormWarehouse>;
    getValues: UseFormGetValues<any>;
    control: Control<any>;
    watch: UseFormWatch<any>;
    eventCategoryOptions: Option[];
    eventOptionSelected: Option[];
    eventSubCategory: string[];
    handleChange: (selected: Option[]) => void;
    setValue: UseFormSetValue<any>;
    show: boolean;
    setShow: (value: boolean) => void;
    handleDateChange: (date: Date | null, dateType: DateType | string) => void;
    thumbnailUrl?: string;
};

const CommonEventFields = (props: Props) => {
    const {
        register,
        errors,
        getValues,
        control,
        eventCategoryOptions,
        eventOptionSelected,
        eventSubCategory,
        handleChange,
        setValue,
        setShow,
        show,
        thumbnailUrl,
    } = props;
    return (
        <>
            <Col md={6}>
                <Row>
                    <Col sm={12}>
                        <TextArea
                            name={'description'}
                            register={register}
                            errorMsg={errors.description?.message}
                            placeholder="Event description here"
                            label="Description"
                            rows={ConstNumber.VALUE_10}
                            maxLength={ConstNumber.VALUE_1000}
                        />
                    </Col>
                    <Col md={12}>
                        <Controller
                            control={control}
                            name="price_range"
                            render={({ field: { onChange } }) => (
                                <PriceRangeRadio
                                    defaultValue={getValues('price_range')}
                                    name="price_range"
                                    register={register}
                                    handleClicked={(val) => onChange(val)}
                                />
                            )}
                        />
                    </Col>
                    <Col sm={12}>
                        <div className="form-group">
                            <UploadMedia
                                show={show}
                                setShow={setShow}
                                setValue={setValue}
                                name="document"
                                document={getValues('document' as any)}
                                thumbnailUrl={thumbnailUrl}
                            />
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={6}>
                <Row>
                    <Col sm={12}>
                        <CustomInput
                            label="Ticket URL"
                            name={'ticket_url'}
                            placeholder="Ticket URL here"
                            register={register}
                            errorMsg={errors.ticket_url?.message}
                            optional
                        />
                    </Col>
                    <Col sm={12}>
                        <Form.Group className="form-group" controlId="">
                            <MultiSelect
                                options={eventCategoryOptions}
                                label="Categories/Tags"
                                isSearchable={false}
                                isMulti
                                isClearable={false}
                                isSelectAll={true}
                                value={eventOptionSelected}
                                changeHandler={handleChange}
                                errorMsg={errors.categories_list?.message}
                                placeholder="Select Categories"
                            />
                            <CategoryCard subCategory={eventSubCategory} />
                        </Form.Group>
                    </Col>
                </Row>
            </Col>
        </>
    );
};

export default CommonEventFields;
