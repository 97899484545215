import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
    AWS_STATES,
    CognitoAuth,
    Params,
    localStorageKeys,
} from '../../../shared/constants/application.constant';
import { ToastEnum } from '../../../shared/constants/html.constant';
import { ConstNumber } from '../../../shared/constants/number.constant';
import { routeObj } from '../../../shared/constants/routes.constants';
import { verifyOTP, resendOTP } from '../slices/slice.auth';
import {
    useCompleteUserMutation,
    useVerifyTestOtpMutation,
} from '../slices/slice.authApi';
import Toast from '../../../shared/utils/toast.helper';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { VerifiedOTPSchema } from '../utils/utils';
import { Hub } from 'aws-amplify';
import { setDataIntoLocalStorage } from '../../../shared/utils/storage.helpers';
import { VerificationOTPProps } from '../utils/slice.types';

export function useVerifyOtp({ username, isSignIn }: VerificationOTPProps) {
    const dispatch = useAppDispatch();
    const { isDataLoading } = useAppSelector((state) => state.auth);
    const navigate = useNavigate();
    const [verifyTestUser, { isLoading }] = useVerifyTestOtpMutation();
    const [completeUser] = useCompleteUserMutation();
    const [canResend, setResendEnable] = useState(true);
    const cognitoAuth = process.env.REACT_APP_COGNITO_AUTH;
    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
        trigger,
    } = useForm({
        defaultValues: {
            otp: '',
        },
        resolver: yupResolver(VerifiedOTPSchema),
    });

    async function listenToAutoSignInEvent() {
        Hub.listen('auth', async ({ payload }) => {
            const { event, data } = payload;
            if (event === AWS_STATES.AUTO_SIGNIN) {
                if (data.signInUserSession) {
                    setDataIntoLocalStorage(localStorageKeys.USER, {
                        role: '',
                        access_token:
                            data.signInUserSession.accessToken?.jwtToken,
                        username: data.username,
                        expirationTime:
                            data.signInUserSession.accessToken.payload.exp,
                    });
                    await completeUser({});
                }
            } else if (event === AWS_STATES.SIGNIN_FAILED) {
                // redirect to sign in page
                Toast.error(
                    ToastEnum.SOMETHING_WENT_WRONG,
                    ToastEnum.ERROR_AUTO_SIGNIN
                );
            }
        });
    }

    const verify = async (otp: string) => {
        dispatch(
            verifyOTP({
                username,
                code: otp,
            })
        )
            .unwrap()
            .then(async () => {
                /**
                 * we have added this message for testing purpose, will remove it in upcoming PR
                 */
                Toast.success(ToastEnum.VERIFIED, ToastEnum.OTP_VERIFIED_DECS);
                await listenToAutoSignInEvent();
                navigate(routeObj.SIGN_UP_SUCCESS, {
                    state: {
                        from: Params.OTP_SCREEN,
                    },
                });
            })
            .catch((_error) => {
                Toast.error(ToastEnum.SOMETHING_WENT_WRONG, _error.message);
            });
    };

    const verifyTest = async () => {
        const res = await verifyTestUser({ username });
        if ('data' in res) {
            Toast.success(ToastEnum.VERIFIED, res.data.message);
            navigate(routeObj.SIGN_UP_SUCCESS);
        } else {
            Toast.error(ToastEnum.SOMETHING_WENT_WRONG, '');
        }
    };

    const verifySubmit = async (values: { otp: string }) => {
        if (cognitoAuth?.includes(CognitoAuth.NOT)) {
            verifyTest();
        } else {
            await verify(values.otp);
        }
    };

    const resendCode = () => {
        if (canResend) {
            dispatch(
                resendOTP({
                    username,
                })
            )
                .unwrap()
                .then(() => {
                    /**
                     * we have added this message for testing purpose, will remove it in upcoming PR
                     */
                    Toast.success(ToastEnum.OTP_SENT, ToastEnum.OTP_SEND_DECS);
                })
                .catch((_error) => {
                    Toast.error(ToastEnum.SOMETHING_WENT_WRONG, _error.message);
                });
            disableResend();
        } else {
            Toast.error(ToastEnum.RESEND, ToastEnum.RESEND_DESC);
        }
    };

    const disableResend = () => {
        setResendEnable(false);
        setTimeout(() => {
            setResendEnable(true);
        }, ConstNumber.ONE_MINUTE);
    };

    useEffect(() => {
        if (isSignIn) {
            resendCode();
        }
        disableResend();
    }, []);

    return {
        resendCode,
        verifySubmit,
        isDataLoading,
        isLoading,
        canResend,
        trigger,
        handleSubmit,
        register,
        errors,
        isValid,
    };
}
