export const routeObj = {
    DEFAULT: '/',
    BUSINESS_DASHBOARD: '/business',
    BUSINESS_SPECIALS: '/business/specials',
    BUSINESS_VENUE_DEMOGRAPHICS: '/business/venue-demographics',
    BUSINESS_VENUE_LOCATIONS: '/business/venue-locations',
    BUSINESS_VENUE_LOCATIONS_DETAILS: '/business/venue-locations/details',
    BUSINESS_VENUE_DETAILS: '/business/venue-details',
    BUSINESS_EVENTS: '/business/events',
    BUSINESS_ADVERTISING: '/business/advertising',
    BUSINESS_NOTIFICATION: '/business/notifications',
    BUSINESS_SUBSCRIPTION: '/business/subscription',
    BUSINESS_SPECIAL_CREATE: '/business/specials/create',
    BUSINESS_SPECIAL_EDIT: '/business/specials/edit',
    BUSINESS_EVENT_CREATE: '/business/events/create-update',
    SIGN_UP: '/sign-up',
    SIGN_UP_SUCCESS: '/sign-up/success',
    SIGN_UP_BUSINESS_TYPE: '/sign-up/business-type',
    SIGN_UP_SELECT_PLAN: '/sign-up/select-plan',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    BUSINESS_EVENT_DETAIL: '/business/events/edit-details',
    BUSINESS_ADVERTISING_CREATE: '/business/advertising/create',
    //Admin url
    ADMIN: '/admin',
    ADMIN_BUSINESSES: '/admin/business',
    ADMIN_BUSINESS_DETAILS: '/admin/business/detail/:id',
    ADMIN_BUSINESS_CATEGORIES: '/business/categories',
    ADMIN_BUSINESSES_CATEGORIES: '/admin/categories',
    ADMIN_EVENT_CLAIM: '/admin/event-claim',
    ADMIN_API_EVENTS: '/admin/api-events',
    ADMIN_API_EVENTS_DETAIL: '/admin/api-events/event-detail/:id',
    ADMIN_API_BUSINESS_DETAIL: '/admin/api-events/business-detail/:id',
    ADMIN_DATA_WAREHOUSE: '/admin/data-warehouse',
    ADMIN_WAREHOUSE_EVENT_DETAIL: '/admin/data-warehouse/event-detail/:id',
    ADMIN_WAREHOUSE_EVENT_VENUE: '/admin/data-warehouse/event-venue',
    ADMIN_WAREHOUSE_BUSINESS_DETAIL:
        '/admin/data-warehouse/business-detail/:id',
    ADMIN_PROMOTION_CODE: '/admin/promotion-code',
    ADMIN_CREATE_PROMOTION_CODE: '/admin/promotion-code/create',
    ADMIN_NOTIFICATION: '/admin/notification',
    ADMIN_USERS: '/admin/users',
    ADMIN_CATEGORIES_LEVEL3: '/admin/categories/level3',
    ADMIN_SUBSCRIPTIONS: '/admin/payment-plans',
    ADMIN_SUBSCRIPTIONS_CREATE: '/admin/payment-plans/create',
    ADMIN_CLAIM_EVENTS_DETAIL: '/admin/event-claim/event-detail/:id',
};
