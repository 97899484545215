export enum reducerEnums {
    SIGN_UP = 'auth/sign-up',
    VERIFY_OTP = 'auth/verify-otp',
    RESEND_OTP = 'auth/resend-otp',
    SIGN_IN = 'auth/sign-in',
    SIGN_OUT = 'auth/sign-out',
    DATA = 'auth',
    FORGOT_PASSWORD = 'auth/forgot-password',
    RESET_PASSWORD = 'auth/reset-password',
    CHANGE_PASSWORD = 'auth/change-password',
    NOTIFICATION = 'notification',
}
