import React from 'react';
import { Outlet } from 'react-router-dom';
import {
    LOGINBG
} from '../../../shared/constants/image.constant';

const WelcomeImage = () => {
    return (
        <>
        <div className="login-container">
            <div className="login-image-container">
                <img src={LOGINBG} alt="Login" />
            </div>
            <div className="login-form-container">
                <Outlet />
            </div>
        </div>

        </>
    );
};

export default WelcomeImage;
