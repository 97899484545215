import React from 'react';
import { ConstNumber } from '../../app/shared/constants/number.constant';
import { IconProps } from '../../app/shared/types/type';

export const Check = React.memo(
    ({
        width = ConstNumber.VALUE_34,
        height = ConstNumber.VALUE_34,
        stroke = '#34C759',
        strokeWidth = ConstNumber.VALUE_1,
        ...otherProps
    }: IconProps) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                stroke={stroke}
                strokeWidth={strokeWidth}
                {...otherProps}
                viewBox="0 0 34 34"
                fill="none"
            >
                <g id="Frame">
                    <path
                        id="Vector"
                        d="M17 31.1659C9.176 31.1659 2.83337 24.8232 2.83337 16.9992C2.83337 9.17515 9.176 2.83252 17 2.83252C24.824 2.83252 31.1667 9.17515 31.1667 16.9992C31.1667 24.8232 24.824 31.1659 17 31.1659ZM15.5871 22.6659L25.6045 12.6485L23.601 10.645L15.5871 18.659L11.5802 14.6519L9.57669 16.6555L15.5871 22.6659Z"
                        fill={stroke}
                    />
                </g>
            </svg>
        );
    }
);
