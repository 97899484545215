export enum ConstNumber {
    VALUE_0 = 0,
    VALUE_1 = 1,
    VALUE_2 = 2,
    VALUE_3 = 3,
    VALUE_4 = 4,
    VALUE_5 = 5,
    VALUE_6 = 6,
    VALUE_7 = 7,
    VALUE_8 = 8,
    VALUE_9 = 9,
    VALUE_10 = 10,
    VALUE_11 = 11,
    VALUE_12 = 12,
    VALUE_13 = 13,
    VALUE_14 = 14,
    VALUE_15 = 15,
    VALUE_16 = 16,
    VALUE_17 = 17,
    VALUE_18 = 18,
    VALUE_19 = 19,
    VALUE_20 = 20,
    VALUE_21 = 21,
    VALUE_22 = 22,
    VALUE_23 = 23,
    VALUE_24 = 24,
    VALUE_25 = 25,
    VALUE_26 = 26,
    VALUE_27 = 27,
    VALUE_28 = 28,
    VALUE_29 = 29,
    VALUE_30 = 30,
    VALUE_31 = 31,
    VALUE_32 = 32,
    VALUE_33 = 33,
    VALUE_34 = 34,
    VALUE_35 = 35,
    VALUE_36 = 36,
    VALUE_37 = 37,
    VALUE_38 = 38,
    VALUE_39 = 39,
    VALUE_40 = 40,
    VALUE_41 = 41,
    VALUE_42 = 42,
    VALUE_43 = 43,
    VALUE_44 = 44,
    VALUE_45 = 45,
    VALUE_46 = 46,
    VALUE_47 = 47,
    VALUE_48 = 48,
    VALUE_49 = 49,
    VALUE_50 = 50,
    VALUE_51 = 51,
    VALUE_52 = 52,
    VALUE_53 = 53,
    VALUE_54 = 54,
    VALUE_55 = 55,
    VALUE_56 = 56,
    VALUE_57 = 57,
    VALUE_58 = 58,
    VALUE_59 = 59,
    VALUE_60 = 60,
    VALUE_61 = 61,
    VALUE_62 = 62,
    VALUE_63 = 63,
    VALUE_64 = 64,
    VALUE_65 = 65,
    VALUE_66 = 66,
    VALUE_67 = 67,
    VALUE_68 = 68,
    VALUE_69 = 69,
    VALUE_70 = 70,
    VALUE_71 = 71,
    VALUE_72 = 72,
    VALUE_73 = 73,
    VALUE_74 = 74,
    VALUE_75 = 75,
    VALUE_76 = 76,
    VALUE_77 = 77,
    VALUE_78 = 78,
    VALUE_79 = 79,
    VALUE_80 = 80,
    VALUE_81 = 81,
    VALUE_82 = 82,
    VALUE_83 = 83,
    VALUE_84 = 84,
    VALUE_85 = 85,
    VALUE_86 = 86,
    VALUE_87 = 87,
    VALUE_88 = 88,
    VALUE_89 = 89,
    VALUE_90 = 90,
    VALUE_91 = 91,
    VALUE_92 = 92,
    VALUE_93 = 93,
    VALUE_94 = 94,
    VALUE_95 = 95,
    VALUE_96 = 96,
    VALUE_97 = 97,
    VALUE_98 = 98,
    VALUE_99 = 99,
    VALUE_100 = 100,
    VALUE_150 = 150,
    VALUE_200 = 200,
    VALUE_255 = 255,
    VALUE_1000 = 1000,
    VALUE_2000 = 2000,
    VALUE_2500 = 2500,
    VALUE_3000 = 3000,
    VALUE_4000 = 4000,
    VALUE_9999 = 9999,
    VALUE_1000000 = 1000000,
    VALUE_8000000 = 8000000,
    MB_100 = 100000000,
    VALUE_120 = 120,
    ONE_MINUTE = 60000,
    HALF_SECOND = 500, // in ms
    FIVE_SEC = 5000,
    SUN = 1,
    MON = 2,
    TUE = 3,
    WED = 4,
    THU = 5,
    FRI = 6,
    SAT = 7,
    BACK = -1,
}
