import { AuthNetEnvironment, HostedForm } from 'react-acceptjs';

const authData = {
    apiLoginID: process.env.REACT_APP_AUTHORIZE_LOGIN_ID as string,
    clientKey: process.env.REACT_APP_AUTHORIZE_TRANSACTION_KEY as string,
};

type Props = {
    handleSubmit: (cardEncData: any) => void;
    label: string;
    buttonClass: string;
    isDisabled?: boolean;
};

const AuthorizeNetPayment = (props: Props) => {
    const { buttonClass, handleSubmit, label, isDisabled = false } = props;
    const environment = process.env.REACT_APP_ENVIRONMENT as AuthNetEnvironment;

    return (
        <>
            <HostedForm
                buttonText={label}
                environment={environment}
                buttonClassName={buttonClass}
                authData={authData}
                onSubmit={handleSubmit}
                disabled={isDisabled}
                errorTextClassName="error"
                errorTextStyle={{
                    color: 'red',
                    fontWeight: 'bold',
                }}
            />
        </>
    );
};

export default AuthorizeNetPayment;
