import {
    useChargeCreditCardMutation,
    useGetPaymentPlanOnMountQuery,
} from '../slices/slice.authApi';
import { ChargeCCParams, SelectPlanParams } from '../utils/slice.types';
import Toast from '../../../shared/utils/toast.helper';
import { ToastEnum } from '../../../shared/constants/html.constant';
import { useEffect, useState } from 'react';
import { ErrorObj } from '../../../shared/types/type';
import { useGetMonthlyPlan } from './useGetMonthlyPlan';
import { ConstNumber } from '../../../shared/constants/number.constant';

export function useSelectPlan(params: SelectPlanParams) {
    const { level1_id, promo_code, promo_id } = params;
    const { getMonthlyPlan, gettingPlan } = useGetMonthlyPlan(level1_id);
    const [
        chargeCreditCard,
        {
            isLoading: isChargingCC,
            isSuccess: isPaymentSuccess,
            isError: isPaymentFailed,
            error: paymentError,
            data: paymentData,
        },
    ] = useChargeCreditCardMutation();
    const {
        data,
        isLoading: gettingPaymentPlan,
        isError,
    } = useGetPaymentPlanOnMountQuery({
        id: level1_id,
        promo_code,
    });
    const [isWaiting, setWaiting] = useState(false);

    useEffect(() => {
        if (isPaymentSuccess) {
            getMonthlyPlan();
        }
    }, [isPaymentSuccess]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | undefined;
        if (paymentError) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (paymentError as ErrorObj)?.message
            );
        } else if (paymentData) {
            Toast.success(ToastEnum.PAYMENT_DONE, paymentData.message);
        }
        if (isPaymentFailed) {
            timeoutId = setTimeout(() => {
                window.location.reload();
            }, ConstNumber.VALUE_3000);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [paymentData, paymentError, isPaymentFailed]);

    const submitCreditCard = async (cardEncData: any) => {
        const requestObj: ChargeCCParams = {
            token: cardEncData.opaqueData.dataValue as string,
            plan_id: data?.id as number,
        };
        if (promo_id) {
            requestObj.promo_code = promo_id;
        }
        setWaiting(true);
        //Added timeout to allow authorize.net to store the latest token
        setTimeout(async () => {
            await chargeCreditCard(requestObj);
            setWaiting(false);
        }, ConstNumber.FIVE_SEC);
    };

    return {
        data,
        getMonthlyPlan,
        isLoading: gettingPaymentPlan,
        isError,
        submitCreditCard,
        isChargingCC: isChargingCC || isWaiting,
        isPaymentSuccess,
        paymentError,
        gettingPlan,
        isDisabled: isChargingCC || isPaymentSuccess || isPaymentFailed,
    };
}
