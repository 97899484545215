import * as yup from 'yup';
import { ValidationEnums } from '../../../shared/constants/application.constant';
import { ConstNumber } from '../../../shared/constants/number.constant';
import { formatChangeDateTime } from '../../../shared/utils/helper';
import { CreateAdvertisementForm } from '../constants/types.advertising';

export const createAdvertisementSchema = yup.object().shape({
    id: yup.number().optional(),
    name: yup
        .string()
        .trim()
        .max(ConstNumber.VALUE_100, ValidationEnums.MAX_100)
        .required(ValidationEnums.REQUIRED),
    start_datetime: yup
        .date()
        .typeError(ValidationEnums.REQUIRED)
        .required(ValidationEnums.REQUIRED),
    age_target: yup.string().required(ValidationEnums.REQUIRED),
    views: yup.string().required(ValidationEnums.REQUIRED),
    placement: yup.string().required(ValidationEnums.REQUIRED),
});

export const getUpdatedValues = (
    previous: CreateAdvertisementForm,
    latest: any
) => {
    type UpdatedFields = { [key: string]: string | number[] | File };
    const updatedValues: UpdatedFields = {};
    for (const key in latest) {
        const keyString = key as keyof CreateAdvertisementForm;
        if (
            !(keyString in previous) ||
            latest[keyString] !== previous[keyString]
        ) {
            updatedValues[keyString] = latest[keyString] as any;
        }
    }

    return updatedValues;
};

export const getDataRequest = (data: CreateAdvertisementForm) => {
    return {
        name: data.name,
        age_target: Number(data.age_target),
        views: Number(data.views),
        start_datetime: formatChangeDateTime(
            data.start_datetime,
            data.start_datetime
        ),
        placement: Number(data.placement),
        document: data.document
    };
};
