import './assets/styles/styles.scss';
import { Routers } from './app/shared/routes/Routers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CustomErrorBoundary } from './app/shared/Error/CustomErrorBoundary';
import { useAppSelector } from './store/hooks';
import { useNavigate } from 'react-router-dom';
import { routeObj } from './app/shared/constants/routes.constants';

function App() {
    const { doRedirect } = useAppSelector((state) => state.notificationStates);
    const navigate = useNavigate();

    const handleToastOnClick = () => {
        if (doRedirect) {
            navigate(routeObj.BUSINESS_NOTIFICATION);
        }
    };

    return (
        <div className="main-container">
            <CustomErrorBoundary>
                <Routers />
            </CustomErrorBoundary>
            <ToastContainer
                position="top-center"
                autoClose={2500}
                hideProgressBar
                newestOnTop={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                closeOnClick
                onClick={handleToastOnClick}
            />
        </div>
    );
}

export default App;
