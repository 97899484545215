import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutesConfig';
import Error404 from '../../Error/Error404';

/*
 * This is the route utility component used for generating
 * routes and child routes it only requires routes prop
 */

interface Props {
    routes: PrivateRoutes[];
    basePath: string;
    isAddNotFound: boolean;
}
function MapAllowedRoutes({ routes, isAddNotFound }: Props) {
    return (
        <Routes>
            {routes.map((route: any, index: number) => {
                const {
                    path,
                    component: Component,
                    children,
                    permission,
                    ...rest
                } = route;
                return children ? (
                    <Route
                        {...rest}
                        key={`${path}-${index}`}
                        path={`${path}`}
                        element={<Component />}
                    >
                        {children.map(
                            (
                                {
                                    component: ChildComponent,
                                    path: childPath,
                                }: any,
                                idx: number
                            ) => (
                                <Route
                                    {...rest}
                                    key={idx}
                                    index={idx === 0}
                                    path={childPath}
                                    element={<ChildComponent />}
                                />
                            )
                        )}
                    </Route>
                ) : (
                    <Route
                        {...rest}
                        key={path}
                        path={`${path}`}
                        element={<Component />}
                    />
                );
            })}
            {isAddNotFound && <Route path="*" element={<Error404 />} />}
        </Routes>
    );
}

export default memo(MapAllowedRoutes);
