import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import InternalError from './InternalError';
import { useLocation } from 'react-router-dom';

interface FallbackProps {
    error: Error;
    resetErrorBoundary: (...args: Array<unknown>) => void;
}

interface ErrorBoundaryProps {
    children: ReactNode;
}

export const ErrorFallback = ({ error }: FallbackProps) => {
    return <InternalError />;
};

export const CustomErrorBoundary = ({ children }: ErrorBoundaryProps) => {
    const location = useLocation();
    return (
        <ErrorBoundary
            // this key will check if the path changed then it reset the error boundary
            key={location.pathname}
            FallbackComponent={ErrorFallback}
        >
            {children}
        </ErrorBoundary>
    );
};
