import { Form } from 'react-bootstrap';
import { Heading } from '../../../shared/constants/html.constant';
import { SubmitButton } from '../../../shared/elements/Buttons';
import { ConfirmationPopup } from '../../../shared/elements/ConfirmationPopup';
import CustomInput, {
    PasswordInput,
} from '../../../shared/elements/CustomInput';
import PasswordHint from '../../../shared/elements/PasswordHint';
import { useResetPassword } from '../hooks/useResetPassword';
import Resend from '../elements/Resend';

const ResetPassword = () => {
    const {
        errors,
        handleSubmit,
        isValid,
        onSubmitForm,
        register,
        isDataLoading,
        trigger,
        locationState,
        watch,
        isLoading,
        canResend,
        resendCode,
        handleConfirm,
        show,
        handleShow,
    } = useResetPassword();
    const pswdWatch = watch('password');

    return (
        <>
            <ConfirmationPopup
                visible={show}
                CloseBtnText={Heading.NO}
                ConfirmBtnText={Heading.YES}
                heading={Heading.EXIT}
                subHeading={Heading.CONFIRM_EXIT}
                handleClose={handleShow}
                onConfirm={handleConfirm}
            />

            <div className="cancel-btn-container">
                <span className="cancel-btn" onClick={handleShow}>
                    Cancel
                </span>
            </div>
            <div className="login-inner">
                <h1 className="login-title mb-2">Reset Password</h1>
                <p className="verify-subtext">
                    Enter the 6-digit code sent to{' '}
                    <span>{locationState?.email}</span>
                </p>
                <Form
                    className="login-form"
                    onSubmit={handleSubmit(onSubmitForm)}
                >
                    <CustomInput
                        label="Enter Code"
                        type="text"
                        name={'otp'}
                        placeholder="Enter Code"
                        errorMsg={errors.otp?.message}
                        onBlur={() => trigger('otp')}
                        register={register}
                    />
                    <PasswordInput
                        label="New Password"
                        type="password"
                        name={'password'}
                        placeholder="New Password"
                        register={register}
                        onBlur={() => trigger('password')}
                        errorMsg={errors.password?.message}
                        formGroupClass="form-group input-with-icon"
                        required
                    />
                    <PasswordHint password={pswdWatch} />
                    <div className="signin-bottom less-margin">
                        <Resend
                            canResend={canResend}
                            resendCode={resendCode}
                            customClass="text-center"
                        />
                    </div>
                    <div className="btn-container">
                        <SubmitButton
                            isDisable={!isValid || isDataLoading || isLoading}
                            isLoading={isDataLoading || isLoading}
                            label="Reset Password"
                        />
                    </div>
                </Form>
            </div>
        </>
    );
};

export default ResetPassword;
