import { CLOSEMENU } from '../../../shared/constants/image.constant';

interface IDetailChipProps {
    value: string;
    onClickRemove: (value: string) => void;
}

const DetailChip = (props: IDetailChipProps) => {
    const { value, onClickRemove } = props;

    return (
        <div className="chip primary dark" key={value}>
            {value}
            <img
                src={CLOSEMENU}
                alt="Close"
                className="close small"
                onClick={() => onClickRemove(value)}
            />
        </div>
    );
};

export default DetailChip;
