export enum apiUrlBusinessInfo {
    GET_SPECIALS = '/v1/specials/',
    GET_META = '/v1/meta/',
    BUSINESS_PROFILE = '/v1/business-profile/',
    GET_BUSINESS_PROFILE_UPDATE = '/v1/business-profile-update/',
    GET_VENUE_DETAILS = '/v1/level2/',
    GET_BUSINESS_LEVEL_3 = '/v1/business-level3/',
    GET_BUSINESS_LEVEL_2 = '/v1/business-level2',
    BUSINESS_LOCATIONS = '/v1/business-profile/locations/',
}
