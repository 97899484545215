import { Col, Form, Row } from 'react-bootstrap';
import CustomInput, { CustomDatePicker } from '../../../shared/elements/CustomInput';
import { useCreateEvent } from '../hooks/useCreateEvent';
import { ConstNumber } from '../../../shared/constants/number.constant';
import { useEffect } from 'react';
import { getFooterButton } from '../utils/utils';
import { getDuplicateText } from '../../../shared/utils/utils';
import SingleSelect from '../../../shared/elements/SingleSelect';
import CommonEventFields from '../../../shared/elements/CommonEventFields';
import { DATEICON } from '../../../shared/constants/image.constant';
import { DateFormats } from '../../../shared/constants/application.constant';

const CreateEventForm = () => {
    const {
        handleSubmit,
        isValid,
        register,
        errors,
        eventCategoryOptions,
        eventOptionSelected,
        handleChange,
        eventSubCategory,
        watch,
        handleDateChange,
        setValue,
        onSubmitForm,
        isLoading,
        show,
        setShow,
        getValues,
        businessDetails,
        setInitialValues,
        params,
        eventDataById,
        setDropdownVaue,
        isUpdating,
        duplicateCSS,
        isSubmitting,
        businessLocation,
        handleAddressChange,
        getSelectedValue,
        control,
    } = useCreateEvent();

    useEffect(() => {
        setInitialValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessDetails, eventDataById]);

    useEffect(() => {
        if (eventCategoryOptions.length && eventDataById) {
            setDropdownVaue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventCategoryOptions, eventDataById]);

    return (
        <Form
            className="modal-form demo-form"
            onSubmit={handleSubmit(onSubmitForm)}
        >
            <Row>
                <Col md={6}>
                    <CustomInput
                        requiredText={getDuplicateText(duplicateCSS)}
                        duplicateTextClass={duplicateCSS ? 'text-danger' : ''}
                        label="Event Name"
                        name={'name'}
                        register={register}
                        errorMsg={errors.name?.message}
                        placeholder="Event name here"
                        maxLength={ConstNumber.VALUE_100}
                    />
                </Col>
                <Col md={6}>
                    <Form.Group
                        className="form-group"
                        controlId="event-location"
                    >
                        <SingleSelect
                            options={businessLocation}
                            label="Location"
                            changeHandler={handleAddressChange}
                            placeholder="Select"
                            isClearable={false}
                            isSearchable={false}
                            errorMsg={errors.address?.message}
                            value={getSelectedValue()}
                        />
                    </Form.Group>
                </Col>
                <CommonEventFields
                    control={control}
                    errors={errors}
                    register={register}
                    eventCategoryOptions={eventCategoryOptions}
                    eventOptionSelected={eventOptionSelected}
                    eventSubCategory={eventSubCategory}
                    getValues={getValues}
                    handleChange={handleChange}
                    handleDateChange={handleDateChange}
                    setShow={setShow}
                    show={show}
                    setValue={setValue}
                    watch={watch}
                    thumbnailUrl={eventDataById?.thumbnail_url || ''}
                />
                 <Col md={6}>
                <Form.Group className="form-group" controlId="">
                    <Form.Label className="d-block">Start Date*</Form.Label>
                    <CustomDatePicker
                        name="start_date"
                        register={register}
                        watch={watch}
                        icon={DATEICON}
                        handleDateChange={handleDateChange}
                        errorMsg={errors.start_date?.message}
                        disablePastDate
                    />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="form-group" controlId="">
                    <Form.Label className="d-block">End Date*</Form.Label>
                    <CustomDatePicker
                        name="end_date"
                        icon={DATEICON}
                        watch={watch}
                        register={register}
                        handleDateChange={handleDateChange}
                        errorMsg={errors.end_date?.message}
                        disablePastDate
                    />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="form-group" controlId="">
                    <Form.Label className="d-block">Start Time*</Form.Label>
                    <CustomDatePicker
                        name="start_time"
                        timeCaption="Time"
                        timeIntervals={ConstNumber.VALUE_15}
                        dateFormat={DateFormats.TIME_WITH_MARIDIAN}
                        register={register}
                        setValue={setValue}
                        watch={watch}
                        handleDateChange={handleDateChange}
                        errorMsg={errors.start_time?.message}
                        showTimeSelect
                        showTimeSelectOnly
                    />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="form-group" controlId="">
                    <Form.Label className="d-block">End Time*</Form.Label>
                    <CustomDatePicker
                        name="end_time"
                        timeCaption="Time"
                        timeIntervals={ConstNumber.VALUE_15}
                        dateFormat={DateFormats.TIME_WITH_MARIDIAN}
                        register={register}
                        setValue={setValue}
                        watch={watch}
                        handleDateChange={handleDateChange}
                        errorMsg={errors.end_time?.message}
                        showTimeSelect
                        showTimeSelectOnly
                    />
                </Form.Group>
            </Col>
            </Row>
            {getFooterButton(
                params,
                isValid,
                isLoading,
                isUpdating,
                isSubmitting
            )}
        </Form>
    );
};

export default CreateEventForm;
