import { Form } from 'react-bootstrap';
import { default as ReactSelect, ActionMeta } from 'react-select';
import { customStyles } from '../constants/style.constant';
import { ternaryLogic } from '../utils/orLogic';
import { SelectDropDownProps } from '../utils/types.elements';

const SingleSelect = (props: SelectDropDownProps) => {
    const {
        label,
        options,
        isClearable,
        isSearchable,
        placeholder,
        errorMsg,
        value,
        isOptional,
        getOptionLabel,
        getOptionValue,
        changeHandler,
        isDisabled,
        isLabel = true,
        defaultValue,
    } = props;
    const handleChange = (selected: any, actionMeta: ActionMeta<any>) => {
        return changeHandler(selected);
    };

    return (
        <>
            {isLabel && (
                <Form.Label>{`${label}${ternaryLogic(
                    isOptional,
                    '',
                    '*'
                )}`}</Form.Label>
            )}
            <ReactSelect
                {...props}
                defaultValue={defaultValue}
                options={options}
                styles={customStyles}
                tabSelectsValue={false}
                placeholder={placeholder}
                isSearchable={isSearchable}
                isClearable={isClearable}
                onChange={handleChange}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                aria-invalid={ternaryLogic(errorMsg, 'true', 'false')}
                value={value}
                className="custom-select"
                isDisabled={isDisabled}
            />
            {errorMsg && (
                <Form.Control.Feedback type="invalid">
                    {errorMsg}
                </Form.Control.Feedback>
            )}
        </>
    );
};

export default SingleSelect;
