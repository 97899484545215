import { Auth } from 'aws-amplify';
import {
    SignUpParameters,
    ResendConfCodeParameters,
    ConfirmSignUpParameters,
    SignInParameters,
    ConfirmResetParameters,
    ChangePassword,
} from './types.elements';

export async function signUpWithCognito({
    username,
    password,
    attributes,
}: SignUpParameters) {
    try {
        const { user } = await Auth.signUp({
            username,
            password,
            attributes,
            autoSignIn: {
                // optional - enables auto sign in after user is confirmed
                enabled: true,
            },
        });
        return Promise.resolve(user.getUsername());
    } catch (error: any) {
        return Promise.reject(error.message);
    }
}

export async function resendConfirmationCode({
    username,
}: ResendConfCodeParameters) {
    try {
        const res = await Auth.resendSignUp(username);
        return Promise.resolve(res);
    } catch (error: any) {
        return Promise.reject(error.message);
    }
}

export async function confirmSignUp({
    username,
    code,
}: ConfirmSignUpParameters) {
    try {
        const res = await Auth.confirmSignUp(username, code, {
            forceAliasCreation: false,
        });
        return Promise.resolve(res);
    } catch (error: any) {
        return Promise.reject(error.message);
    }
}

export async function signIn({ username, password }: SignInParameters) {
    try {
        const user = await Auth.signIn(username, password);
        // Since the response doesn't contain the user data after sign-in,
        // we return an empty object as data to conform to RTK Query requirements.
        return Promise.resolve(user);
    } catch (error: any) {
        return Promise.reject(error);
    }
}

export async function signOutWithCognito() {
    try {
        await Auth.signOut();
        return Promise.resolve();
    } catch (error: any) {
        return Promise.reject(error.message);
    }
}

export async function forgotPasswordCognito({
    username,
}: ResendConfCodeParameters) {
    try {
        const res = await Auth.forgotPassword(username);
        return Promise.resolve(res);
    } catch (error: any) {
        return Promise.reject(error);
    }
}

export async function resetPasswordCognito({
    username,
    code,
    password,
}: ConfirmResetParameters) {
    try {
        const res = await Auth.forgotPasswordSubmit(username, code, password);
        return Promise.resolve(res);
    } catch (error: any) {
        return Promise.reject(error.message);
    }
}

export async function changePasswordCognito({
    currentPassword,
    newPassword,
}: ChangePassword) {
    try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const user = await Auth.changePassword(
            cognitoUser,
            currentPassword,
            newPassword
        );
        return Promise.resolve(user);
    } catch (error: any) {
        return Promise.reject(error);
    }
}
