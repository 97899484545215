import {
    Button,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'react-bootstrap';
import { handleLoadedMetadata, isImageUrl, isVideoUrl } from '../utils/helper';
import React, { useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import UploadMediaInput from './UploadMediaInput';
import ViewUploadedMedia from './ViewUploadedMedia';
import DocumentValidation from './DocumentValidation';
import { useUploadMedia } from '../hooks/useUploadMedia';
import { UploadMediaProps } from '../types/type';
import { andAndLogic, ternaryLogic } from '../utils/orLogic';

const UploadMedia: React.FC<UploadMediaProps> = (props) => {
    const {
        clear,
        closeHandler,
        crop,
        deleteHandler,
        doc,
        fromCategory,
        handleCropComplete,
        handleImageChange,
        isActionDisabled,
        isOptional,
        onImageLoad,
        saveHandler,
        show,
        uploadValidation,
        videoRef,
        image,
        setImage,
        video,
        setVideo,
        document,
        setDocument,
        setCrop,
        setShow,
        checkValidation,
        thumbnailUrl,
    } = useUploadMedia(props);

    useEffect(() => {
        if (typeof doc === 'string') {
            if (isImageUrl(doc)) {
                setImage(doc);
            } else if (isVideoUrl(doc)) {
                setVideo(doc);
            }
            setDocument(doc);
        }
    }, [doc]);

    useEffect(() => {
        if (clear) {
            setDocument(null);
            setImage(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clear]);

    return (
        <>
            <Modal show={show} size="lg" centered>
                <ModalHeader
                    closeButton
                    onClick={closeHandler}
                    className="big-header"
                >
                    <h2>
                        {ternaryLogic(
                            fromCategory,
                            'Upload Image',
                            'Upload Image or Video'
                        )}
                    </h2>
                </ModalHeader>
                <ModalBody className="text-center">
                    {andAndLogic(!image, !video) && (
                        <UploadMediaInput
                            handleImageChange={handleImageChange}
                            fromCategory={fromCategory}
                        />
                    )}
                    <DocumentValidation uploadValidation={uploadValidation} />
                    {image && (
                        <ReactCrop
                            crop={crop}
                            onChange={(newCrop) => setCrop(newCrop)}
                            onComplete={handleCropComplete}
                            locked
                        >
                            <img
                                className="img-crop"
                                alt="Crop me"
                                src={image}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}
                    {video && (
                        <div className="video-container">
                            <video
                                height={257}
                                width={300}
                                src={
                                    typeof video === 'string'
                                        ? video
                                        : URL.createObjectURL(video)
                                }
                                ref={videoRef}
                                onLoadedMetadata={() =>
                                    handleLoadedMetadata(
                                        videoRef,
                                        video,
                                        checkValidation,
                                        setVideo
                                    )
                                }
                                controls
                            />
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="link"
                        className={`${ternaryLogic(
                            isActionDisabled,
                            'disabled',
                            'enabled'
                        )}`}
                        disabled={isActionDisabled}
                        onClick={deleteHandler}
                    >
                        Delete
                    </Button>
                    <Button disabled={isActionDisabled} onClick={saveHandler}>
                        Upload
                    </Button>
                </ModalFooter>
            </Modal>
            <>
                {document ? (
                    <ViewUploadedMedia
                        media={document}
                        deleteHandler={deleteHandler}
                        setShow={setShow}
                        fromCategory={fromCategory}
                        isOptional={isOptional}
                        isDisabled={props.isDisabled}
                        thumbnailUrl={thumbnailUrl}
                    />
                ) : (
                    <div className="upload-form">
                        <Form.Label>
                            {ternaryLogic(fromCategory, 'Image', 'Image/Video')}
                            {ternaryLogic(isOptional, '*', '')}{' '}
                            {!isOptional && (
                                <span className="text-muted">(Optional)</span>
                            )}
                        </Form.Label>
                        <Button variant="info" onClick={() => setShow(true)}>
                            Click to Upload
                        </Button>
                    </div>
                )}
            </>
        </>
    );
};

export default UploadMedia;
