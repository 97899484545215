import React from 'react';
import { Provider } from '../utils/types.elements';

type Props = {
    uploadValidation: Provider;
};

const DocumentValidation = (props: Props) => {
    return (
        <>
            {!props.uploadValidation.valid && (
                <div>
                    {props.uploadValidation.value
                        .filter((e) => e !== '')
                        .map((e, i) => (
                            <span className="d-block text-danger" key={i}>
                                {e}
                            </span>
                        ))}
                </div>
            )}
        </>
    );
};

export default DocumentValidation;
