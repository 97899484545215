import { useCallback, useState } from 'react';
import { TabKeys } from '../../../shared/constants/application.constant';
import MyEvents from '../screen/MyEvents';
import { useLocation, useNavigate } from 'react-router-dom';

import { routeObj } from '../../../shared/constants/routes.constants';
import { CreateEventParams } from '../constants/types.events';

const useEventTabs = () => {
    const navigate = useNavigate();
    const params = useLocation().state as CreateEventParams;
    const [activeTab, setActiveTab] = useState<string | null>(
        params?.activeTab || TabKeys.MY_EVENTS
    );
    const [showUpload, setShowUpload] = useState(false);

    const handleShowUpload = () => {
        setShowUpload(!showUpload);
    };

    const tabs = [
        {
            eventKey: TabKeys.MY_EVENTS,
            title: 'My Events',
            content: (
                <MyEvents
                    activeTab={activeTab}
                    showUpload={showUpload}
                    handleShowUpload={handleShowUpload}
                />
            ),
        },
        {
            eventKey: TabKeys.CLAIMED_EVENTS,
            title: 'Claimed Events',
            content: (
                <MyEvents
                    activeTab={activeTab}
                    showUpload={showUpload}
                    handleShowUpload={handleShowUpload}
                />
            ),
        },
    ];

    const handleTabClick = useCallback((tab: string | null): void => {
        setActiveTab(tab);
    }, []);

    const navigateToCreate = () => {
        navigate(routeObj.BUSINESS_EVENT_CREATE, {
            state: {
                activeTab,
            },
        });
    };

    return {
        tabs,
        activeTab,
        handleTabClick,
        navigateToCreate,
        showUpload,
        handleShowUpload,
    };
};

export default useEventTabs;
