import { ConstNumber } from '../../../shared/constants/number.constant';
import { useEffect, useState } from 'react';
import {
    BusinessLevel3Request,
    VenueDetails,
} from '../../businessInfo/utils/types.businessInfo';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    eventsApi,
    useGetEventDetailsQuery,
    useGetEventLevel3Query,
    useUpdateEventLevel3Mutation,
} from '../slices/slice.events';
import { ErrorObj } from '../../../shared/types/type';
import Toast from '../../../shared/utils/toast.helper';
import { ToastEnum } from '../../../shared/constants/html.constant';
import { routeObj } from '../../../shared/constants/routes.constants';
import { useAppDispatch } from '../../../../store/hooks';

const useEventDetails = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [eventDetails, setEventDetails] = useState<VenueDetails[]>([]);
    const [selectedEventLevels, setSelectedEventLevels] = useState<number[]>(
        []
    );
    const { data: eventDetailsData, isLoading: isLoadingGetEventDetails } =
        useGetEventDetailsQuery({
            id: state?.id,
        });
    const { data: eventLevel3, isLoading: isLoadingGetEventLevel3 } =
        useGetEventLevel3Query({
            id: state?.id,
        });
    const [
        updateEventLevel3,
        { isLoading, error, data: updateEventLevel3Response },
    ] = useUpdateEventLevel3Mutation();

    const onChangeDetail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedLevels = [...selectedEventLevels];
        const index = selectedEventLevels.indexOf(Number(e.target.value));
        if (index !== -ConstNumber.VALUE_1) {
            updatedLevels.splice(index, ConstNumber.VALUE_1);
        } else {
            updatedLevels.push(Number(e.target.value));
        }
        setSelectedEventLevels(updatedLevels);
    };

    const onSubmitDetails = () => {
        const requestData: BusinessLevel3Request = {
            category_level3_list: selectedEventLevels,
        };
        updateEventLevel3({
            data: requestData,
            id: state?.id,
        });
    };

    useEffect(() => {
        // Reset data when component unmounts or changes
        return () => {
            dispatch(eventsApi.util.resetApiState());
        };
    }, []);

    useEffect(() => {
        if (eventDetailsData) {
            setEventDetails(eventDetailsData?.data);
        }
    }, [eventDetailsData]);

    useEffect(() => {
        if (eventLevel3) {
            setSelectedEventLevels(eventLevel3.category_level3_list);
        }
    }, [eventLevel3]);

    useEffect(() => {
        if (updateEventLevel3Response?.message) {
            navigate(
                state?.warehouseEvent
                    ? routeObj.ADMIN_DATA_WAREHOUSE
                    : routeObj.BUSINESS_EVENTS,
                {
                    state: {
                        activeTab: state?.activeTab,
                    },
                }
            );
            Toast.success(
                ToastEnum.EVENT_DETAIL_SAVED,
                updateEventLevel3Response?.message
            );
        } else if (error) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (error as ErrorObj)?.message
            );
        }
    }, [updateEventLevel3Response?.message, error]);

    return {
        eventDetails,
        selectedEventLevels,
        onChangeDetail,
        onSubmitDetails,
        updateEventLevel3Response,
        isLoadingGetEventDetails,
        isLoadingGetEventLevel3,
        isLoading,
        state,
    };
};

export default useEventDetails;
