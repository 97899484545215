import React, { startTransition } from 'react';
import { ERRORPATH } from '../constants/image.constant';
import { NoDataEnums } from '../constants/html.constant';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../utils/storage.helpers';
import { routeObj } from '../constants/routes.constants';
import { StorageDataResponse } from '../utils/types.elements';
import { checkIsAdmin } from '../utils/helper';
import ErrorLayout from './ErrorLayout';

const Error404 = () => {
    const navigate = useNavigate();
    const isLogged = isLoggedIn() as StorageDataResponse;
    function clickHandler() {
        startTransition(() => {
            navigate(
                isLogged.role
                    ? checkIsAdmin(isLogged.role)
                        ? routeObj.ADMIN
                        : routeObj.BUSINESS_DASHBOARD
                    : routeObj.DEFAULT
            );
        });
    }

    return (
        <ErrorLayout img={ERRORPATH}>
            <h3>404 error</h3>
            <p>{NoDataEnums.NO_PATH_FOUND}</p>
            <Button onClick={() => clickHandler()} variant="primary">
                Back to Home
            </Button>
        </ErrorLayout>
    );
};

export default Error404;
