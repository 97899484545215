import { formatDateTime } from '../../../shared/utils/helper';
import { DateFormats } from '../../../shared/constants/application.constant';
import { NotificationListData } from '../utils/types.notificationList';

interface INotificationTile {
    notification: NotificationListData;
}

const NotificationTile = (props: INotificationTile) => {
    const { notification } = props;

    return (
        <div className="list">
            <div className="list-content">
                <div className="title">{notification.title_web}</div>
                <p>{notification.notification_data}</p>
            </div>
            <div className="list-time">
                {formatDateTime(
                    notification.created_at,
                    DateFormats.DD_MMM_TIME
                )}
            </div>
        </div>
    );
};

export default NotificationTile;
