import { useForm } from 'react-hook-form';
import {
    ForgotPasswordFormSchema,
    getForgotPasswordIntialValues,
} from '../utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { forgotPassword } from '../slices/slice.auth';
import {
    CognitoAuth,
    CognitoExceptions,
} from '../../../shared/constants/application.constant';
import { routeObj } from '../../../shared/constants/routes.constants';
import { ForgotPassword } from '../utils/slice.types';
import Toast from '../../../shared/utils/toast.helper';
import { ToastEnum } from '../../../shared/constants/html.constant';
import { useNavigate } from 'react-router-dom';

export function useForgotPassword() {
    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
        setValue,
        trigger,
        watch,
    } = useForm({
        defaultValues: getForgotPasswordIntialValues(),
        resolver: yupResolver(ForgotPasswordFormSchema),
    });
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const cognitoAuth = process.env.REACT_APP_COGNITO_AUTH;
    const { isDataLoading } = useAppSelector((state) => state.auth);

    const onSubmitForm = useCallback(
        async (values: ForgotPassword): Promise<void> => {
            if (cognitoAuth === CognitoAuth.ALLOWED) {
                dispatch(
                    forgotPassword({
                        username: values.email,
                    })
                )
                    .unwrap()
                    .then(() => {
                        /**
                         * we have added this message for testing purpose, will remove it in upcoming PR
                         */
                        Toast.success(
                            ToastEnum.OTP_SENT,
                            ToastEnum.OTP_SEND_DECS
                        );
                        navigate(routeObj.RESET_PASSWORD, {
                            state: {
                                email: values.email,
                            },
                        });
                    })
                    .catch((_error) => {
                        if (_error.code === CognitoExceptions.USER_NOT_FOUND) {
                            Toast.error(
                                ToastEnum.SOMETHING_WENT_WRONG,
                                ToastEnum.EMAIL_NOT_EXIST
                            );
                        } else {
                            Toast.error(
                                ToastEnum.SOMETHING_WENT_WRONG,
                                _error.message
                            );
                        }
                    });
            } else {
                navigate(routeObj.RESET_PASSWORD, {
                    state: {
                        email: values.email,
                    },
                });
            }
        },
        []
    );

    return {
        register,
        handleSubmit,
        onSubmitForm,
        setValue,
        trigger,
        watch,
        errors,
        isValid,
        isDataLoading,
    };
}
