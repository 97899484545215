import { NotificationTypeEnum, PromotionTypeEnum, SpecialEnum } from '../../../shared/constants/application.constant';

export const OfferTypeArray = [
    {
        label: 'Local Specials',
        value: SpecialEnum.LOCAL_SPECIAL,
    },
    {
        label: 'Happy Hours',
        value: SpecialEnum.HAPPY_HOURS,
    },

];

export const PromotionTypeArray = [
    {
        label: 'Subscription',
        value: PromotionTypeEnum.SUBSCRIPTION,
    },
    {
        label: 'Advertisement',
        value: PromotionTypeEnum.ADVERTISEMENT,
    },
];

export const NotificationTypeArray = [
    {
        label: 'Business Users',
        value: NotificationTypeEnum.BUSINESS_USER,
    },
    {
        label: 'End Users',
        value: NotificationTypeEnum.END_USER,
    },
];
