import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query';
import {
    HttpStatusCode,
    localStorageKeys,
    ValidationEnums,
} from '../constants/application.constant';
import {
    getStorageData,
    redirectToIndex,
    setDataIntoLocalStorage,
} from '../utils/storage.helpers';
import { Auth } from 'aws-amplify';
import { StorageDataResponse } from '../utils/types.elements';
import { ConstNumber } from '../constants/number.constant';
import { signOutWithCognito } from '../utils/aws.methods';
import Toast from '../utils/toast.helper';
import { ToastEnum } from '../constants/html.constant';

export const baseQuery: BaseQueryFn<any, any, any> = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
});

export const getToken = async (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        const result = getStorageData() as StorageDataResponse;
        if (result.access_token !== '' && typeof result === 'object') {
            try {
                const cognitoUser = await Auth.currentAuthenticatedUser();
                const { expirationTime } = result;
                const currentTime =
                    new Date().getTime() / ConstNumber.VALUE_1000;
                if (parseInt(expirationTime) <= currentTime) {
                    const currentSession = await Auth.currentSession();
                    cognitoUser.refreshSession(
                        currentSession.getRefreshToken(),
                        async (err: any, session: any) => {
                            const { accessToken } = session;
                            const newData = {
                                ...result,
                                access_token: accessToken?.jwtToken,
                            };
                            setDataIntoLocalStorage(
                                localStorageKeys.USER,
                                newData
                            );
                            resolve(newData);
                        }
                    );
                } else {
                    resolve(result);
                }
            } catch (e) {
                reject(e);
                redirectToIndex(); // in case of unauthorised user, naivgate to Login
            }
        } else {
            resolve({});
        }
    });
};

// Define your middleware function
export const interceptorMiddleware: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args: any, api: any, extraOptions: any) => {
    // Intercept the request here, Add custom headers to the request
    const token = (await getToken()).access_token;
    if (token) {
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        args.headers = headers;
    }
    try {
        // Make the request using the base query
        const result = await baseQuery(args, api, extraOptions);
        // Check for error in response
        if (result.error) {
            if (
                result.error.status === HttpStatusCode.UNAUTHORISED ||
                result.error.status === HttpStatusCode.NOT_EXIST
            ) {
                signOutWithCognito()
                    .then(async () => {
                        redirectToIndex();
                    })
                    .catch((_error) => {
                        alert(_error.message);
                    });
            }
            if (
                result.error.code === HttpStatusCode.ERR_NETWORK ||
                result.error.status === HttpStatusCode.FETCH_ERROR
            ) {
                return Promise.reject(ValidationEnums.NO_INTERNET);
            }
            return Promise.reject(result.error.data.message);
        }
        // Return the response
        return result;
    } catch (error) {
        // Throw an error to be caught by the catch block
        throw error;
    }
};
