import { useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import {
    ButtonLabel,
    ToastEnum,
} from '../../../shared/constants/html.constant';
import { SubmitButton } from '../../../shared/elements/Buttons';
import CustomInput from '../../../shared/elements/CustomInput';
import { ErrorObj } from '../../../shared/types/type';
import Toast from '../../../shared/utils/toast.helper';
import useAdminLevel3Categories from '../hooks/useAdminLevel3Categories';

interface ICreateCategoryLevelThreeProps {
    show: boolean;
    handleShow: () => void;
}

const CreateCategoryLevelThree = (props: ICreateCategoryLevelThreeProps) => {
    const { show, handleShow } = props;
    const {
        onSubmitForm,
        handleSubmit,
        register,
        errors,
        isValid,
        isLoadingLevelThree,
        createLevelThreeResponse,
        apiError,
        reset,
        refetch,
        trigger,
    } = useAdminLevel3Categories();

    useEffect(() => {
        if (createLevelThreeResponse?.message) {
            refetch();
            handleShow();
            Toast.success(
                ToastEnum.GROUP_CREATED,
                createLevelThreeResponse?.message
            );
        } else if (apiError) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (apiError as ErrorObj)?.message
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createLevelThreeResponse?.message, apiError]);

    useEffect(() => {
        if (show) {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <Modal
            centered
            className="admin-modals"
            show={show}
            onHide={handleShow}
        >
            <Modal.Header closeButton className="text-left">
                <Modal.Title>Create New</Modal.Title>
            </Modal.Header>
            <Form className="mt-1" onSubmit={handleSubmit(onSubmitForm)}>
                <Modal.Body>
                    <CustomInput
                        label="Group Name"
                        type="text"
                        name={'heading'}
                        register={register}
                        errorMsg={errors.heading?.message}
                        placeholder="Group Name"
                        onBlur={() => trigger('heading')}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <SubmitButton
                        label={'Save'}
                        isDisable={!isValid || isLoadingLevelThree}
                        isLoading={isLoadingLevelThree}
                        loadingLabel={ButtonLabel.SAVING}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CreateCategoryLevelThree;
