import { HttpMethods } from '../constants/application.constant';

export async function uploadTos3(preSignedUrl: string, file: File) {
    try {
        const response = await fetch(preSignedUrl, {
            method: HttpMethods.PUT,
            body: file,
        });
        if (response.ok) {
            return true; // Successful upload
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}
