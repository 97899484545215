type Props = {
    title: string;
    link?: string;
};

const TermsLink = (props: Props) => {
    const { title, link } = props;
    return (
        <a href={link} title={title} target="_blank" rel="noreferrer">
            {title}
        </a>
    );
};

export default TermsLink;
