import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { businessDashboardApi } from '../app/modules/businessDashboard/slices/slice.dashboard';
import { businessInfoApi } from '../app/modules/businessInfo/slices/slice.businessInfo';
import { eventsApi } from '../app/modules/events/slices/slice.events';
import { specialsApi } from '../app/modules/specials/slices/slice.specials';
import { authApi } from '../app/modules/onBoarding/slices/slice.authApi';
import authReducer from '../app/modules/onBoarding/slices/slice.auth';
import { categoriesApi } from '../app/adminModules/categories/slices/slice.categories';
import { businessSearchApi } from '../app/adminModules/Businesses/slices/slice.businesses';
import { subAdminApi } from '../app/adminModules/users/slices/slice.adminUsers';
import { advertisingApi } from '../app/modules/advertising/slices/slice.advertising';
import { metaApi } from '../app/shared/slices/slice.meta';
import { paymentPlanApi } from '../app/adminModules/subscriptions/slices/slice.subscriptions';
import { promotionApi } from '../app/adminModules/promotion/slices/slice.promotion';
import { subscriptionApi } from '../app/modules/subscription/slices/slice.subscription';
import { apiEventsApi } from '../app/adminModules/apiEvents/slices/slice.apiEvents';
import { claimEventsApi } from '../app/adminModules/claimEvents/slices/slice.claimEvents';
import { notificationApi } from '../app/adminModules/notification/slices/slice.notification';
import { warehouseApi } from '../app/adminModules/warehouse/slices/slice.warehouse';
import notificationReducer from '../app/shared/slices/slice.notification';
import { notificationListApi } from '../app/modules/notificationList/slices/slice.notificationList';

export const store = configureStore({
    reducer: {
        // Add the generated reducer as a specific top-level slice
        [businessDashboardApi.reducerPath]: businessDashboardApi.reducer,
        [specialsApi.reducerPath]: specialsApi.reducer,
        [businessInfoApi.reducerPath]: businessInfoApi.reducer,
        [eventsApi.reducerPath]: eventsApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        auth: authReducer,
        [categoriesApi.reducerPath]: categoriesApi.reducer,
        [businessSearchApi.reducerPath]: businessSearchApi.reducer,
        [subAdminApi.reducerPath]: subAdminApi.reducer,
        [advertisingApi.reducerPath]: advertisingApi.reducer,
        [metaApi.reducerPath]: metaApi.reducer,
        [paymentPlanApi.reducerPath]: paymentPlanApi.reducer,
        [promotionApi.reducerPath]: promotionApi.reducer,
        [subscriptionApi.reducerPath]: subscriptionApi.reducer,
        [apiEventsApi.reducerPath]: apiEventsApi.reducer,
        [claimEventsApi.reducerPath]: claimEventsApi.reducer,
        [notificationApi.reducerPath]: notificationApi.reducer,
        [warehouseApi.reducerPath]: warehouseApi.reducer,
        notificationStates: notificationReducer,
        [notificationListApi.reducerPath]: notificationListApi.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            authApi.middleware,
            businessDashboardApi.middleware,
            specialsApi.middleware,
            businessInfoApi.middleware,
            eventsApi.middleware,
            categoriesApi.middleware,
            subAdminApi.middleware,
            businessSearchApi.middleware,
            advertisingApi.middleware,
            metaApi.middleware,
            paymentPlanApi.middleware,
            promotionApi.middleware,
            subscriptionApi.middleware,
            apiEventsApi.middleware,
            claimEventsApi.middleware,
            notificationApi.middleware,
            warehouseApi.middleware,
            notificationListApi.middleware,
        ]),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
