import { Row, Col, Form } from 'react-bootstrap';
import CustomInput, {
    CustomDatePicker,
    RadioInput,
    TextArea,
} from '../../../shared/elements/CustomInput';
import { useCreateSpecial } from '../hooks/useCreateSpecial';
import { DATEICON } from '../../../shared/constants/image.constant';
import 'react-datepicker/dist/react-datepicker.css';
import PriceRangeRadio from '../../../shared/elements/PriceRangeRadio';
import {
    DateFormats,
    SpecialEnum,
} from '../../../shared/constants/application.constant';
import { OfferTypeArray } from '../constants/data.specials';
import { ConstNumber } from '../../../shared/constants/number.constant';
import UploadMedia from '../../../shared/elements/UploadMedia';
import Loader from '../../../shared/elements/Loader';
import { useEffect } from 'react';
import {
    Controller,
    RegisterOptions,
    UseFormRegisterReturn,
} from 'react-hook-form';
import { SubmitButton } from '../../../shared/elements/Buttons';
import { FormParams } from '../../../shared/utils/types.elements';
import OpenHours from '../../../shared/elements/OpenHours';
import { ternaryLogic } from '../../../shared/utils/orLogic';
import { getDuplicateText } from '../../../shared/utils/utils';
import SingleSelect from '../../../shared/elements/SingleSelect';
import DateElements from './DateElements';

interface Props {
    params: FormParams;
}

const CreateSpecialForm = (props: Props) => {
    const {
        handleSubmit,
        onSubmitForm,
        setValue,
        register,
        watch,
        errors,
        isValid,
        isLoading,
        trigger,
        getValues,
        handleDateChange,
        setShow,
        show,
        isDataFethcing,
        setInitialValues,
        specialDataById,
        control,
        daysSelected,
        handleDaysChange,
        handleTimeChange,
        saveSlotHandler,
        timeSlot,
        isSlotDisabled,
        removeTimeSlot,
        label,
        loadingLabel,
        duplicateCSS,
        businessOptions,
        handleChange,
        getSelectedValue,
    } = useCreateSpecial(props.params);

    useEffect(() => {
        if (specialDataById) {
            setInitialValues(specialDataById);
            trigger();
        }
    }, [specialDataById]);

    return isDataFethcing ? (
        <Loader />
    ) : (
        <Form
            className="modal-form demo-form"
            onSubmit={handleSubmit(onSubmitForm)}
        >
            <Controller
                control={control}
                name="offer_type"
                render={({ field: { onChange } }) => (
                    <div className="radio-heading-wrapper">
                        <RadioInput
                            name={'offer_type'}
                            mapOption={(option: {
                                label: string;
                                value: SpecialEnum;
                            }) => ({
                                label: option.label,
                                value: option.value,
                            })}
                            options={OfferTypeArray}
                            defaultValue={getValues('offer_type')}
                            radioClass="custom-radio"
                            handleChange={(e) =>
                                onChange(Number(e.target.value))
                            }
                        />
                    </div>
                )}
            />
            <Row>
                <Col md={6}>
                    <CustomInput
                        requiredText={getDuplicateText(duplicateCSS)}
                        duplicateTextClass={ternaryLogic(
                            duplicateCSS,
                            'text-danger',
                            ''
                        )}
                        label="Offer Name"
                        type="text"
                        name="name"
                        placeholder="Name"
                        register={register}
                        errorMsg={errors.name?.message}
                        onBlur={() => trigger('name')}
                        required
                    />
                </Col>
                <Col md={6}>
                    <Form.Group className="form-group" controlId="location">
                        <SingleSelect
                            options={businessOptions}
                            label="Location"
                            isSearchable={false}
                            isClearable={false}
                            changeHandler={handleChange}
                            errorMsg={undefined}
                            placeholder="Select"
                            value={getSelectedValue()}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Row>
                        <DateElements
                            setValue={setValue}
                            register={register}
                            watch={watch}
                            handleDateChange={handleDateChange}
                            errors={errors}
                        />
                        <Col md={12}>
                            <Controller
                                control={control}
                                name="price_range"
                                render={({ field: { onChange } }) => (
                                    <PriceRangeRadio
                                        defaultValue={getValues('price_range')}
                                        name="price_range"
                                        register={register}
                                        handleClicked={(val) => onChange(val)}
                                    />
                                )}
                            />
                        </Col>
                        <Col md={12}>
                            <UploadMedia
                                show={show}
                                setShow={setShow}
                                setValue={setValue}
                                name="document"
                                document={getValues('document' as any)}
                                thumbnailUrl={specialDataById?.thumbnail_url || ''}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <Form.Group className="form-group" controlId="description">
                        <TextArea
                            label="Description"
                            rows={ConstNumber.VALUE_15}
                            name="description"
                            placeholder="Offer description here"
                            register={register}
                            onBlur={() => trigger('description')}
                            errorMsg={errors.description?.message}
                            maxLength={ConstNumber.VALUE_1000}
                        />
                    </Form.Group>
                </Col>
                <OpenHours
                    daysSelected={daysSelected}
                    handleDaysChange={handleDaysChange}
                    handleTimeChange={handleTimeChange}
                    watch={watch}
                    register={register}
                    errorMsgStartTime={errors.oh_start_time?.message}
                    errorMsgEndTime={errors.oh_end_time?.message}
                    onClickSaveSlot={saveSlotHandler}
                    start_time={getValues('oh_start_time')}
                    end_time={getValues('oh_end_time')}
                    timeSlot={timeSlot}
                    isOptionDisabled={isSlotDisabled}
                    removeTimeSlot={removeTimeSlot}
                    startAtName="oh_start_time"
                    endAtName="oh_end_time"
                />
            </Row>
            <div className="button-footer">
                <SubmitButton
                    label={label}
                    customClass="medium"
                    isLoading={isLoading}
                    loadingLabel={loadingLabel}
                    isDisable={!isValid || isLoading}
                />
            </div>
        </Form>
    );
};

export default CreateSpecialForm;
