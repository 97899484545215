import { createApi } from '@reduxjs/toolkit/query/react';
import { interceptorMiddleware } from '../services/interceptor.service';
import { HttpMethods } from '../constants/application.constant';
import { getFileExtension } from '../utils/helper';
import { apiUrlShared } from '../constants/apiUrl.constant';
import { ApplyPromoCodeRes } from '../types/type';

export const metaApi = createApi({
    reducerPath: 'meta',
    baseQuery: interceptorMiddleware,
    tagTypes: ['meta'],
    endpoints: (builder) => ({
        getUrl: builder.mutation<{ pre_signed_link: string }, { file: File }>({
            query: (param) => {
                const ext = getFileExtension(param.file);
                return {
                    url: `${apiUrlShared.PRE_SIGNED}/?ext=${ext}`,
                    method: HttpMethods.GET,
                };
            },
        }),
        uploadToS3: builder.mutation<{}, { preSignedUrl: string; file: File }>({
            query: ({ preSignedUrl, file }) => {
                return {
                    url: preSignedUrl,
                    method: HttpMethods.PUT,
                    body: file,
                };
            },
        }),
        applyPromocode: builder.mutation<
            ApplyPromoCodeRes,
            { code: string; type: number }
        >({
            query: ({ code, type }) => {
                const params = new URLSearchParams();
                // Append the encoded coupon code and promotion type
                params.append('promotion_code', code);
                params.append('promotion_type', type.toString());
                return {
                    url: `${
                        apiUrlShared.APPLY_PROMO_CODE
                    }/?${params.toString()}`,
                    method: HttpMethods.GET,
                };
            },
        }),
    }),
});

export const {
    useGetUrlMutation,
    useUploadToS3Mutation,
    useApplyPromocodeMutation,
} = metaApi;
