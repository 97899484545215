import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { EventActionType } from '../constants/application.constant';

type Props = {
    isDuplicate?: boolean;
    id: number;
    actionHandler: (id: number, actionType: EventActionType) => void;
    isEdit?: boolean;
    isDelete?: boolean;
    isRefresh?: boolean;
};

const EventActions = (props: Props) => {
    const {
        isDuplicate,
        actionHandler,
        id,
        isEdit = true,
        isDelete = true,
        isRefresh,
    } = props;
    return (
        <DropdownButton id="moreDropdown" variant="btn-secondary" title="">
            {isEdit && (
                <Dropdown.Item
                    onClick={() => {
                        actionHandler(id, EventActionType.EDIT);
                    }}
                >
                    <span>Edit</span>
                </Dropdown.Item>
            )}
            {isDuplicate && (
                <Dropdown.Item
                    onClick={() => {
                        actionHandler(id, EventActionType.DUPLICATE);
                    }}
                >
                    <span>Duplicate</span>
                </Dropdown.Item>
            )}
            {isDelete && (
                <Dropdown.Item
                    onClick={() => {
                        actionHandler(id, EventActionType.DELETE);
                    }}
                >
                    <span className="text-danger">Delete</span>
                </Dropdown.Item>
            )}
            {isRefresh && (
                <Dropdown.Item
                    onClick={() => {
                        actionHandler(id, EventActionType.REFRESH);
                    }}
                >
                    <span>Refresh</span>
                </Dropdown.Item>
            )}
        </DropdownButton>
    );
};

export default EventActions;
