import { Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NoDataEnums } from '../../../shared/constants/html.constant';
import { ConstNumber } from '../../../shared/constants/number.constant';
import EventCard from '../../../shared/elements/EventCard';
import NoDataFound from '../../../shared/elements/NoDataFound';
import useExternalBusiness from '../hooks/useExternalBusiness';
import { FilterArg } from '../../../shared/types/type';

interface Props {
    filter: FilterArg;
}

const ExternalBusiness = (props: Props) => {
    const {
        externalBusinessess,
        hasMore,
        handleFetchNextPage,
        isLoadingBusinessess,
        navigateToDetail,
        isFetching,
    } = useExternalBusiness(props.filter);

    return (
        <>
            {externalBusinessess?.length ? (
                <InfiniteScroll
                    dataLength={externalBusinessess?.length}
                    next={handleFetchNextPage}
                    hasMore={Boolean(hasMore)}
                    scrollThreshold={ConstNumber.VALUE_1}
                    loader={undefined}
                >
                    <Row onClick={navigateToDetail}>
                        {externalBusinessess?.map((externalBusiness, index) => (
                            <EventCard
                                id={externalBusiness.id}
                                key={externalBusiness.id}
                                document={externalBusiness.profile_video}
                                title={externalBusiness.business_name}
                                date={externalBusiness.address}
                                time={''}
                                thumbnail_url={externalBusiness.thumbnail_url}
                                lg={ConstNumber.VALUE_4}
                                xxl={ConstNumber.VALUE_3}
                                disableDetail={false}
                            />
                        ))}
                    </Row>
                </InfiniteScroll>
            ) : isFetching ? (
                <> </>
            ) : (
                !isLoadingBusinessess && (
                    <NoDataFound
                        heading={NoDataEnums.NO_BUSINESS_FOUND}
                        subHeading=" "
                    />
                )
            )}
        </>
    );
};

export default ExternalBusiness;
