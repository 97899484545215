import logo from '../../../assets/images/logo.png';
import monitor from '../../../assets/images/monitor.svg';
import bar from '../../../assets/images/bar.svg';
import grid from '../../../assets/images/grid.svg';
import award from '../../../assets/images/award.svg';
import tv from '../../../assets/images/tv.svg';
import hand from '../../../assets/images/hand.png';
import notification from '../../../assets/images/notification.svg';
import avatar from '../../../assets/images/avatar.png';
import heart from '../../../assets/images/heart.svg';
import fire from '../../../assets/images/fire.svg';
import calendarBlue from '../../../assets/images/calendar-blue.svg';
import calendarGreen from '../../../assets/images/calendar-green.svg';
import star from '../../../assets/images/all-star.svg';
import plus from '../../../assets/images/plus.svg';
import trash from '../../../assets/images/trash.svg';
import sampleCard from '../../../assets/images/sample-card.jpg';
import sampleCard1 from '../../../assets/images/sample-card-1.jpg';
import edit from '../../../assets/images/edit.svg';
import iconRight from '../../../assets/images/icon-right.svg';
import close from '../../../assets/images/close.svg';
import editImage from '../../../assets/images/edit-white.svg';
import clock from '../../../assets/images/clock.svg';
import menu from '../../../assets/images/menu.svg';
import closeProfile from '../../../assets/images/close-white.svg';
import closeMenu from '../../../assets/images/close-menu.svg';
import loginBg from '../../../assets/images/bg-login.png';
import eyeOpen from '../../../assets/images/eye-open.svg';
import eyeClose from '../../../assets/images/eye-close.svg';
import verification from '../../../assets/images/verification.svg';
import correctCircle from '../../../assets/images/correct-circle.svg';
import back from '../../../assets/images/back.svg';
import chevronDown from '../../../assets/images/chevron-down.svg';
import bgProfile from '../../../assets/images/bg-profile.png';
import logout from '../../../assets/images/logout.svg';
import crown from '../../../assets/images/crown.svg';
import lock from '../../../assets/images/lock.svg';
import date from '../../../assets/images/date.svg';
import alertInfo from '../../../assets/images/alert-color.svg';
import alertDanger from '../../../assets/images/close-color.svg';
import dummyImage from '../../../assets/images/placeholder-image.svg';
import playButton from '../../../assets/images/play-button.svg';
import duplicate from '../../../assets/images/duplicate.svg';
import plusWhite from '../../../assets/images/plus-white.svg';
import noData from '../../../assets/images/no-data-found.svg';
import notFound from '../../../assets/images/not-found.png';
import errorPath from '../../../assets/images/error-404.png';
import internalError from '../../../assets/images/error-500.png';
import underCon from '../../../assets/images/under-construction.png';
import dummyVideo from '../../../assets/images/placeholder-video.png';
import dummyBusiness from '../../../assets/images/placeholder-business.png';
import trashAdmin from '../../../assets/images/trash-admin.svg';
import colorfilter from '../../../assets/images/colorfilter.svg';
import calendarTick from '../../../assets/images/calendar-tick.svg';
import calendarSearch from '../../../assets/images/calendar-search.svg';
import warehouse from '../../../assets/images/warehouse.svg';
import ticket from '../../../assets/images/ticket-discount.svg';
import adminNotification from '../../../assets/images/admin-notification.svg';
import userSquare from '../../../assets/images/user-square.svg';
import search from '../../../assets/images/search.svg';
import moreIcon from '../../../assets/images/more-icon.svg';
import userIcon from '../../../assets/images/avatar.svg';
import businessImagePlaceholder from '../../../assets/images/business_image_placeholder.png';
import locationBlue from '../../../assets/images/location-blue.svg';
import arrowRight from '../../../assets/images/arrow-right.svg';
import categoryPlaceholder from '../../../assets/images/category_placeholder.svg';
import smsEdit from '../../../assets/images/sms-edit.svg';
import calendarIconBlue from '../../../assets/images/calendar-icon-blue.svg';
import bellIcon from '../../../assets/images/bell-icon.svg';
import info from '../../../assets/images/information-fill.svg';

export const LOGO = logo;
export const MONITOR = monitor;
export const BAR = bar;
export const GRID = grid;
export const AWARD = award;
export const TV = tv;
export const HAND = hand;
export const NOTIFICATION = notification;
export const AVATAR = avatar;
export const HEART = heart;
export const FIRE = fire;
export const CALENDARBLUE = calendarBlue;
export const CALENDARGREEN = calendarGreen;
export const STAR = star;
export const PLUS = plus;
export const SAMPLECARD = sampleCard;
export const SAMPLECARD1 = sampleCard1;
export const EDIT = edit;
export const TRASH = trash;
export const ICONRIGHT = iconRight;
export const CLOSE = close;
export const EDITIMAGE = editImage;
export const CLOCK = clock;
export const MENU = menu;
export const CLOSEMENU = closeMenu;
export const CLOSEPROFILE = closeProfile;
export const LOGINBG = loginBg;
export const EYEOPEN = eyeOpen;
export const EYECLOSE = eyeClose;
export const VERIFICATION = verification;
export const CORRECTCIRCLE = correctCircle;
export const BACK = back;
export const CHEVRONDOWN = chevronDown;
export const LOCK = lock;
export const CROWN = crown;
export const LOGOUT = logout;
export const BGPROFILE = bgProfile;
export const DATEICON = date;
export const ALERTINFO = alertInfo;
export const ALERTDANGER = alertDanger;
export const DUMMYIMAGE = dummyImage;
export const PLAYBUTTON = playButton;
export const DUPLICATE = duplicate;
export const PLUSWHITE = plusWhite;
export const NODATA = noData;
export const NOTFOUND = notFound;
export const ERRORPATH = errorPath;
export const INTERNAL_ERROR = internalError;
export const UNDERCON = underCon;
export const DUMMYVIDEO = dummyVideo;
export const DUMMYBUSINESS = dummyBusiness;
export const TRASHADMIN = trashAdmin;
export const COLOR_FILTER = colorfilter;
export const CALENDARTICK = calendarTick;
export const CALENDARSEARCH = calendarSearch;
export const WAREHOUSE = warehouse;
export const TICKET = ticket;
export const ADMINNOTIFICATION = adminNotification;
export const USERSQUARE = userSquare;
export const SEARCH = search;
export const MORE = moreIcon;
export const USER_ICON = userIcon;
export const BUSINESS_IMAGE_PLACEHOLDER = businessImagePlaceholder;
export const LOCATIONBLUE = locationBlue;
export const ARROWRIGHT = arrowRight;
export const CATEGORY_PLACEHOLDER = categoryPlaceholder;
export const SMSEDIT = smsEdit;
export const CALENDARICONBLUE = calendarIconBlue;
export const BELLICON = bellIcon;
export const INFOICON = info;
