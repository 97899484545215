import { localStorageKeys } from '../constants/application.constant';
import { PrivateRoutes } from '../routes/PrivateRoutes/PrivateRoutesConfig';
import { getStorageData } from './storage.helpers';

export function isArrayWithLength(arr: Array<string>) {
    return Array.isArray(arr) && arr.length;
}

export function getAllowedRoutes(routes: Array<PrivateRoutes>) {
    const role = getStorageData(localStorageKeys.ROLE) as string;
    let childRouting: PrivateRoutes[] = [];
    const routing = routes.filter(({ permission, children }: PrivateRoutes) => {
        if (!permission) {
            return true;
        } else if (!isArrayWithLength(permission)) {
            return true;
        } else {
            if (children?.length) {
                const childRoutingLocal = children.filter(
                    ({ permission: childPermission }: PrivateRoutes) => {
                        if (!permission) {
                            return true;
                        } else if (!isArrayWithLength(permission)) {
                            return true;
                        } else {
                            return intersection(childPermission, role).length;
                        }
                    }
                );
                childRouting = [...childRouting, ...childRoutingLocal];
            }
            return intersection(permission, role).length;
        }
    });
    if (routing.length) {
        routing[0].children = [...childRouting];
    }
    return routing;
}

function intersection(array1: string[], role: string) {
    const set1 = new Set(array1);
    const result: String[] = [];

    set1.forEach((value) => {
        if (value === role) {
            result.push(value);
        }
    });

    return result;
}
