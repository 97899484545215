import { ConstNumber } from './number.constant';

export const customStyles = {
    multiValueLabel: (def: any) => ({
        ...def,
        borderRadius: '30px 0 0 30px',
        background: '#47B5FF',
        color: '#fff',
        fontSize: '14px',
        padding: '9px 10px 9px 20px !important',
    }),
    multiValueRemove: (def: any) => ({
        ...def,
        borderRadius: '0 30px 30px 0',
        background: '#47B5FF',
        color: '#fff',
        fontSize: '14px',
        padding: '9px 20px 9px 0 !important',
        '&:hover': {
            background: '#47B5FF',
            color: '#fff',
        },
    }),
    multiValue: (def: any) => ({
        ...def,
        borderRadius: '30px',
        background: '#47B5FF',
        maxWidth: '97%',
    }),
    control: (def: any) => ({
        ...def,
        minHeight: '50px',
        borderRadius: '10px',
        border: '1px solid #E9ECEF',
        background: 'rgba(233, 236, 239, 0.08)',
        '&:hover': {
            borderColor: '#E9ECEF',
        },
        '@media screen and (min-width: 1400px)': {
            minHeight: '60px',
        },
    }),
    indicatorsContainer: (def: any) => ({
        ...def,
        height: '27px',
        width: '27px',
        borderRadius: '50%',
        background: '#E9ECEF',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px 15px 0 0',
        color: '#222222 !important',
        '@media screen and (min-width: 1400px)': {
            marginTop: '15px',
        },
        '&:hover': {
            color: '#222222 !important',
        },
    }),
    indicatorSeparator: (def: any) => ({
        ...def,
        display: 'none',
    }),
    valueContainer: (base: any) => ({
        ...base,
        overflow: 'auto',
        padding: '5px 18px',
        '@media screen and (min-width: 1400px)': {
            padding: '10px 18px',
        },
    }),
    option: (styles: any, { isSelected, isFocused }: any) => {
        return {
            ...styles,
            backgroundColor:
                isSelected && !isFocused
                    ? null
                    : isFocused && !isSelected
                    ? styles.backgroundColor
                    : isFocused && isSelected
                    ? '#E1F3FF'
                    : null,
            color: isSelected ? null : null,
            padding: '10px 20px 9px',
        };
    },
    menu: (def: any) => ({
        ...def,
        zIndex: ConstNumber.VALUE_99,
        marginTop: '4px',
        borderTop: 0,
        borderRadius: '10px',
    }),
    menuList: (def: any) => ({
        ...def,
        padding: '10px 0',
    }),
};
