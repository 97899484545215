import React from 'react';
import { CLOSEMENU } from '../../../shared/constants/image.constant';
import { Button } from 'react-bootstrap';
import { Candidate } from '../utils/slice.types';
import { ConfirmPopup } from '../../../shared/constants/application.constant';

type Props = {
    businessInfo: Candidate[];
    businessHandler: (value: string) => void;
};

const BusinessSuggestion = (props: Props) => {
    const business = props.businessInfo[0];
    return (
        <div className="business-modal">
            <div
                className="close-btn"
                role="button"
                onClick={() => {
                    props.businessHandler(ConfirmPopup.NO);
                }}
            >
                <img src={CLOSEMENU} alt="Close" />
            </div>
            <h3 className="modal-title">Does this Business belong to you?</h3>
            <h5>{business.name}</h5>
            <p className="address">{business.formatted_address}</p>
            <p>
                If this business belongs to you then click on yes and you can
                fill other fields if no you have to add address and fields too.
            </p>
            <div className="footer-btn">
                <Button
                    variant="link"
                    onClick={() => props.businessHandler(ConfirmPopup.NO)}
                >
                    No
                </Button>
                <Button
                    variant="primary"
                    onClick={() => props.businessHandler(ConfirmPopup.YES)}
                >
                    Yes
                </Button>
            </div>
        </div>
    );
};

export default BusinessSuggestion;
