import { HttpMethods } from '../../../shared/constants/application.constant';
import { interceptorMiddleware } from '../../../shared/services/interceptor.service';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiUrlSubscription } from '../constants/apiUrl.subscription';
import { ActivePaymentPlan } from '../utils/slice.types';
import { ListResponse } from '../../../shared/utils/types.elements';

// Define a service using a base URL and expected endpoints
export const subscriptionApi = createApi({
    reducerPath: 'subscription',
    baseQuery: interceptorMiddleware,
    tagTypes: ['subscription'],
    endpoints: (builder) => ({
        getActivePlan: builder.query<
            ActivePaymentPlan[],
            { payment_plan__plan_type: number; businessId?: number }
        >({
            query: ({ payment_plan__plan_type, businessId }) => {
                let queryString = '';
                if (payment_plan__plan_type) {
                    queryString += `payment_plan__plan_type=${payment_plan__plan_type}`;
                }
                if (businessId) {
                    queryString += `&user_id=${businessId}`;
                }
                return {
                    url: `${apiUrlSubscription.GET_SUBSCRIPTION}?${queryString}`,
                    method: HttpMethods.GET,
                };
            },
            transformResponse: (res: ListResponse<ActivePaymentPlan>) =>
                res.data,
            providesTags: ['subscription'],
        }),
        cancelActivePlan: builder.mutation<{ message: string }, {}>({
            query: ({}) => ({
                url: `${apiUrlSubscription.CANCEL_SUBSCRIPTION}`,
                method: HttpMethods.POST,
            }),
        }),
    }),
});

export const { useGetActivePlanQuery, useCancelActivePlanMutation } =
    subscriptionApi;
