import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeObj } from '../../../shared/constants/routes.constants';
import { ConstNumber } from '../../../shared/constants/number.constant';
import {
    eventFormSchema,
    getCategoryData,
    getCategoryOptionsData,
    getMapDateAndTime,
    getUniqueLevel2Data,
} from '../utils/utils';
import {
    Category,
    FormParams,
    Option,
} from '../../../shared/utils/types.elements';
import {
    useGetBusinessLocationsQuery,
    useGetBusinessUserQuery,
    useGetCategoriesQuery,
} from '../../businessInfo/slices/slice.businessInfo';
import { DateTimeParam, DateType } from '../../specials/utils/types.specials';
import { EventForm } from '../constants/types.events';
import {
    useCreateEventMutation,
    useGetEventByIdQuery,
    useUpdateEventMutation,
} from '../slices/slice.events';
import {
    andAndLogic,
    orOrLogic,
    ternaryLogic,
} from '../../../shared/utils/orLogic';
import {
    convertToOptions,
    formatChangeDateTime,
    formatDateAndTime,
    generateBusinessLocationOptions,
    getAdjustedStartDate,
    getS3MediaUrl,
} from '../../../shared/utils/helper';
import {
    EventActionType,
    TabKeys,
} from '../../../shared/constants/application.constant';
import moment from 'moment';
import { ToastEnum } from '../../../shared/constants/html.constant';
import Toast from '../../../shared/utils/toast.helper';
import { ErrorObj } from '../../../shared/types/type';
import { uploadTos3 } from '../../../shared/utils/api.meta';
import { NO_ADDRESS } from '../../../shared/constants/data.constant';

let globalObject: any;

export function useCreateEvent() {
    const params = useLocation().state as FormParams;
    const {
        handleSubmit,
        register,
        formState: { errors, isValid, isSubmitting },
        setValue,
        getValues,
        trigger,
        watch,
        control,
    } = useForm({
        defaultValues: {
            id: undefined,
            name: '',
            address: ConstNumber.VALUE_0,
            start_date: getAdjustedStartDate().toDate(),
            end_date: getAdjustedStartDate().toDate(),
            start_time: moment().add(ConstNumber.VALUE_15, 'minutes').toDate(),
            end_time: moment().add(ConstNumber.VALUE_30, 'minutes').toDate(),
            description: null,
            categories_list: [],
            price_range: null,
            ticket_url: null,
        },
        resolver: yupResolver(eventFormSchema),
        mode: 'onChange',
    });
    const navigate = useNavigate();
    const [eventCategories, setEventCategories] = useState<Category[]>([]);
    const [eventCategoryOptions, setEventCategoryOptions] = useState<Option[]>(
        []
    );
    const [eventOptionSelected, setEventOptionSelected] = useState<Option[]>(
        []
    );
    const [eventSubCategory, setEventSubCategory] = useState<string[]>([]);
    const [show, setShow] = useState(false);
    const [isSkip, setIsSkip] = useState(false);
    const {
        data,
        isLoading: isLoadingGetCategories,
        refetch,
    } = useGetCategoriesQuery({
        categoryType: ConstNumber.VALUE_1,
    });
    const { data: businessDetails, isLoading: isLoadingGetBusinessUser } =
        useGetBusinessUserQuery({});
    const { data: locations } = useGetBusinessLocationsQuery({});

    const businessLocation: Option[] = useMemo(
        () => generateBusinessLocationOptions(locations),
        [locations]
    );

    const [
        createEvent,
        { isLoading, error: apiError, data: createEventResponse },
    ] = useCreateEventMutation();
    const {
        data: eventDataById,
        isLoading: isDataFethcing,
        error: dataError,
    } = useGetEventByIdQuery(params?.id, {
        skip: !Boolean(params?.id) || isSkip,
    });
    const [
        updateEvent,
        { isLoading: isUpdating, error: updateError, data: updateResponse },
    ] = useUpdateEventMutation();

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (dataError) {
            setIsSkip(true);
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (dataError as ErrorObj)?.message
            );
        }
    }, [dataError]);

    useEffect(() => {
        if (updateResponse?.message) {
            Toast.success(ToastEnum.EVENT_UPDATED, updateResponse?.message);
        } else if (updateError) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (updateError as ErrorObj)?.message
            );
        }
    }, [updateResponse?.message, updateError]);

    useEffect(() => {
        data && setEventCategories(data.category);
    }, [data]);

    useEffect(() => {
        if (eventCategories.length) {
            const categoryOptionsData: Option[] =
                getCategoryOptionsData(eventCategories);
            setEventCategoryOptions(categoryOptionsData);
        }
    }, [eventCategories]);

    useEffect(() => {
        if (eventOptionSelected?.length && eventCategories.length) {
            const uniqueLevel2Data = getUniqueLevel2Data(
                eventOptionSelected,
                eventCategories
            );
            setEventSubCategory(uniqueLevel2Data);
        } else {
            setEventSubCategory([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventOptionSelected]);

    const handleChange = useCallback(
        (selected: Option[]): void => {
            setEventOptionSelected(selected);
            setValue(
                'categories_list',
                selected?.map((val) => val.value as number) ?? []
            );
            trigger('categories_list');
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [eventOptionSelected, setValue, trigger]
    );

    function mapDateAndTime(
        date: Date | null,
        time: Date,
        dateType: DateType,
        dependentDateType: DateType,
        typeParam: DateTimeParam
    ) {
        if (date !== null) {
            const updatedDateTime: Date = getMapDateAndTime(
                date,
                time,
                typeParam
            );
            setValue(dateType, updatedDateTime);
            setValue(dependentDateType, updatedDateTime);
        } else {
            setValue(dateType as any, null);
            setValue(dependentDateType, time);
        }
        trigger(['start_date', 'end_date', 'start_time', 'end_time']);
    }

    const handleDateChange = (
        date: Date | null,
        dateType: DateType | string
    ) => {
        if (orOrLogic(dateType === 'start_date', dateType === 'end_date')) {
            const dependentDateType = ternaryLogic(
                dateType === 'start_date',
                'start_time',
                'end_time'
            );
            const dependentTime = getValues(dependentDateType);
            dependentTime &&
                mapDateAndTime(
                    date,
                    dependentTime,
                    dateType as DateType,
                    dependentDateType,
                    'date'
                );
            const alternateDateType = ternaryLogic(
                dateType === 'start_date',
                'end_date',
                'start_date'
            );
            const alternateTimeType = ternaryLogic(
                alternateDateType === 'start_date',
                'start_time',
                'end_time'
            );
            const alternateDate = getValues(alternateDateType);
            const alternateTime = getValues(alternateTimeType);
            andAndLogic(alternateDate, alternateTime) &&
                mapDateAndTime(
                    alternateDate,
                    alternateTime,
                    alternateDateType,
                    alternateTimeType,
                    'date'
                );
        } else if (
            orOrLogic(dateType === 'start_time', dateType === 'end_time')
        ) {
            const dependentDateType = ternaryLogic(
                dateType === 'start_time',
                'start_date',
                'end_date'
            );
            const dependentDate = getValues(dependentDateType);
            dependentDate &&
                mapDateAndTime(
                    date, // this date is actually used for getting time from the Date obj
                    dependentDate,
                    dateType as DateType,
                    dependentDateType,
                    'time'
                );
            const alternateTimeType = ternaryLogic(
                dateType === 'start_time',
                'end_time',
                'start_time'
            );
            const alternateDateType = ternaryLogic(
                alternateTimeType === 'start_time',
                'start_date',
                'end_date'
            );
            const alternateTime = getValues(alternateTimeType);
            const alternateDate = getValues(alternateDateType);
            if (alternateTime && alternateDate) {
                mapDateAndTime(
                    alternateTime,
                    alternateDate,
                    alternateTimeType,
                    alternateDateType,
                    'time'
                );
            }
        }
    };

    const setInitialValues = useCallback((): void => {
        if (eventDataById) {
            globalObject = {
                ...globalObject,
                name: eventDataById.name,
                description: eventDataById.description,
                start_datetime: formatDateAndTime(
                    new Date(eventDataById.start_datetime),
                    new Date(eventDataById.start_datetime)
                ),
                end_datetime: formatDateAndTime(
                    new Date(eventDataById.end_datetime),
                    new Date(eventDataById.end_datetime)
                ),
                document: eventDataById.document,
                price_range: eventDataById.price_range as any,
                ticket_url: eventDataById.ticket_url as any,
                display_start_datetime: formatChangeDateTime(
                    new Date(eventDataById.display_start_datetime),
                    new Date(eventDataById.display_start_datetime)
                ),
                display_end_datetime: formatChangeDateTime(
                    new Date(eventDataById.display_end_datetime),
                    new Date(eventDataById.display_end_datetime)
                ),
            };
            setValue('address', eventDataById?.address.id);
            setValue('id', eventDataById.id);
            setValue(
                'name',
                params?.type === EventActionType.DUPLICATE
                    ? `Duplicate-${eventDataById.name}`
                    : eventDataById.name
            );
            setValue('description', eventDataById?.description);
            setValue('document' as any, eventDataById.document);
            setValue('price_range', eventDataById.price_range);
            setValue('ticket_url', eventDataById.ticket_url);
            setValue('start_date', new Date(eventDataById.start_datetime));
            setValue('start_time', new Date(eventDataById.start_datetime));
            setValue('end_date', new Date(eventDataById.end_datetime));
            setValue('end_time', new Date(eventDataById.end_datetime));
            trigger();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessDetails, setValue, eventDataById]);

    const onSubmitForm = useCallback(
        async (values: EventForm): Promise<void> => {
            let res,
                isUploaded = false;
            if (values?.document && values?.s3MediaUrl) {
                isUploaded = await uploadTos3(
                    values.s3MediaUrl,
                    values.document
                );
            }
            if (params?.id && params?.type === EventActionType.EDIT) {
                // edit
                res = await updateEvent({
                    data: {
                        ...values,
                        s3MediaUrl: getS3MediaUrl(
                            isUploaded,
                            values.s3MediaUrl
                        ),
                    },
                    initialValues: globalObject,
                    isClaimedEvent: eventDataById?.is_published || false,
                });
            } else {
                //create or duplicate
                res = await createEvent({
                    ...values,
                    s3MediaUrl: getS3MediaUrl(isUploaded, values.s3MediaUrl),
                });
            }
            const activeTab =
                params?.activeTab === TabKeys.CLAIMED_EVENTS &&
                params?.type === EventActionType.EDIT
                    ? TabKeys.CLAIMED_EVENTS
                    : undefined;
            'data' in res &&
                navigate(routeObj.BUSINESS_EVENT_DETAIL, {
                    state: {
                        id:
                            params?.id && params?.type === EventActionType.EDIT
                                ? params.id
                                : res.data.data.id,
                        activeTab,
                    },
                });
        },
        []
    );

    const setDropdownVaue = useCallback((): void => {
        const categoryData = getCategoryData(
            eventDataById,
            eventCategoryOptions
        );
        const categoryField = categoryData?.map((val) => val.value);
        setEventOptionSelected(categoryData ?? []);
        setValue('categories_list', categoryField ?? []);
        trigger('categories_list');
        if (eventDataById) {
            globalObject.categories_list = categoryField;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventCategoryOptions, eventOptionSelected, eventDataById]);

    const getSelectedValue = () => {
        const selectedLocation = locations?.find(
            (location) => location.id === getValues('address')
        );
        if (selectedLocation) {
            return convertToOptions([selectedLocation], 'id', 'address');
        } else {
            return [];
        }
    };

    const handleAddressChange = (selected: Option) => {
        setValue('address', selected.value as number);
        trigger('address');
    };

    return {
        register,
        handleSubmit,
        onSubmitForm,
        setValue,
        trigger,
        errors,
        isValid,
        getValues,
        eventCategoryOptions,
        eventOptionSelected,
        handleChange,
        eventSubCategory,
        watch,
        handleDateChange,
        businessDetails,
        setInitialValues,
        isLoadingGetCategories,
        isLoadingGetBusinessUser,
        isLoading,
        apiError,
        createEventResponse,
        show,
        setShow,
        params,
        isDataFethcing,
        eventDataById,
        setDropdownVaue,
        isUpdating,
        updateError,
        updateResponse,
        duplicateCSS: params?.type === EventActionType.DUPLICATE,
        isSubmitting,
        businessLocation,
        handleAddressChange,
        getSelectedValue,
        control,
    };
}
