import React from 'react';
import { Button } from 'react-bootstrap';
import { PLUSWHITE } from '../constants/image.constant';
import { AddButtonProps } from '../types/type';

export const AddButtonPrimary = (props: AddButtonProps) => {
    const { label = 'Add New', icon = PLUSWHITE, handleClick } = props;
    return (
        <Button variant="primary absolute-button" onClick={handleClick}>
            <img src={icon} alt="add icon" className='add-icon' />
            {label}
        </Button>
    );
};
