export const orHyphenLogic = (element: any) => {
    return element || '-';
};
export const orEmptyLogic = (element: any) => {
    return element || '';
};
export const ternaryLogic = (condition: any, first: any, second: any) => {
    return condition ? first : second;
};
export const andAndLogic = (first: any, second: any) => {
    return first && second;
};
export const orOrLogic = (first: any, second: any) => {
    return first || second;
};
