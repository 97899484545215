import React from 'react';

type Props = {
    resendCode: () => void;
    canResend: boolean;
    customClass?: string;
};

const Resend = (props: Props) => {
    return (
        <>
            <div className={`resend-text ${props.customClass || ''}`}>
                Didn’t get the code?
                <span
                    role="button"
                    onClick={props.resendCode}
                    className={`link ${props.canResend ? 'enable' : 'disable'}`}
                    title="Resend"
                >
                    Resend
                </span>
            </div>
            <div className="resend-bottom-text text-center">
                You will be able to resend after 60 seconds
            </div>
        </>
    );
};

export default Resend;
