import React, { ChangeEvent } from 'react';

interface Props {
    handleImageChange: (e: ChangeEvent<HTMLInputElement>) => void;
    fromCategory?: boolean;
}

const UploadMediaInput = (props: Props) => {
    let acceptType = '';
    if (props.fromCategory) {
        acceptType = 'image/heic,image/png,image/jpeg';
    } else {
        acceptType = '.mp4,.mov,image/heic,image/png,image/jpeg';
    }

    return (
        <div className="form-control custom-upload">
            <input
                type="file"
                accept={acceptType}
                onChange={props.handleImageChange}
            />
            <div className="upload-text">
                <span>Drag & Drop a file or click to upload</span>
                <span>
                    (Accepted file types:{' '}
                    {props.fromCategory
                        ? '.heif, .jpeg, .jpg, .png'
                        : '.heif, .jpeg, .jpg, .png, .mp4 or .mov'}
                    )
                </span>
                <span>
                    Max file size (
                    {props.fromCategory ? 'image' : 'image/video'}):{' '}
                    {props.fromCategory ? '8 MB' : '8 MB/100 MB'}
                </span>
                {!props.fromCategory && (
                    <span>Max duration (video): 20 seconds</span>
                )}
            </div>
        </div>
    );
};

export default UploadMediaInput;
