export enum apiUrlOnBoarding {
    LOGOUT = '/v1/logout/',
    GOOGLE_BUSINESS = '/v1/google-business-info/',
    GET_ADDRESS_FROM_LATLNG = '/v1/google-business-address/',
    VERIFY_TEST_USER = '/v1/verify-test-user/',
    RESET_PASSWORD = '/v1/reset-password/',
    USER_REGISTER = '/v1/user-register/',
    GET_L1CATEGORIES = '/v1/level1_category_plan/',
    GET_PAYMENT_PLAN = '/v1/payment_plan',
    GET_SUBSCRIPTION = '/v1/business-level1/',
    CHARGE_CC = '/v1/charge/',
    NEWSLETTER_STATUS = '/v1/user-sms-newsletter-status/',
}
