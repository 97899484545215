import { Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ButtonLabel, Heading } from '../../../shared/constants/html.constant';
import { ConstNumber } from '../../../shared/constants/number.constant';
import { ConfirmationPopup } from '../../../shared/elements/ConfirmationPopup';
import EventCard from '../../../shared/elements/EventCard';
import {
    getFromToDateValue,
    getFromToTimeValue,
} from '../../../shared/utils/helper';
import useEventsHooks, { IEventProps } from '../hooks/useEvents';
import EventPlaceholder from './EventPlaceholder';
import UploadEvents from '../elements/UploadEvents';

const MyEvents = (props: IEventProps) => {
    const {
        events,
        hasMore,
        handleFetchNextPage,
        isLoadingEvents,
        actionHandler,
        show,
        handleShow,
        handleConfirm,
        deletedEvent,
        isDeleting,
        showUpload,
        resetOnBulkUpload,
        handleShowUpload,
    } = useEventsHooks(props);

    return (
        <>
            {showUpload && (
                <UploadEvents
                    show={showUpload}
                    handleShow={handleShowUpload}
                    resetOnBulkUpload={resetOnBulkUpload}
                />
            )}
            <ConfirmationPopup
                visible={show}
                CloseBtnText={Heading.CANCEL}
                ConfirmBtnText={Heading.DELETE}
                heading={Heading.DELETE_EVENT}
                subHeading={`${Heading.DELETE_EVENT_SUBTEXT} ${deletedEvent?.name}?`}
                handleClose={handleShow}
                onConfirm={handleConfirm}
                isLoading={isDeleting}
                loadingLabel={ButtonLabel.DELETING}
            />
            {events?.length ? (
                <InfiniteScroll
                    dataLength={events?.length}
                    next={handleFetchNextPage}
                    hasMore={Boolean(hasMore)}
                    scrollThreshold={ConstNumber.VALUE_1}
                    loader={undefined}
                >
                    <Row>
                        {events?.map((event, index) => (
                            <EventCard
                                customClass="no-detail"
                                id={event.id}
                                key={event.id}
                                document={event.document}
                                title={event.name}
                                date={getFromToDateValue(
                                    event.display_start_datetime,
                                    event.display_end_datetime
                                )}
                                time={getFromToTimeValue(
                                    event.display_start_datetime,
                                    event.display_end_datetime
                                )}
                                actionHandler={actionHandler}
                                thumbnail_url={event.thumbnail_url}
                                isDuplicate
                            />
                        ))}
                    </Row>
                </InfiniteScroll>
            ) : (
                !isLoadingEvents && <EventPlaceholder />
            )}
        </>
    );
};

export default MyEvents;
