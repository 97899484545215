import { Auth } from 'aws-amplify';
import {
    Bool,
    UserRole,
    localStorageKeys,
} from '../../../shared/constants/application.constant';
import { ToastEnum } from '../../../shared/constants/html.constant';
import { routeObj } from '../../../shared/constants/routes.constants';
import {
    getStorageData,
    setDataIntoLocalStorage,
} from '../../../shared/utils/storage.helpers';
import Toast from '../../../shared/utils/toast.helper';
import { StorageDataResponse } from '../../../shared/utils/types.elements';
import { useGetSubscriptionInfoByCatgeoryIdMutation } from '../slices/slice.authApi';

export function useGetMonthlyPlan(catLvl1Id: number) {
    const [getSubscriptionPlan, { isLoading: gettingPlan }] =
        useGetSubscriptionInfoByCatgeoryIdMutation();
    const getMonthlyPlan = async () => {
        try {
            await getSubscriptionPlan({
                level1_id: catLvl1Id,
            });
            const cognitoUser = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(cognitoUser, {
                'custom:is_profile_completed': Bool.true,
            });
            const userInfo = getStorageData() as StorageDataResponse;
            setDataIntoLocalStorage(localStorageKeys.USER, {
                ...userInfo,
                role: UserRole.BUSINESS,
            });
            window.location.href = routeObj.BUSINESS_DASHBOARD;
        } catch (error: any) {
            Toast.error(ToastEnum.SOMETHING_WENT_WRONG, error.message);
        }
    };
    return {
        getMonthlyPlan,
        gettingPlan,
    };
}
