import { Form } from 'react-bootstrap';
import { ButtonLabel, HelpText } from '../../../shared/constants/html.constant';
import { ConstNumber } from '../../../shared/constants/number.constant';
import { useVerifyOtp } from '../hooks/useVerifyOtp';
import { Link } from 'react-router-dom';
import { routeObj } from '../../../shared/constants/routes.constants';
import CustomInput from '../../../shared/elements/CustomInput';
import { SubmitButton } from '../../../shared/elements/Buttons';
import Resend from '../elements/Resend';
import { VerificationOTPProps } from '../utils/slice.types';

const VerificationOTP = (prop: VerificationOTPProps) => {
    const { username, setShowOtp, isSignIn = false } = prop;
    const {
        isDataLoading,
        isLoading,
        canResend,
        resendCode,
        verifySubmit,
        handleSubmit,
        register,
        trigger,
        errors,
        isValid,
    } = useVerifyOtp(prop);

    function navigateToSignIn(): void {
        if (setShowOtp) {
            setShowOtp(false);
        }
    }

    return (
        <div className="login-inner verify-container">
            <h1 className="login-title">Verify Your Registered Email ID</h1>
            <p className="verify-subtext">
                We have sent a verification code to your registered email ID{' '}
                <span>{username}</span>
            </p>
            <Form onSubmit={handleSubmit(verifySubmit)} className="verify-form">
                <CustomInput
                    label="Enter Code"
                    type="text"
                    name={'otp'}
                    placeholder="Enter Code"
                    maxLength={ConstNumber.VALUE_6}
                    errorMsg={errors.otp?.message}
                    onBlur={() => trigger('otp')}
                    register={register}
                />
                <div className="help-text-container">
                    <p>
                        <strong>{HelpText.NOTE}</strong> - {HelpText.JUNK_EMAIL}
                    </p>
                </div>
                <div className="signin-bottom less-margin">
                    <Resend canResend={canResend} resendCode={resendCode} />
                </div>
                <div className="btn-container">
                    <SubmitButton
                        label={ButtonLabel.VERIFY}
                        isDisable={!isValid || isLoading || isDataLoading}
                        isLoading={isLoading || isDataLoading}
                        loadingLabel={ButtonLabel.VERIFING}
                    />
                </div>
            </Form>
            <div className="signin-bottom">
                Back to
                <Link
                    to={routeObj.DEFAULT}
                    onClick={navigateToSignIn}
                    title="Sign In"
                >
                    Sign In
                </Link>
            </div>
        </div>
    );
};

export default VerificationOTP;
