import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
    useChargeCreditCardMutation,
    useGetL1CategoriesAssociatedWithPaymentQuery,
    useGetPaymentPlanMutation,
    useGetSubscriptionInfoByCatgeoryIdMutation,
} from '../slices/slice.authApi';
import { BusinessTypeSchema } from '../utils/utils';
import { useNavigate } from 'react-router-dom';
import { routeObj } from '../../../shared/constants/routes.constants';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import {
    Bool,
    PromotionTypeEnum,
    UserRole,
    localStorageKeys,
} from '../../../shared/constants/application.constant';
import {
    getStorageData,
    setDataIntoLocalStorage,
} from '../../../shared/utils/storage.helpers';
import { StorageDataResponse } from '../../../shared/utils/types.elements';
import Toast from '../../../shared/utils/toast.helper';
import { ToastEnum } from '../../../shared/constants/html.constant';
import { ErrorObj } from '../../../shared/types/type';
import { useApplyPromocodeMutation } from '../../../shared/slices/slice.meta';

export function useBusinessType() {
    const { data, isLoading } = useGetL1CategoriesAssociatedWithPaymentQuery(
        {}
    );
    const [
        chargeCreditCard,
        {
            isLoading: isChargingCC,
            isSuccess: isPaymentSuccess,
            error: paymentError,
            data: paymentData,
        },
    ] = useChargeCreditCardMutation();
    const [getSubscriptionPlan, { isLoading: gettingPlan }] =
        useGetSubscriptionInfoByCatgeoryIdMutation();
    const [getPaymentPlan, { isLoading: gettingPaymentPlan, isError }] =
        useGetPaymentPlanMutation();
    const [
        applyPromoCode,
        {
            isSuccess: isPromoApplied,
            data: promoData,
            reset: resetPromo,
            error: promoError,
            isLoading: promoLoading,
        },
    ] = useApplyPromocodeMutation({});
    const [coupon, setCoupon] = useState('');
    const navigate = useNavigate();
    const {
        handleSubmit,
        formState: { isValid },
        getValues,
        control,
    } = useForm({
        resolver: yupResolver(BusinessTypeSchema),
    });

    useEffect(() => {
        if (paymentError) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (paymentError as ErrorObj).message
            );
        } else if (paymentData) {
            Toast.success(ToastEnum.PAYMENT_DONE, paymentData.message);
        }
    }, [paymentData, paymentError]);

    useEffect(() => {
        if (promoError) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (promoError as ErrorObj)?.message
            );
        }
    }, [promoError]);

    const couponHandler = async (_coupon: string) => {
        const res = await applyPromoCode({
            code: _coupon,
            type: PromotionTypeEnum.SUBSCRIPTION,
        });
        if ('data' in res) {
            setCoupon(_coupon);
        }
    };

    const resetHandler = () => {
        setCoupon('');
        resetPromo();
    };

    const onSubmit = async (values: { level1_id: number }) => {
        if (!promoData?.card_required && promoData?.promo_id) {
            // get plan id for cc charge
            const paymentPlan = await getPaymentPlan({
                id: values.level1_id,
                promo_code: coupon,
            });
            if ('data' in paymentPlan) {
                // call charge api without token, with valid free promo code
                const res = await chargeCreditCard({
                    plan_id: paymentPlan.data.id,
                    promo_code: promoData?.promo_id,
                });
                if ('data' in res) {
                    // get subscription plan
                    await getSubscriptionPlan({
                        level1_id: values.level1_id,
                    });
                    const cognitoUser = await Auth.currentAuthenticatedUser();
                    await Auth.updateUserAttributes(cognitoUser, {
                        'custom:is_profile_completed': Bool.true,
                    });
                    const userInfo = getStorageData() as StorageDataResponse;
                    setDataIntoLocalStorage(localStorageKeys.USER, {
                        ...userInfo,
                        role: UserRole.BUSINESS,
                    });
                    window.location.href = routeObj.BUSINESS_DASHBOARD;
                }
            }
        } else {
            navigate(
                `${routeObj.SIGN_UP_SELECT_PLAN}?id=${
                    values.level1_id
                }&promo_code=${encodeURIComponent(coupon)}&promo_id=${
                    promoData?.promo_id || ''
                }`
            );
        }
    };

    return {
        isValid,
        handleSubmit,
        onSubmit,
        control,
        getValues,
        data,
        isLoading:
            isLoading || isChargingCC || gettingPlan || gettingPaymentPlan,
        isPromoApplied,
        promoData,
        resetHandler,
        promoError,
        promoLoading,
        couponHandler,
    };
}
