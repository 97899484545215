import { NoDataEnums } from '../../../shared/constants/html.constant';
import { NODATA } from '../../../shared/constants/image.constant';

const EventPlaceholder = () => {
    return (
        <>
            <div className="no-data-container">
                <div className="no-data-inner">
                    <img src={NODATA} alt="No data" className="no-data-image" />
                    <h3>{NoDataEnums.NO_RESULT_FOUND}</h3>
                    <p>You can add events to show here</p>
                    {/* code commeted for now, we may use in future */}
                    {/* <div className="absolute-btn-container">
                        <AddButtonPrimary handleClick={navigateToCreate} label="Add Event" />
                        <AddButton handleClick={() => {}} label="Upload Bulk Events" />
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default EventPlaceholder;
