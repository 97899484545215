import { Crop } from 'react-image-crop';
import { ConstNumber } from './number.constant';
import { EventStatus } from './application.constant';

export const PriceRangeOptions = [
    { label: '$', value: ConstNumber.VALUE_1 },
    { label: '$$', value: ConstNumber.VALUE_2 },
    { label: '$$$', value: ConstNumber.VALUE_3 },
    { label: '$$$$', value: ConstNumber.VALUE_4 },
];

export const InitialCorp: Crop = {
    width: 300,
    height: 200,
    unit: 'px',
    x: 5,
    y: 5,
};

export const OPENHOURS = [
    { label: 'Sunday', value: ConstNumber.VALUE_1, slug: 'Sun' },
    { label: 'Monday', value: ConstNumber.VALUE_2, slug: 'Mon' },
    { label: 'Tuesday', value: ConstNumber.VALUE_3, slug: 'Tue' },
    { label: 'Wednesday', value: ConstNumber.VALUE_4, slug: 'Wed' },
    { label: 'Thursday', value: ConstNumber.VALUE_5, slug: 'Thu' },
    { label: 'Friday', value: ConstNumber.VALUE_6, slug: 'Fri' },
    { label: 'Saturday', value: ConstNumber.VALUE_7, slug: 'Sat' },
];

export const PasswordChecks = [
    { key: 'length', isValid: false, label: 'Min. 8 characters' },
    { key: 'lowercase', isValid: false, label: '1 lowercase' },
    { key: 'uppercase', isValid: false, label: '1 uppercase' },
    { key: 'specialchar', isValid: false, label: '1 special character' },
    { key: 'numeric', isValid: false, label: '1 numeric character' },
];

export const allDays = [
    ConstNumber.SUN,
    ConstNumber.MON,
    ConstNumber.TUE,
    ConstNumber.WED,
    ConstNumber.THU,
    ConstNumber.FRI,
    ConstNumber.SAT,
];

export const PLAN_MONTHS = [
    { label: 'One Month', value: ConstNumber.VALUE_1 },
    { label: 'Two Months', value: ConstNumber.VALUE_2 },
    { label: 'Three Months', value: ConstNumber.VALUE_3 },
];

export const NO_ADDRESS = [{ label: 'No Address Found', value: '' }];

export const EVENT_STATUS_OPTIONS = [
    {
        label: 'Published',
        value: EventStatus.PUBLISHED,
    },
    {
        label: 'Unpublished',
        value: EventStatus.UN_PUBLISHED,
    },
];
