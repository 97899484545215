import * as yup from 'yup';
import { ValidationEnums } from '../constants/application.constant';
import { PASSWORD_REGEX } from '../constants/regex.constant';
import { CommonEnum } from '../constants/html.constant';
import { ConstNumber } from '../constants/number.constant';

export function getChangePasswordValues() {
    return {
        currentPassword: '',
        newPassword: '',
    };
}

export const changePasswordFormSchema = yup.object().shape({
    currentPassword: yup.string().trim().required(ValidationEnums.REQUIRED),
    newPassword: yup
        .string()
        .trim()
        .required(ValidationEnums.REQUIRED)
        .matches(PASSWORD_REGEX, ValidationEnums.INCORRECT_PASSWORD)
        .notOneOf(
            [yup.ref('currentPassword')],
            ValidationEnums.SAME_CURRENT_PASSWORD
        )
        .max(ConstNumber.VALUE_14, ValidationEnums.MAX_14),
});

export const getDuplicateText = (isDuplicate: boolean) => {
    return isDuplicate ? CommonEnum.PLEASE_UPDATE_NAME : '*';
};

export function getEventCardClass(isDataWareHouse = false): string | undefined {
    return `three-tile-list ${isDataWareHouse ? 'no-detail' : ''}`;
}
