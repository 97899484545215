const AMPLIFY_CONFIG = {
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_COGNITO_REGION,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID,
    },
};

export default AMPLIFY_CONFIG;
