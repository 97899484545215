import React from 'react';
import { Accordion, Col, Row, Form } from 'react-bootstrap';
import { VenueDetails } from '../utils/types.businessInfo';

interface IAccordianCardProps {
    details: VenueDetails;
    selectedLevels: number[];
    onChangeDetail: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AccordianCard = (props: IAccordianCardProps) => {
    const { details, selectedLevels, onChangeDetail } = props;

    return (
        <Accordion.Item eventKey={details.id.toString()}>
            <Accordion.Header>{details.name}</Accordion.Header>
            <Accordion.Body>
                {details.category_level_3?.map((level) => (
                    <div key={level.id} className='inner-box'>
                        <h4 className="check-title">{level.heading}</h4>
                        <Row>
                            {level.category_level3?.map((subLevel) => (
                                <Col md={3} key={subLevel.id}>
                                    <div className="custom-checkbox">
                                        <Form.Check
                                            type={'checkbox'}
                                            id={subLevel.id.toString()}
                                            label={subLevel.name}
                                            value={subLevel.id}
                                            checked={Boolean(
                                                selectedLevels.includes(
                                                    subLevel.id
                                                )
                                            )}
                                            onChange={onChangeDetail}
                                        />
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                ))}
            </Accordion.Body>
        </Accordion.Item>
    );
};

export default AccordianCard;
