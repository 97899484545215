import React from 'react';
import { Image } from './Image';
import VideoPlayer from './VideoPlayer';

type Props = {
    url?: null | string;
    thumbnail_url?: string | null;
    idForDom?: string;
    disableDetail?: boolean;
};

const Media = (props: Props) => {
    const { url, thumbnail_url, idForDom, disableDetail } = props;
    if (url === null || !url) {
        return (
            <Image
                disableDetail={disableDetail}
                id={idForDom}
                imageUrl={null}
            />
        );
    }
    return (
        <>
            {url && thumbnail_url ? (
                <VideoPlayer
                    videoSrc={url}
                    thumbnail={thumbnail_url}
                    videoTitle="Video"
                    id={idForDom}
                    disableDetail={disableDetail}
                />
            ) : (
                <Image
                    disableDetail={disableDetail}
                    id={idForDom}
                    imageUrl={url}
                />
            )}
        </>
    );
};

export default React.memo(Media);
