import { useEffect, useState } from 'react';
import {
    Bool,
    EventActionType,
    TabKeys,
} from '../../../shared/constants/application.constant';
import { ConstNumber } from '../../../shared/constants/number.constant';
import { useNavigate } from 'react-router-dom';
import {
    eventsApi,
    useDeleteEventMutation,
    useGetEventsQuery,
} from '../slices/slice.events';
import { Event } from '../constants/types.events';
import { routeObj } from '../../../shared/constants/routes.constants';
import { useAppDispatch } from '../../../../store/hooks';
import { ErrorObj } from '../../../shared/types/type';
import Toast from '../../../shared/utils/toast.helper';
import { ToastEnum } from '../../../shared/constants/html.constant';
import {
    andAndLogic,
    orOrLogic,
    ternaryLogic,
} from '../../../shared/utils/orLogic';

export interface IEventProps {
    activeTab: string | null;
    showUpload: boolean;
    handleShowUpload: () => void;
}

const useEventsHooks = (props: IEventProps) => {
    const { activeTab, showUpload, handleShowUpload } = props;
    const navigate = useNavigate();
    const [page, setPage] = useState<number>(ConstNumber.VALUE_1);
    const [events, setEvents] = useState<Event[]>([]);
    const [isLoadingEvents, setIsLoadingEvents] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const [show, setShow] = useState(false);
    const [deletedEvent, setDeletedEvent] = useState<Event | undefined>(
        undefined
    );

    const { data, isLoading, isFetching, refetch } = useGetEventsQuery({
        page,
        isClaimed: ternaryLogic(
            activeTab === TabKeys.MY_EVENTS,
            Bool.FALSE,
            Bool.TRUE
        ),
    });
    const [
        deleteEvent,
        { isLoading: isDeleting, error: deleteError, data: deleteResponse },
    ] = useDeleteEventMutation();
    const hasMore = data && data?.data.length > ConstNumber.VALUE_0;

    const handleFetchNextPage = () => {
        if (andAndLogic(!isFetching, hasMore)) {
            setPage((prevPage) => prevPage + ConstNumber.VALUE_1);
        }
    };

    const actionHandler = (id: number, type: string) => {
        if (
            orOrLogic(
                type === EventActionType.EDIT,
                type === EventActionType.DUPLICATE
            )
        ) {
            navigate(routeObj.BUSINESS_EVENT_CREATE, {
                state: {
                    id,
                    type,
                    activeTab,
                },
            });
        } else if (type === EventActionType.DELETE) {
            const deleteEventValue = events.find((event) => event.id === id);
            setDeletedEvent(deleteEventValue);
            handleShow();
        }
    };

    const handleShow = () => {
        setShow(!show);
    };

    const handleConfirm = () => {
        deletedEvent && deleteEvent({ id: deletedEvent.id });
    };

    const resetData = () => {
        setPage(ConstNumber.VALUE_1);
        setEvents([]);
        dispatch(eventsApi.util.resetApiState());
    };

    useEffect(() => {
        if (data) {
            setEvents((prevData) => [...prevData, ...data.data]);
            setIsLoadingEvents(false);
        }
    }, [data]);

    useEffect(() => {
        // Reset data when component unmounts or changes
        return () => {
            resetData();
        };
    }, []);

    useEffect(() => {
        isLoading && setIsLoadingEvents(true);
    }, [isLoading]);

    useEffect(() => {
        if (deleteResponse?.message) {
            Toast.success(ToastEnum.EVENT_DELETED, deleteResponse?.message);
            handleShow();
            resetData();
            setTimeout(() => {
                refetch();
            }, ConstNumber.VALUE_100);
        } else if (deleteError) {
            handleShow();
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (deleteError as ErrorObj)?.message
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteResponse?.message, deleteError]);

    const resetOnBulkUpload = () => {
        resetData();
        setTimeout(() => {
            refetch();
        }, ConstNumber.VALUE_100);
    };

    useEffect(() => {
        if (activeTab) {
            setPage(ConstNumber.VALUE_1);
            setEvents([]);
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    return {
        events,
        hasMore,
        handleFetchNextPage,
        isLoading,
        isLoadingEvents,
        actionHandler,
        handleShow,
        show,
        handleConfirm,
        deletedEvent,
        isDeleting,
        showUpload,
        handleShowUpload,
        resetOnBulkUpload,
    };
};

export default useEventsHooks;
