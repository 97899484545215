import PromoCodeInput from './PromoCodeInput';

type Props = {
    label: string;
    applyHandler: (coupon: string) => void;
    resetHandler: () => void;
    customClass?: string;
    successMessage?: string;
    isSuccess: boolean;
    isLoading: boolean;
};

const ApplyPromoCode = (props: Props) => {
    const {
        customClass = '',
        successMessage,
        isSuccess,
        isLoading,
        applyHandler,
        resetHandler,
    } = props;

    return (
        <>
            <h2>{props.label}</h2>
            <p>
                Please enter the promotion code below, if any, and get a special
                offer
            </p>
            <div className="position-relative">
                <PromoCodeInput
                    applyCode={applyHandler}
                    removeCoupon={resetHandler}
                    isApplied={isSuccess}
                    isLoading={isLoading}
                />
                {successMessage && (
                    <div className="success-feedback">{successMessage}</div>
                )}
            </div>
        </>
    );
};

export default ApplyPromoCode;
