import { useEffect } from 'react';
import { LOGO } from '../../../shared/constants/image.constant';
import SignUpForm from '../elements/SignUpForm';
import { useSignup } from '../hooks/useSignup';
import VerificationOTP from './VerificationOTP';
import { Link } from 'react-router-dom';
import { routeObj } from '../../../shared/constants/routes.constants';
import { setShowOtp } from '../slices/slice.auth';

export function SignUp() {
    const { showOTP, username, dispatch } = useSignup();

    useEffect(() => {
        /** OTP screen will get hide once user move from sign up screen */
        return () => {
            dispatch(setShowOtp(false));
        };
    }, []);

    return showOTP ? (
        <VerificationOTP username={username} />
    ) : (
        <div className="login-inner">
            <img src={LOGO} alt="Logo" className="logo" />
            <h1 className="login-title">Sign Up</h1>
            <SignUpForm />
            <div className="signin-bottom">
                Already have an account?
                <Link to={routeObj.DEFAULT} title="Sign In">
                    Sign In
                </Link>
            </div>
        </div>
    );
}
