import { useForm } from 'react-hook-form';
import {
    checkProfile,
    checkSubscribed,
    getSignInIntialValues,
    setRole,
    showLoginError,
    signinFormSchema,
} from '../utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { signInUser, signOutUser } from '../slices/slice.auth';
import { setDataIntoLocalStorage } from '../../../shared/utils/storage.helpers';
import {
    Bool,
    CognitoExceptions,
    localStorageKeys,
} from '../../../shared/constants/application.constant';
import { routeObj } from '../../../shared/constants/routes.constants';
import { SignIn } from '../utils/slice.types';
import Toast from '../../../shared/utils/toast.helper';
import { ToastEnum } from '../../../shared/constants/html.constant';
import {
    checkIsAdmin,
    isCognitoAuthAllowed,
} from '../../../shared/utils/helper';
import { useCompleteUserMutation } from '../slices/slice.authApi';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { andAndLogic, ternaryLogic } from '../../../shared/utils/orLogic';

export function useLogin() {
    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
        setValue,
        getValues,
        trigger,
        watch,
    } = useForm({
        defaultValues: getSignInIntialValues(),
        resolver: yupResolver(signinFormSchema),
    });
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [completeUser] = useCompleteUserMutation();
    const { isDataLoading } = useAppSelector((state) => state.auth);
    const [showOtp, setShowOtp] = useState(false);
    const isAuthAllowed = isCognitoAuthAllowed();

    const logout = async () => {
        dispatch(signOutUser())
            .unwrap()
            .then(async () => {
                Toast.error(
                    ToastEnum.ACCOUNT_DEACTIVATED,
                    ToastEnum.ACCOUNT_DEACTIVATED_MSG
                );
            })
            .catch((_error) => {
                alert(_error.message);
            });
    };

    const onSubmitForm = useCallback(async (values: SignIn): Promise<void> => {
        const obj = {
            username: values.username,
            password: values.password,
        };
        const ROLE_KEY = 'custom:role';

        // cognito signin
        dispatch(signInUser(obj))
            .unwrap()
            .then(async (res) => {
                // checking if user deactivated
                if (res.attributes['custom:is_active'] === Bool.false) {
                    logout();
                    return;
                }
                const isProfileCompleted = checkProfile(
                    res.attributes['custom:is_profile_completed']
                );
                const isSubscribed = checkSubscribed(
                    res.attributes['custom:is_subscribed'],
                    res.attributes[ROLE_KEY]
                );
                //saved data into local storage
                setDataIntoLocalStorage(localStorageKeys.USER, {
                    role: setRole(
                        res.attributes[ROLE_KEY],
                        isAuthAllowed,
                        isProfileCompleted,
                        isSubscribed
                    ),
                    access_token: res.signInUserSession.accessToken.jwtToken,
                    username: res.username,
                    expirationTime:
                        res.signInUserSession.accessToken.payload.exp,
                });
                //checking if user perofile is not confirmed or unsubscribed, redirecting select plan
                if (
                    andAndLogic(!isProfileCompleted, isAuthAllowed) ||
                    !isSubscribed
                ) {
                    // TODO Later -- if(res.attributes['custom:is_onboarded'] === Bool.true){
                    //     await completeUser({});
                    // }
                    await completeUser({});
                    navigate(routeObj.SIGN_UP_BUSINESS_TYPE);
                } else {
                    // checking if user perofile is not confirmed, calling register api
                    if (!isProfileCompleted) {
                        const response = await completeUser({});
                        // if success set attribute to true in cognito
                        if ('data' in response) {
                            await Auth.updateUserAttributes(res, {
                                'custom:is_profile_completed': 'true',
                            });
                        }
                    }
                    //check if user is admin or business
                    window.location.href = ternaryLogic(
                        checkIsAdmin(res.attributes[ROLE_KEY]),
                        routeObj.ADMIN,
                        routeObj.BUSINESS_DASHBOARD
                    );
                }
            })
            .catch((_error) => {
                if (_error.code === CognitoExceptions.USER_NOT_CONFIRMED) {
                    setDataIntoLocalStorage(localStorageKeys.TEMP_LOGIN, obj);
                    setShowOtp(true);
                }
                showLoginError(_error);
            });
    }, []);

    return {
        register,
        handleSubmit,
        onSubmitForm,
        setValue,
        trigger,
        watch,
        errors,
        isValid,
        getValues,
        isDataLoading,
        showOtp,
        setShowOtp,
    };
}
