import React, { memo } from 'react';
import { Form } from 'react-bootstrap';
import { UseFormRegister } from 'react-hook-form';
import { PriceRangeOptions } from '../constants/data.constant';

interface Props {
    name: string;
    defaultValue?: number | null;
    register: UseFormRegister<any>;
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleClicked: (value: null | number) => void;
}

interface ShowPriceRangeProp {
    priceRange?: number | null;
}

const PriceRangeRadio = (props: Props) => {
    const { name, defaultValue, register, handleChange, handleClicked } = props;

    const _handleClicked = (val: string) => {
        const value = Number(val);
        if (Number(defaultValue) === value) {
            handleClicked(null);
        } else {
            handleClicked(value);
        }
    };

    return (
        <Form.Group className="form-group" controlId="">
            <Form.Label>
                Price Range <span className="text-muted">(Optional)</span>
            </Form.Label>
            <div className="radio-container">
                {PriceRangeOptions.map((option) => (
                    <div className="custom-radio-chip" key={option.label}>
                        <Form.Check
                            {...register(name)}
                            type={'radio'}
                            id={option.label}
                            label={option.label}
                            name={name}
                            value={option.value}
                            checked={defaultValue === option.value}
                            onClick={(e) => {
                                _handleClicked(e.currentTarget.value);
                            }}
                            onChange={(e) => {
                                e.stopPropagation();
                                if (handleChange) {
                                    handleChange(e);
                                }
                            }}
                            onBlur={(e) => e.stopPropagation()}
                        />
                    </div>
                ))}
            </div>
        </Form.Group>
    );
};

export default memo(PriceRangeRadio);

export const ShowPriceRange = (prop: ShowPriceRangeProp) => {
    const { priceRange } = prop;
    const getPriceRange = (_priceRange?: number | null) => {
        const priceSymbols = ['$', '$$', '$$$', '$$$$'];

        if (_priceRange) {
            return priceSymbols[_priceRange - 1];
        } else {
            return '-';
        }
    };

    return (
        <>
            <Form.Label className="big-label">Price Range</Form.Label>
            <div className="radio-container">
                <div className="custom-radio-chip">
                    <Form.Check // prettier-ignore
                        type={'radio'}
                        id={`$`}
                        label={getPriceRange(priceRange)}
                        value={getPriceRange(priceRange)}
                        checked
                        readOnly
                    />
                </div>
            </div>
        </>
    );
};
