import { useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import {
    ButtonLabel,
    ToastEnum,
} from '../../../shared/constants/html.constant';
import { SubmitButton } from '../../../shared/elements/Buttons';
import { handleDownloadClick } from '../../../shared/utils/helper';
import { Controller } from 'react-hook-form';
import { useUploadEvents } from '../hooks/useUploadEvents';
import Toast from '../../../shared/utils/toast.helper';
import { ErrorObj } from '../../../shared/types/type';

interface IUploadEventsProps {
    show: boolean;
    handleShow: () => void;
    resetOnBulkUpload: () => void;
}

const UploadEvents = (props: IUploadEventsProps) => {
    const { show, handleShow, resetOnBulkUpload } = props;
    const {
        control,
        errors,
        handleSubmit,
        onSubmitForm,
        isValid,
        isLoading,
        apiUploadError,
        uploadEventResponse,
        reset,
        fileInputRef,
    } = useUploadEvents();

    useEffect(() => {
        if (show) {
            reset();
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        if (uploadEventResponse?.message) {
            Toast.success(
                ToastEnum.EVENTS_CREATED,
                uploadEventResponse?.message
            );
            handleShow();
            resetOnBulkUpload();
        } else if (apiUploadError) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (apiUploadError as ErrorObj)?.message
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadEventResponse?.message, apiUploadError]);

    return (
        <Modal
            show={show}
            onHide={handleShow}
            centered
            className="add-user add-location upload-csv-container"
        >
            <Modal.Header closeButton className="justify-content-center">
                <Modal.Title>Upload CSV</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmitForm)}>
                <Modal.Body>
                    <Form.Group className="form-group" controlId="name">
                        <Form.Label>Attach CSV file</Form.Label>
                        <div className="custom-file-upload">
                            <Controller
                                name="document"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Form.Control
                                            type="text"
                                            placeholder="Select File"
                                            readOnly
                                            value={
                                                field?.value
                                                    ? (field.value as any)[0]
                                                          ?.name
                                                    : ''
                                            }
                                        />
                                        <Form.Control
                                            type="file"
                                            accept=".csv"
                                            onChange={(e) => {
                                                e.preventDefault();
                                                const selectedFile = (
                                                    e.target as HTMLInputElement
                                                )?.files?.[0];

                                                if (selectedFile) {
                                                    field.onChange([
                                                        selectedFile,
                                                    ]);
                                                    e.target.value = '';
                                                }
                                            }}
                                            ref={(
                                                input: HTMLInputElement | null
                                            ) => {
                                                field.ref(input);
                                                fileInputRef.current = input;
                                            }}
                                        />
                                        {errors.document && (
                                            <Form.Control.Feedback type="invalid">
                                                {errors.document.message}
                                            </Form.Control.Feedback>
                                        )}
                                    </>
                                )}
                            />
                            <Button variant="primary">Browse</Button>
                        </div>
                    </Form.Group>
                    <Button
                        variant="link"
                        className="down-btn"
                        onClick={handleDownloadClick}
                    >
                        Download Sample
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" onClick={handleShow}>
                        Cancel
                    </Button>
                    <SubmitButton
                        label={ButtonLabel.UPLOAD}
                        isDisable={!isValid || isLoading}
                        isLoading={isLoading}
                        loadingLabel={ButtonLabel.UPLOADING}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default UploadEvents;
