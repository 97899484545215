import { useForm } from 'react-hook-form';
import {
    getResetPasswordIntialValues,
    ResetPasswordFormSchema,
} from '../utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { forgotPassword, resetPassword } from '../slices/slice.auth';
import {
    CognitoAuth,
    Params,
} from '../../../shared/constants/application.constant';
import { routeObj } from '../../../shared/constants/routes.constants';
import { ResetPassword } from '../utils/slice.types';
import Toast from '../../../shared/utils/toast.helper';
import { ToastEnum } from '../../../shared/constants/html.constant';
import { useLocation, useNavigate } from 'react-router-dom';
import { useResetPasswordMutation } from '../slices/slice.authApi';
import { ConstNumber } from '../../../shared/constants/number.constant';

export function useResetPassword() {
    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
        setValue,
        trigger,
        watch,
    } = useForm({
        defaultValues: getResetPasswordIntialValues(),
        resolver: yupResolver(ResetPasswordFormSchema),
    });
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { state: locationState } = useLocation();
    const { isDataLoading } = useAppSelector((state) => state.auth);
    const cognitoAuth = process.env.REACT_APP_COGNITO_AUTH;
    const [resetUserPassword, { isLoading }] = useResetPasswordMutation();
    const [canResend, setCanResend] = useState(false);
    const [show, setShow] = useState(false);

    const disableResend = () => {
        setCanResend(false);
        setTimeout(() => {
            setCanResend(true);
        }, ConstNumber.ONE_MINUTE);
    };

    useEffect(() => {
        disableResend();
    }, []);

    const resendCode = () => {
        if (canResend && cognitoAuth === CognitoAuth.ALLOWED) {
            dispatch(
                forgotPassword({
                    username: locationState?.email,
                })
            )
                .unwrap()
                .then(() => {
                    /**
                     * we have added this message for testing purpose, will remove it in upcoming PR
                     */
                    Toast.success(ToastEnum.OTP_SENT, ToastEnum.OTP_SEND_DECS);
                })
                .catch((_error) => {
                    Toast.error(ToastEnum.SOMETHING_WENT_WRONG, _error.message);
                });
            disableResend();
        } else {
            Toast.error(ToastEnum.RESEND, ToastEnum.RESEND_DESC);
        }
    };

    const onSubmitForm = useCallback(
        async (values: ResetPassword): Promise<void> => {
            if (cognitoAuth === CognitoAuth.ALLOWED) {
                dispatch(
                    resetPassword({
                        username: locationState?.email,
                        code: values.otp,
                        password: values.password,
                    })
                )
                    .unwrap()
                    .then(() => {
                        /**
                         * we have added this message for testing purpose, will remove it in upcoming PR
                         */
                        Toast.success(
                            ToastEnum.VERIFIED,
                            ToastEnum.PASSWORD_RESET
                        );
                        navigate(routeObj.SIGN_UP_SUCCESS, {
                            state: {
                                from: Params.RESET_PASSWORD,
                            },
                            replace: true
                        });
                    })
                    .catch((_error) => {
                        Toast.error(
                            ToastEnum.SOMETHING_WENT_WRONG,
                            _error.message
                        );
                    });
            } else {
                const res = await resetUserPassword({
                    username: locationState?.email,
                    password: values.password,
                });
                if ('data' in res) {
                    Toast.success(ToastEnum.VERIFIED, res.data.message);
                    navigate(routeObj.SIGN_UP_SUCCESS, {
                        state: {
                            from: Params.RESET_PASSWORD,
                        },
                        replace: true
                    });
                } else {
                    Toast.error(ToastEnum.SOMETHING_WENT_WRONG, '');
                }
            }
        },
        []
    );

    const handleShow = () => {
        setShow(!show);
    };

    const handleConfirm = () => {
        handleShow();
        navigate(routeObj.DEFAULT);
    };

    return {
        register,
        handleSubmit,
        onSubmitForm,
        setValue,
        trigger,
        watch,
        errors,
        isValid,
        locationState,
        isDataLoading,
        isLoading,
        canResend,
        resendCode,
        handleConfirm,
        show,
        handleShow,
    };
}
