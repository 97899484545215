import { Button } from 'react-bootstrap';
import {
    CLOSEMENU,
    CATEGORY_PLACEHOLDER,
} from '../../../shared/constants/image.constant';
import useAdminLevel3Categories from '../hooks/useAdminLevel3Categories';
import Loader from '../../../shared/elements/Loader';
import BackWithText from '../../../shared/elements/BackWithText';
import { routeObj } from '../../../shared/constants/routes.constants';
import NoDataFound from '../../../shared/elements/NoDataFound';
import {
    ButtonLabel,
    Heading,
    NoDataEnums,
    ToastEnum,
} from '../../../shared/constants/html.constant';
import CreateCategoryLevelThree from './CreateCategoryLevelThree';
import CreateCategorySubLevelThree from './CreateCategorySubLevelThree';
import { ConfirmationPopup } from '../../../shared/elements/ConfirmationPopup';
import { useEffect } from 'react';
import { ErrorObj } from '../../../shared/types/type';
import Toast from '../../../shared/utils/toast.helper';

const SubCategories = () => {
    const {
        level3Category,
        isLoading,
        params,
        show,
        handleShow,
        showSub,
        handleShowSub,
        onClickAdd,
        selectedHeading,
        showDelete,
        handleDelete,
        confirmDelete,
        isDeletingLevel3,
        deleteHandler,
        deleteErrorLevel3,
        deleteResLevel3,
        refetch,
        selectedLevel3,
    } = useAdminLevel3Categories();

    useEffect(() => {
        if (deleteResLevel3?.message) {
            refetch();
            handleDelete();
            Toast.success(ToastEnum.CATEGORY_DELETED, deleteResLevel3?.message);
        } else if (deleteErrorLevel3) {
            handleDelete();
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (deleteErrorLevel3 as ErrorObj)?.message
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteResLevel3?.message, deleteErrorLevel3]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <ConfirmationPopup
                visible={showDelete}
                handleClose={handleDelete}
                onConfirm={confirmDelete}
                isLoading={isDeletingLevel3}
                loadingLabel={ButtonLabel.DELETING}
                CloseBtnText={Heading.CANCEL}
                ConfirmBtnText={Heading.DELETE}
                heading={Heading.DELETE_CATEGORY}
                subHeading={Heading.DELETE_CATEGORY_SUBTEXT}
            />
            <CreateCategoryLevelThree show={show} handleShow={handleShow} />
            <CreateCategorySubLevelThree
                show={showSub}
                handleShow={handleShowSub}
                selectedHeading={selectedHeading}
                level3={selectedLevel3}
            />
            <div className="admin-full-page">
                <div className="header-with-btn">
                    <div className="left-data">
                        <BackWithText
                            to={routeObj.ADMIN_BUSINESSES_CATEGORIES}
                            label={params.name}
                            containerClass="back-btn-large"
                            linkClass="back-btn-large"
                            state={{
                                level_one: params?.level_one,
                                active_tab: params?.active_tab,
                            }}
                        />
                        <p>
                            Create Level 3 groups and sub-groups within the
                            existing framework.
                        </p>
                    </div>
                    <Button
                        variant="primary"
                        className="medium"
                        onClick={handleShow}
                    >
                        Create Group
                    </Button>
                </div>
                {level3Category?.data?.length ? (
                    level3Category?.data.map((category, index) => (
                        <div className="list-group" key={category.id}>
                            <div className="list-header">
                                <span className="list-title">
                                    {category.heading}
                                </span>
                                <div className="btn-container">
                                    <Button
                                        variant="link"
                                        onClick={() =>
                                            onClickAdd(category, null)
                                        }
                                    >
                                        Add New
                                    </Button>
                                    <Button
                                        variant="link text-danger"
                                        onClick={() =>
                                            deleteHandler(category.id, true)
                                        }
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                            <div className="list-container">
                                {category.category_level3.map((level3) => (
                                    <div
                                        className="avatar-chips"
                                        key={level3.id}
                                    >
                                        <img
                                            src={
                                                level3.icon ||
                                                CATEGORY_PLACEHOLDER
                                            }
                                            alt="User profile"
                                            className="user-avatar"
                                            onClick={() =>
                                                onClickAdd(category, level3)
                                            }
                                        />
                                        <div className="chip-title">
                                            {level3.name}
                                        </div>
                                        <div
                                            className="avatar-delete"
                                            onClick={() =>
                                                deleteHandler(level3.id, false)
                                            }
                                        >
                                            <img
                                                src={CLOSEMENU}
                                                alt="Delete"
                                                className="delete-avatar"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                ) : (
                    <NoDataFound heading={NoDataEnums.NO_SUBCATEGORY_FOUND} />
                )}
            </div>
        </>
    );
};

export default SubCategories;
