import { toast } from 'react-toastify';
import { ALERTDANGER, BELLICON, ICONRIGHT } from '../constants/image.constant';

export default class Toast {
    static success(heading: string, message: string) {
        return toast(
            <div className='toast-inner success'>
                <img src={ICONRIGHT} alt="Right" />
                <div className="content">
                    <h2>{heading}</h2>
                    <p>{message}</p>
                </div>
            </div>
        );
    }
    static error(heading: string, message: string) {
        return toast(
            <div className='toast-inner danger'>
                <img src={ALERTDANGER} alt="Danger" />
                <div className="content">
                    <h2>{heading}</h2>
                    <p>{message}</p>
                </div>
            </div>
        );
    }
    static info(heading: string, message: string) {
        return toast(
            <div className='toast-inner info'>
                <img src={BELLICON} alt="Info" />
                <div className="content">
                    <h2>{heading}</h2>
                    <p>{message}</p>
                </div>
            </div>
        );
    }
}
