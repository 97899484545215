import { useForm } from 'react-hook-form';
import { getSignupIntialValues, signupFormSchema } from '../utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { signUpUser } from '../slices/slice.auth';
import { AddressResponse, SignUp, SignupField } from '../utils/slice.types';
import {
    useGetAddressByLatLngMutation,
    useGetBusinessByPhoneMutation,
} from '../slices/slice.authApi';
import {
    Bool,
    ConfirmPopup,
    CountryCode,
    GooglePlaceTypes,
    UserRole,
} from '../../../shared/constants/application.constant';
import Toast from '../../../shared/utils/toast.helper';
import { ToastEnum } from '../../../shared/constants/html.constant';
import { getRegionData } from '../../../shared/utils/helper';
import { ConstNumber } from '../../../shared/constants/number.constant';
import { ErrorObj } from '../../../shared/types/type';

export function useSignup() {
    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
        setValue,
        getValues,
        trigger,
        control,
        watch,
    } = useForm({
        defaultValues: getSignupIntialValues(),
        resolver: yupResolver(signupFormSchema),
    });
    const dispatch = useAppDispatch();
    const { username, showOTP, isDataLoading } = useAppSelector(
        (state) => state.auth
    );
    const [getBusiness, { data }] = useGetBusinessByPhoneMutation();
    const [getRegionAddress] = useGetAddressByLatLngMutation();

    const [isBusinessSelected, setBusinessSelected] = useState(false);
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [isValueSetFromBusiness, setValueFromBusiness] = useState(false);
    const [prevPhone, setPrevPhone] = useState('');
    const businessInfo = data?.data.candidates;

    const onSubmitForm = useCallback(async (values: SignUp): Promise<void> => {
        const place = values.place;
        const { lat, lng } = place.geometry.location;
        const { city, zip_code, state } = getRegionData(
            place.address_components
        );
        const userObj = {
            username: values.email,
            password: values.password,
            attributes: {
                address: values.address,
                name: values.name.trim(),
                'custom:role': UserRole.BUSINESS,
                'custom:business_phonenumber':
                    `${CountryCode.US}${values.phone_number}`.replaceAll(
                        '-',
                        ''
                    ), // made sure to remove hyphen if there is any
                'custom:referral_code': values.referral_code,
                'custom:latitude': lat().toString(),
                'custom:longitude': lng().toString(),
                'custom:place_id': place.place_id,
                'custom:city': city,
                'custom:state': state,
                'custom:zip_code': zip_code,
                'custom:newsletter_enabled': `${values.newsletter_enabled}`,
                'custom:is_profile_completed': Bool.false,
                'custom:is_onboarded': Bool.false,
                'custom:is_active': Bool.true,
                'custom:is_subscribed': Bool.false,
                'custom:is_first_time': Bool.false,
            },
        };
        dispatch(signUpUser(userObj))
            .unwrap()
            .then()
            .catch((_error) => {
                Toast.error(ToastEnum.SOMETHING_WENT_WRONG, _error.message);
            });
    }, []);

    const handleOnBlurMobile = async (phone: string) => {
        trigger('phone_number').then(async () => {
            if (!Boolean(errors.phone_number?.message) && prevPhone !== phone) {
                const res = await getBusiness({
                    country_code: CountryCode.US,
                    contact_number: phone,
                });
                setShowSuggestion(true);
                setPrevPhone(phone);
            }
        });
        if (prevPhone !== phone) {
            if (isValueSetFromBusiness) {
                setValue('name', '');
                setValue('address', '');
                setValueFromBusiness(false);
            }
            setBusinessSelected(false);
        }
    };

    /**
     * @description this method is used to create the place object from two different apis, 'place' object is used in submit handler
     * @param placeId
     * @param lat
     * @param lng
     * @param addressData
     * @returns
     */
    const setPlaceData = (
        placeId: string,
        lat: string,
        lng: string,
        addressData: AddressResponse
    ) => {
        return {
            place_id: placeId,
            geometry: {
                location: {
                    lat() {
                        return lat;
                    },
                    lng() {
                        return lng;
                    },
                },
            },
            address_components: [
                {
                    long_name: addressData.state,
                    short_name: '',
                    types: [GooglePlaceTypes.STATE],
                },
                {
                    long_name: addressData.postcode,
                    short_name: '',
                    types: [GooglePlaceTypes.POSTAL_CODE],
                },
                {
                    long_name: addressData.city,
                    short_name: '',
                    types: [GooglePlaceTypes.CITY],
                },
            ],
        };
    };

    const businessHandler = async (value: string) => {
        if (businessInfo && ConfirmPopup.YES === value) {
            const lat = businessInfo[0].geometry.location.lat.toFixed(
                    ConstNumber.VALUE_6
                ),
                lng = businessInfo[0].geometry.location.lng.toFixed(
                    ConstNumber.VALUE_6
                );

            const res = await getRegionAddress({
                lat,
                lng,
                place_id: businessInfo[0].place_id,
            });
            if ('data' in res) {
                const placeObj = setPlaceData(
                    businessInfo[0].place_id,
                    lat,
                    lng,
                    res.data
                );
                setValue('place', placeObj);
                setValue('name', businessInfo[0].name);
                setValue('address', businessInfo[0].formatted_address);
                setBusinessSelected(true);
                setValueFromBusiness(true);
                trigger(['name', 'address']); // trigger added to make sure to run the validation
            }
            if ('error' in res) {
                Toast.error(
                    ToastEnum.SOMETHING_WENT_WRONG,
                    (res.error as ErrorObj)?.message
                );
            }
        }
        setShowSuggestion(false);
    };

    const onBlurValidate = (name: SignupField) => {
        trigger(name);
    };

    return {
        dispatch,
        register,
        handleSubmit,
        onSubmitForm,
        setValue,
        trigger,
        watch,
        errors,
        isValid,
        getValues,
        showOTP,
        username,
        handleOnBlurMobile,
        businessInfo,
        isBusinessSelected,
        setBusinessSelected,
        control,
        showSuggestion,
        setShowSuggestion,
        isDataLoading,
        businessHandler,
        onBlurValidate,
    };
}
