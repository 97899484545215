import React, { useEffect, useState } from 'react';
import { Tick } from '../../../assets/icons/Tick';
import {
    LOWERCASE_REGEX,
    NUMERIC_REGEX,
    SPECIALCHAR_REGEX,
    UPPERCASE_REGEX,
} from '../constants/regex.constant';
import { PasswordChecks } from '../constants/data.constant';
import { ConstNumber } from '../constants/number.constant';

type Props = {
    password?: string;
};

const PasswordHint = ({ password = '' }: Props) => {
    const [validationObj, setValidationObj] = useState(PasswordChecks);

    useEffect(() => {
        validatePassword();
    }, [password]);

    const validatePassword = () => {
        const updatedObj = [
            {
                ...validationObj[ConstNumber.VALUE_0],
                isValid: password.length >= ConstNumber.VALUE_8,
            },
            {
                ...validationObj[ConstNumber.VALUE_1],
                isValid: LOWERCASE_REGEX.test(password),
            },
            {
                ...validationObj[ConstNumber.VALUE_2],
                isValid: UPPERCASE_REGEX.test(password),
            },
            {
                ...validationObj[ConstNumber.VALUE_3],
                isValid: SPECIALCHAR_REGEX.test(password),
            },
            {
                ...validationObj[ConstNumber.VALUE_4],
                isValid: NUMERIC_REGEX.test(password),
            },
        ];
        setValidationObj(updatedObj);
    };

    return (
        <div className="password-hint more-margin">
            {validationObj.map((value, idx) => {
                return (
                    <div
                        key={idx}
                        className={`${value.isValid ? 'box active' : 'box'}`}
                    >
                        <Tick stroke={value.isValid ? '#4DB464' : undefined} />
                        {value.label}
                    </div>
                );
            })}
        </div>
    );
};

export default PasswordHint;
