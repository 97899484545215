import React from 'react';
import { getMonth } from '../utils/utils';
import { PromotionRequest } from '../../../adminModules/promotion/utils/types.promotion';
import { PromoCodeType } from '../../../shared/constants/application.constant';

type Props = {
    trialMonths?: number | null;
    promotionPlanType?: PromoCodeType;
};

const Note = (props: Props) => {
    let text = '';
    switch (props?.promotionPlanType) {
        case PromoCodeType.LIFETIME_FREE:
            text = `Note: You now have free access to the Socialyfe application.`;
            break;
        case PromoCodeType.TRAIL:
            text = `Note: Once the ${getMonth(
                props.trialMonths
            )} trial period ends, the standard subscription fee will be applied.`;

            break;
        case PromoCodeType.DISCOUNT:
            text = `Note: Your first subscription purchase has been discounted. The discount is only applicable for your first subscription purchase, and once it ends, the standard subscription fee will be applied. `;
            break;
        case PromoCodeType.DURATION:
            text = `Note: Once the ${getMonth(
                props.trialMonths
            )} trial period ends, you will need to subscribe to the platform to continue using it.`;
            break;
        case PromoCodeType.DISCOUNT_ONBOARD:
            text = `Note: You got a discount for ${getMonth(
                props.trialMonths
            )} offer, The normal amount will be charged from the following month.`;
            break;
        case PromoCodeType.TRAIL_ONBOARD:
            text = `Note: You got a discount for ${getMonth(
                props.trialMonths
            )} trial offer, The normal amount will be charged from the following month.`;
            break;
        default:
            break;
    }
    return <div className="note-container">{text}</div>;
};

export default Note;
