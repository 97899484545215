import { Link } from 'react-router-dom';
import { BACK } from '../constants/image.constant';

interface IBackWithTextProps {
    label: string;
    to: string;
    state?: any;
    containerClass?: string;
    linkClass?: string;
}

const BackWithText = (props: IBackWithTextProps) => {
    const {
        label,
        to,
        state,
        containerClass = 'back-with-text',
        linkClass = 'back-btn',
    } = props;
    return (
        <div className={containerClass}>
            <Link to={to} state={state} title="Back" className={linkClass}>
                <img src={BACK} alt="back" />
            </Link>
            <span>{label}</span>
        </div>
    );
};

export default BackWithText;
