import { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { ButtonLabel } from '../../../shared/constants/html.constant';
import { DATEICON } from '../../../shared/constants/image.constant';
import CustomInput, {
    CustomDatePicker,
} from '../../../shared/elements/CustomInput';
import SingleSelect from '../../../shared/elements/SingleSelect';
import UploadMedia from '../../../shared/elements/UploadMedia';
import { FormParams } from '../../../shared/utils/types.elements';
import useCreateAdvertisement from '../hooks/useCreateAdvertisement';
import { EventActionType } from '../../../shared/constants/application.constant';
import { getDuplicateText } from '../../../shared/utils/utils';
import ApplyPromoCode from '../../../shared/elements/ApplyPromoCode';
import AuthorizeNetPayment from '../../../shared/elements/AuthorizeNetPayment';
import { ternaryLogic } from '../../../shared/utils/orLogic';

interface Props {
    params: FormParams;
}
const CreateAdvertisementForm = (props: Props) => {
    const {
        handleSubmit,
        onSubmitForm,
        setValue,
        register,
        watch,
        errors,
        isValid,
        getValues,
        handleDateChange,
        setShow,
        show,
        ageTargets,
        adPlacements,
        adViewsPack,
        handleChange,
        advertisementDataById,
        setInitialValues,
        selectedTarget,
        selectedPlacement,
        selectedViewsPack,
        id,
        type,
        duplicateCSS,
        isSubmitting,
        couponHandler,
        isPromoApplied,
        promoData,
        promoLoading,
        resetPromo,
        getTotalAmount,
        handlePaySubmit,
        submitBtnRef,
        GetSubmitBtn,
    } = useCreateAdvertisement(props.params);

    useEffect(() => {
        if (advertisementDataById) {
            setInitialValues(advertisementDataById);
        }
    }, [advertisementDataById]);

    return (
        <Form
            className="modal-form demo-form"
            onSubmit={handleSubmit(onSubmitForm)}
        >
            <Row>
                <Col md={12}>
                    <CustomInput
                        requiredText={getDuplicateText(duplicateCSS)}
                        duplicateTextClass={ternaryLogic(
                            duplicateCSS,
                            'text-danger',
                            ''
                        )}
                        label="Ad Name"
                        type="text"
                        name={'name'}
                        register={register}
                        errorMsg={errors.name?.message}
                        placeholder="Ad Name"
                    />
                </Col>
                <Col md={6}>
                    <Form.Group className="form-group" controlId="age_target">
                        <SingleSelect
                            options={ageTargets}
                            label="Age Target"
                            isSearchable={false}
                            isClearable={false}
                            changeHandler={(v) => handleChange(v, 'age_target')}
                            errorMsg={errors.age_target?.message}
                            placeholder="Select"
                            value={selectedTarget}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="form-group" controlId="placement">
                        <SingleSelect
                            options={adPlacements}
                            label="Ad Placement"
                            isSearchable={false}
                            isClearable={false}
                            changeHandler={(v) => handleChange(v, 'placement')}
                            errorMsg={errors.placement?.message}
                            placeholder="Select"
                            value={selectedPlacement}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="form-group" controlId="views">
                        <SingleSelect
                            options={adViewsPack}
                            label="Ad Views Pack"
                            isSearchable={false}
                            isClearable={false}
                            changeHandler={(v) => handleChange(v, 'views')}
                            errorMsg={errors.views?.message}
                            placeholder="Select"
                            value={selectedViewsPack}
                            isDisabled={Boolean(type === EventActionType.EDIT)}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="form-group" controlId="">
                        <Form.Label>Starts on*</Form.Label>
                        <CustomDatePicker
                            name="start_datetime"
                            register={register}
                            icon={DATEICON}
                            watch={watch}
                            disablePastDate
                            handleDateChange={handleDateChange}
                            errorMsg={errors.start_datetime?.message}
                            isDisabled={Boolean(type === EventActionType.EDIT)}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Col md={12}>
                        <CustomInput
                            label="Plan Price"
                            type="text"
                            name={'plan_price'}
                            register={register}
                            readOnly={true}
                            disabled
                            placeholder="Plan Price"
                        />
                    </Col>
                    <Col md={12}>
                        <UploadMedia
                            show={show}
                            setShow={setShow}
                            setValue={setValue}
                            name="document"
                            document={getValues('document' as any)}
                            thumbnailUrl={
                                advertisementDataById?.thumbnail_url || ''
                            }
                            isOptional
                            isDisabled={Boolean(type === EventActionType.EDIT)}
                        />
                    </Col>
                </Col>
            </Row>
            {/** Hide in case of update ad */}
            {type !== EventActionType.EDIT && (
                <Row>
                    <Col md={6}>
                        <div className="promocode-container adv-form-promo">
                            <ApplyPromoCode
                                applyHandler={couponHandler}
                                label="Promotion Code"
                                isLoading={promoLoading}
                                isSuccess={isPromoApplied}
                                resetHandler={resetPromo}
                                successMessage={promoData?.message}
                            />
                        </div>
                    </Col>
                </Row>
            )}
            <div className="button-footer">
                {(!promoData?.card_required && promoData?.promo_id) ||
                type === EventActionType.EDIT ? (
                    <GetSubmitBtn />
                ) : (
                    <>
                        <Button
                            ref={submitBtnRef}
                            type="submit"
                            className="invisible"
                        />
                        <AuthorizeNetPayment
                            label={
                                isSubmitting
                                    ? ButtonLabel.CREATING
                                    : `Pay $${getTotalAmount()} & Publish`
                            }
                            buttonClass="btn btn-primary"
                            isDisabled={
                                !isValid ||
                                !getValues('document' as any) ||
                                isSubmitting
                            }
                            handleSubmit={handlePaySubmit}
                        />
                    </>
                )}
            </div>
        </Form>
    );
};

export default CreateAdvertisementForm;
