export const SAFARI_DATE_REGEX = /-/g;
export const LOWERCASE_REGEX = /[a-z]/;
export const UPPERCASE_REGEX = /[A-Z]/;
export const SPECIALCHAR_REGEX = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
export const NUMERIC_REGEX = /[0-9]/;
export const PASSWORD_REGEX =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
export const NUMBER_REGEX = /^\d+$/;
export const US_NUMBER_FORMAT = /(\d{3})(\d{3})(\d{4})/;
export const FIND_ANYTHING_OTHER_THAN_NUMBER = /[^\d]+/g;
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const FIND_IMAGE = /\.(jpg|jpeg|png|webp)$/gi;
export const FIND_VIDEO = /\.mp4/gi;
export const CONVERT_ESCAPE_NEWLINE = /\r?\n/g;
export const CONVERT_ESCAPE_SPACE = /\r?\n/g;
export const REMOVE_SPECIAL = /[^a-zA-Z0-9]/g;
export const FEATURE_STRING = /\n/g;
export const PRICE =
    /^(?!0{8,}\.\d{1,2}$)^(0\.\d{1,2}|[1-9]\d{0,7}(\.\d{1,2})?)$/;
export const POSITIVE_WHOLE_NUMBER = /^[1-9]\d*$/;
export const PHONE_REGEXP = /^\d{10}$|^\d{3}-\d{3}-\d{4}$/; // xxx-xxx-xxxx or xxxxxxxxxx
