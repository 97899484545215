import moment from 'moment';
import {
    CognitoAuth,
    CommonEnum,
    CountryCode,
    DateFormats,
    GooglePlaceTypes,
    UserRole,
    ValidationEnums,
    localStorageKeys,
} from '../constants/application.constant';
import {
    AWARD,
    BAR,
    COLOR_FILTER,
    DUMMYIMAGE,
    DUMMYVIDEO,
    GRID,
    MONITOR,
    TV,
    CALENDARTICK,
    CALENDARSEARCH,
    TICKET,
    ADMINNOTIFICATION,
    USERSQUARE,
    CROWN,
    WAREHOUSE,
} from '../constants/image.constant';
import { ConstNumber } from '../constants/number.constant';
import { getStorageData } from './storage.helpers';
import {
    MenuLink,
    Option,
    Provider,
    StorageDataResponse,
} from './types.elements';
import { Crop } from 'react-image-crop';
import {
    FEATURE_STRING,
    FIND_ANYTHING_OTHER_THAN_NUMBER,
    FIND_IMAGE,
    FIND_VIDEO,
    REMOVE_SPECIAL,
    US_NUMBER_FORMAT,
} from '../constants/regex.constant';
import { routeObj } from '../constants/routes.constants';
import { ButtonLabel, SidebarLinks } from '../constants/html.constant';
import {
    BusinessDetais,
    BusinessLocation,
} from '../../modules/businessInfo/utils/types.businessInfo';
import { NO_ADDRESS } from '../constants/data.constant';

export const isUserAuthorized = () => {
    const { role, access_token } = getStorageData() as StorageDataResponse;

    if (
        (role === UserRole.SUPER_ADMIN || role === UserRole.SUB_ADMIN) &&
        access_token
    ) {
        // Naivgate to admin dashbaord
        return true;
    }
    if (role === UserRole.BUSINESS && access_token) {
        // navigate to business dashboard
        return true;
    }
    return false;
};

export const showZero = (index: number) => {
    return index < ConstNumber.VALUE_9
        ? `0${index + ConstNumber.VALUE_1}`
        : index + ConstNumber.VALUE_1;
};

// Function to extract date from UTC datetime string and format it
export const formatDateTime = (datetime: string, format: string) => {
    const localDate = moment.utc(datetime).local();
    return localDate.format(format);
};

// Function to extract date from UTC datetime string and format it
export const formatDateTimeValue = (datetime: string, format: string) => {
    const localDate = moment.utc(datetime);
    return localDate.format(format);
};

export const getFromToDate = (
    startDatetime: string,
    endDateTime: string,
    dataLabel = true
) => {
    return `${dataLabel ? 'Date - ' : ''}${formatDateTime(
        startDatetime,
        DateFormats.DATE
    )} to ${formatDateTime(endDateTime, DateFormats.DATE)}`;
};

export const getFromToDateValue = (
    startDatetime: string,
    endDateTime: string,
    dataLabel = true
) => {
    return `${dataLabel ? 'Date - ' : ''}${formatDateTimeValue(
        startDatetime,
        DateFormats.DATE
    )} to ${formatDateTimeValue(endDateTime, DateFormats.DATE)}`;
};

/**
 *
 * @param startDatetime date in string
 * @param endDateTime date in string
 * @returns formatted time e.g. Time - 09:22 PM to 09:37 PM
 */
export const getFromToTime = (startDatetime: string, endDateTime: string) => {
    return `Time - ${formatDateTime(
        startDatetime,
        DateFormats.TIME
    )} to ${formatDateTime(endDateTime, DateFormats.TIME)}`;
};

export const getFromToTimeValue = (
    startDatetime: string,
    endDateTime: string
) => {
    return `Time - ${formatDateTimeValue(
        startDatetime,
        DateFormats.TIME
    )} to ${formatDateTimeValue(endDateTime, DateFormats.TIME)}`;
};

/**
 *
 * @param timeString e.g. "18:00:00"
 * @returns e.g "06:00 PM"
 */
export const getTime = (timeString: string) => {
    return moment(timeString, DateFormats.TIME_SECOND).format(DateFormats.TIME);
};

export const convertISTToUTC = (date: string, format: string) => {
    const d = moment(date, `${DateFormats.YYYY_MM_DD}, ${DateFormats.HH_MM}`);
    return moment.utc(d).format(format).valueOf();
};

/**
 *
 * @param date Javascript date object
 * @param time Javascript date object
 * @returns date with time in utc format e.g `2023-07-16 13:00`
 */

export const formatDateAndTime = (date: Date | null, time: Date | null) => {
    const onlyDate = moment(date).format(DateFormats.YYYY_MM_DD);
    const onlyTime = moment(time).format(DateFormats.HH_MM);
    return convertISTToUTC(
        `${onlyDate}, ${onlyTime}`,
        `${DateFormats.YYYY_MM_DD} ${DateFormats.HH_MM}`
    );
};

/**
 *
 * @param date Javascript date object
 * @param time Javascript date object
 * @returns date with time in format e.g `2023-07-16 13:00`
 */

export const formatChangeDateTime = (date: Date | null, time: Date | null) => {
    const onlyDate = moment(date).format(DateFormats.YYYY_MM_DD);
    const onlyTime = moment(time).format(DateFormats.HH_MM);
    return `${onlyDate} ${onlyTime}`;
};

export const formatUTCDateTime = (date: Date | null, time: Date | null) => {
    const onlyDate = moment.utc(date).format(DateFormats.YYYY_MM_DD);
    const onlyTime = moment.utc(time).format(DateFormats.HH_MM);
    return `${onlyDate} ${onlyTime}`;
};

/**
 * This fuction is used to stop enter event.
 * @param e
 */
export const checkKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
        e.preventDefault();
    }
};

export const validateType = (
    type: any,
    allowedTypes: any,
    allowedTypeMsg: any
) => {
    const result = { value: '', valid: false };
    if (allowedTypes.length >= 1 && typeof type === 'string') {
        result.valid = allowedTypes.some((value: any) =>
            type.toLowerCase().includes(value.toLowerCase())
        );
    }
    result.value = result.valid ? '' : allowedTypeMsg;
    return result;
};

const allowedSizeCalculation = (fileAllowedSize: any) => {
    return fileAllowedSize / ConstNumber.VALUE_1000000;
};

export const validateSize = (
    size: number,
    allowSize: number,
    allowedSizeMessage?: string
) => {
    const result = { value: '', valid: false };
    if (size <= allowSize) {
        result.value = '';
        result.valid = true;
    } else {
        result.value = `${
            allowedSizeMessage || ValidationEnums.INVALID_FILE_SIZE
        } ${allowedSizeCalculation(allowSize)} MB.`;
        result.valid = false;
    }
    return result;
};

export const validateVideoDuration = (
    duration: number,
    allowDuration: number,
    allowDurationMessage?: string
) => {
    const result = { value: '', valid: false };
    if (duration <= allowDuration) {
        result.value = '';
        result.valid = true;
    } else {
        result.value = `${
            allowDurationMessage || ValidationEnums.INVALID_VIDEO_DURATION
        } ${allowDuration} seconds.`;
        result.valid = false;
    }
    return result;
};

export const mapFormDataFields = (config: {
    [key: string]: string | File | number[];
}) => {
    const newFormData = new FormData();
    Object.entries(config).forEach(([fieldName, fieldValue]) => {
        if (fieldValue && !Array.isArray(fieldValue)) {
            newFormData.append(fieldName, fieldValue);
        } else if (Array.isArray(fieldValue)) {
            fieldValue.forEach((item) => {
                newFormData.append(fieldName, `${item}`);
            });
        } else if (
            (fieldName === 'document' ||
                fieldName === 'profile_video' ||
                fieldName === 'ticket_url') &&
            fieldValue === null
        ) {
            newFormData.append(fieldName, '');
        }
    });
    return newFormData;
};

/**
 * this is used to check video or image for now to show placeholder.
 * @param document
 * @returns
 */
export const getImage = (document: string | null) => {
    if (document?.includes('.mp4') || document?.includes('.mov')) {
        return DUMMYVIDEO;
    } else if (document) {
        return document;
    } else {
        return DUMMYIMAGE;
    }
};

export const handleLoadedMetadata = (
    videoRef: React.RefObject<HTMLVideoElement>,
    video: File,
    checkValidation: (value: Provider) => void,
    setVideo: (video: File | null) => void
) => {
    const videoEle = videoRef.current;
    if (!videoEle || !video) {
        return;
    }

    const size = validateSize(
        video.size,
        ConstNumber.MB_100,
        ValidationEnums.INVALID_VIDEO_SIZE
    );
    const duration = validateVideoDuration(
        videoEle.duration,
        ConstNumber.VALUE_20
    );
    const type = validateType(
        video.type,
        ['mp4', 'quicktime'], // quicktime is a mimetype of .mov file
        ValidationEnums.INVALID_VIDEO_FORMAT
    );

    if (!size.valid || !duration.valid || !type.valid) {
        checkValidation({
            valid: false,
            value: [size.value, duration.value, type.value],
        });
        videoEle.src = '';
        setVideo(null);
    }
};

export const getCroppedImage = (
    _crop: Crop,
    imageFile: HTMLImageElement | null,
    setCroppedImage: (file: File | null) => void,
    setVideo: (video: File | null) => void,
    _imageFile?: HTMLImageElement
) => {
    const canvas = document.createElement('canvas');
    const uploadedImage = _imageFile || imageFile;
    if (!uploadedImage) {
        return;
    }
    const scaleX = uploadedImage.naturalWidth / uploadedImage.width;
    const scaleY = uploadedImage.naturalHeight / uploadedImage.height;
    canvas.width = _crop.width;
    canvas.height = _crop.height;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(
        uploadedImage,
        _crop.x * scaleX,
        _crop.y * scaleY,
        _crop.width * scaleX,
        _crop.height * scaleY,
        ConstNumber.VALUE_0,
        ConstNumber.VALUE_0,
        _crop.width,
        _crop.height
    );
    canvas.toBlob((blob) => {
        if (blob) {
            const croppedFile = new File(
                [blob],
                `cropped${new Date().getTime()}.jpg`,
                {
                    type: 'image/jpeg',
                    lastModified: Date.now(),
                }
            );
            setCroppedImage(croppedFile);
            setVideo(null);
        }
    }, 'image/jpeg');
};

/**
 *
 * @param fileName - file that needs to be renamed
 * @returns file name replaced with special char into spaces
 */
export function replaceSpecialCharIntoUnderscore(fileName: string) {
    const parts = fileName.split('.');
    if (parts.length > ConstNumber.VALUE_1) {
        const extension = parts.pop();
        const name = parts.join('.');
        const updatedName = name.replace(REMOVE_SPECIAL, '_');
        return `${updatedName}.${extension}`;
    } else {
        return fileName.replace(REMOVE_SPECIAL, '_');
    }
}

//this function formats the mobile numbers in the US format for international dialing
export const formatMobileNumber = (num?: string) => {
    if (num === '' || num?.length == null) {
        return '';
    }
    return num
        .replace(FIND_ANYTHING_OTHER_THAN_NUMBER, '') //ensures that only numbers are in the string and remove anything else
        .replace(US_NUMBER_FORMAT, '$1-$2-$3') //formats the mobile number in format => 123-456-7890
        .trim(); //removes any trailing spaces
};

//this function formats the mobile numbers in the US format for international dialing
export const formatPhoneWithCountryCode = (num?: string) => {
    return num
        ? `${CountryCode.US} ${formatMobileNumber(
              num?.slice(ConstNumber.VALUE_2, ConstNumber.VALUE_12)
          )}`
        : '-';
};

export const getRegionData = (
    address: Array<{
        long_name: string;
        short_name: string;
        types: Array<string>;
    }>
) => {
    let zipCode = '',
        state = '',
        city = '';
    for (const component of address) {
        if (component.types.includes(GooglePlaceTypes.POSTAL_CODE)) {
            zipCode = component.long_name;
        }
        if (component.types.includes(GooglePlaceTypes.STATE)) {
            state = component.long_name;
        }
        if (component.types.includes(GooglePlaceTypes.CITY)) {
            city = component.long_name;
        }
    }
    return {
        zip_code: zipCode,
        state,
        city,
    };
};

/**
 * @description this function will return sidebar links based on admin flag
 * @param handleToggle
 * @param isAdmin
 * @returns Menu Links
 */
export const getMenuLinks = (handleToggle: () => void, isAdmin = false) => {
    const businessSidebarLinks = [
        {
            to: routeObj.BUSINESS_DASHBOARD,
            icon: MONITOR,
            title: SidebarLinks.DASHBOARD,
            handleToggle,
        },
        {
            isAccordian: true,
            icon: BAR,
            defaultActive: false,
            title: SidebarLinks.BUSINESS_INFO,
            accordianChild: [
                {
                    to: routeObj.BUSINESS_VENUE_LOCATIONS,
                    title: SidebarLinks.VENUE_LOCATION,
                    handleToggle,
                    dot: true,
                },
                {
                    to: routeObj.BUSINESS_VENUE_DEMOGRAPHICS,
                    title: SidebarLinks.VENUE_DEMOGRAPHICS,
                    handleToggle,
                    dot: true,
                },
                {
                    to: routeObj.BUSINESS_VENUE_DETAILS,
                    title: SidebarLinks.VENUE_DETAILS,
                    handleToggle,
                    dot: true,
                },
            ],
        },
        {
            to: routeObj.BUSINESS_EVENTS,
            icon: GRID,
            title: SidebarLinks.EVENTS,
            handleToggle,
        },
        {
            to: routeObj.BUSINESS_SPECIALS,
            icon: AWARD,
            title: SidebarLinks.SPECIALS,
            handleToggle,
        },
        {
            to: routeObj.BUSINESS_ADVERTISING,
            icon: TV,
            title: SidebarLinks.ADVERTISING,
            handleToggle,
        },
    ];

    const adminLinks = [
        {
            to: routeObj.ADMIN_BUSINESSES,
            icon: BAR,
            title: SidebarLinks.BUSINESSES,
            handleToggle,
        },
        {
            to: routeObj.ADMIN_BUSINESSES_CATEGORIES,
            icon: COLOR_FILTER,
            title: SidebarLinks.CATEGORIES,
            handleToggle,
        },
        {
            to: routeObj.ADMIN_EVENT_CLAIM,
            icon: CALENDARTICK,
            title: SidebarLinks.EVENT_CLAIMS,
            handleToggle,
        },
        {
            to: routeObj.ADMIN_API_EVENTS,
            icon: CALENDARSEARCH,
            title: SidebarLinks.API_EVENTS,
            subtitle: SidebarLinks.API_EVENTS_SUBTEXT,
            handleToggle,
        },
        {
            to: routeObj.ADMIN_DATA_WAREHOUSE,
            icon: WAREHOUSE,
            title: SidebarLinks.DATA_WAREHOUSE,
            handleToggle,
        },
        {
            to: routeObj.ADMIN_SUBSCRIPTIONS,
            icon: CROWN,
            title: SidebarLinks.PAYMENT_PLANS,
            handleToggle,
        },
        {
            to: routeObj.ADMIN_PROMOTION_CODE,
            icon: TICKET,
            title: SidebarLinks.PROMOTION_CODE,
            handleToggle,
        },
        {
            to: routeObj.ADMIN_NOTIFICATION,
            icon: ADMINNOTIFICATION,
            title: SidebarLinks.NOTIFICATION,
            handleToggle,
        },
        {
            to: routeObj.ADMIN_USERS,
            icon: USERSQUARE,
            title: SidebarLinks.ADMIN_USERS,
            handleToggle,
        },
    ];

    return (isAdmin ? adminLinks : businessSidebarLinks) as MenuLink[];
};

/**
 * used to check the user is admin or not.
 * @param arg
 * @returns
 */
export const checkIsAdmin = (arg?: string) => {
    const role = arg || getStorageData(localStorageKeys.ROLE);
    return role === UserRole.SUPER_ADMIN || role === UserRole.SUB_ADMIN;
};

export const checkSuperAdmin = (arg?: string) => {
    const role = arg || getStorageData(localStorageKeys.ROLE);
    return role === UserRole.SUPER_ADMIN;
};

export function isImageUrl(url: string) {
    const isImage = url.match(FIND_IMAGE);
    return isImage ? true : false;
}

export function isVideoUrl(url: string) {
    const data = url.match(FIND_VIDEO);
    return data ? true : false;
}

export const debounce = (
    func: Function,
    timeoutId: React.MutableRefObject<NodeJS.Timeout | undefined>,
    delay: number
) => {
    return (...args: any[]) => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
        timeoutId.current = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export function isCurrentTimeBetween11_30And12() {
    const currentTime = moment(); // Get the current time
    const startTime = moment().set({
        hour: 23,
        minute: 30,
        second: 0,
        millisecond: 0,
    }); // 11:30 PM current day
    const endTime = moment()
        .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        })
        .add(1, 'day'); // 12:00 AM next day (midnight)
    return currentTime.isBetween(startTime, endTime);
}

export function getAdjustedStartDate() {
    const currentDate = moment();
    if (isCurrentTimeBetween11_30And12()) {
        return currentDate.add(1, 'day');
    } else {
        return currentDate;
    }
}

export function getTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function compareStartAndCurrentTime(value?: Date) {
    const startTime = moment(value);
    const currentTime = moment();
    return startTime.isSameOrAfter(currentTime);
}

export const getS3MediaUrl = (
    isUploaded: boolean,
    s3PresingedLink?: string
) => {
    return isUploaded ? s3PresingedLink?.split('?')[0] : '';
};

export function getFileExtension(file: File): string {
    const fileName = file.name;
    const lastIndex = fileName.lastIndexOf('.');
    if (lastIndex === -ConstNumber.VALUE_1) {
        return ''; // No extension found
    }
    return fileName.slice(lastIndex + 1).toLowerCase();
}
/**
 *
 * @param arr
 * @returns string of array
 * @description this method use to generate string for adding feture into admin subscription
 */
export function convertIntoString(arr: string[]) {
    const mergedString = `${arr.join(CommonEnum.SEPARATOR)}`;
    return mergedString.replace(FEATURE_STRING, '');
}

/**
 *
 * @param str
 * @returns it return the array
 * @description this is use to convert the feature string to array
 */
export function convertArrIntoString(str?: string) {
    return str?.split(CommonEnum.SEPARATOR);
}

export const isCognitoAuthAllowed = () => {
    const cognitoAuth = process.env.REACT_APP_COGNITO_AUTH;
    return !cognitoAuth?.includes(CognitoAuth.NOT);
};

export const getBusinessName = (business?: BusinessDetais) => {
    let address = ButtonLabel.LOADING as string;
    if (business?.business_name) {
        address = business.business_name;
    }
    if (business?.city) {
        address = `${address}, ${business.city}`;
    }
    return address;
};

/**
 *
 * @param items Options array
 * @param valueKey key that needs to be consider as a value
 * @param labelKey label that needs to be display in dropdown
 * @param slugKey optional
 * @returns it returns any type of items array into Options[] array
 */
export function convertToOptions<T>(
    items: T[],
    valueKey: keyof T,
    labelKey: keyof T,
    slugKey?: keyof T
): Option[] {
    return items.map((item) => ({
        value: item[valueKey] as number,
        label: item[labelKey] as string,
        slug: slugKey ? (item[slugKey] as string) : undefined,
    }));
}
/**
 * This function is used to download csv file.
 */
export const handleDownloadClick = () => {
    const csvUrl = process.env.REACT_APP_EVENT_CSV_URL || '';
    fetch(csvUrl)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'sample_for_import_events.csv';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch((error) => {});
};

export function generateBusinessLocationOptions(
    locations: BusinessLocation[] | undefined,
    isDashboard = false
) {
    return locations?.length
        ? convertToOptions(
              locations,
              isDashboard ? 'location_id' : 'id', // In dashboard we have to use location_id.
              'address'
          )
        : NO_ADDRESS;
}

export const getChangedValues = (initialData: any, _data: any) => {
    type UpdatedFields = { [key: string]: string };
    const changedValues: UpdatedFields = {};
    for (const key in _data) {
        const keyString = key; // as keyof CreateSpecial;
        if (_data[keyString] !== initialData[keyString]) {
            // this if will run for rest of the fields
            changedValues[keyString] = _data[keyString] as any;
        }
    }
    return changedValues;
};

export const getMachineId = () => {
    let machineId = localStorage.getItem('MachineId');

    if (!machineId) {
        machineId = crypto.randomUUID();
        localStorage.setItem('MachineId', machineId);
    }

    return machineId;
};
