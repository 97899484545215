import React from 'react';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ConstNumber } from '../constants/number.constant';
import { IEventCardProps } from '../utils/types.elements';
import EventActions from './EventActions';
import Media from './Media';
import { INFOICON } from '../constants/image.constant';

const EventCard = (props: IEventCardProps) => {
    const {
        document,
        title,
        date,
        time,
        isDuplicate,
        actionHandler,
        id,
        customClass = 'no-class',
        lg = ConstNumber.VALUE_3,
        thumbnail_url,
        xxl = ConstNumber.VALUE_3,
        isDelete,
        isEdit,
        disableDetail = true,
        claimed_requests,
        isClaimed,
        customTitleClass = '',
        customTitleText,
        isRefresh,
    } = props;
    const idForDom = `${id}`;
    return (
        <>
            <Col
                sm={ConstNumber.VALUE_6}
                md={ConstNumber.VALUE_4}
                lg={lg}
                xxl={xxl}
                className={`${customClass}`}
                id={idForDom}
                role="button"
            >
                <div className={`special-card ${customClass}`} id={idForDom}>
                    <div
                        className={
                            document ? 'card-image' : 'card-image shadow-none'
                        }
                        id={idForDom}
                    >
                        <Media
                            idForDom={idForDom}
                            disableDetail={disableDetail}
                            url={document}
                            thumbnail_url={thumbnail_url}
                        />
                    </div>
                    {actionHandler && id && (
                        <div className="more-dropdown">
                            <EventActions
                                actionHandler={actionHandler}
                                id={id}
                                isDuplicate={isDuplicate}
                                isDelete={isDelete}
                                isEdit={isEdit}
                                isRefresh={isRefresh}
                            />
                        </div>
                    )}
                    <div
                        className={`card-title ${customTitleClass}`}
                        id={idForDom}
                    >
                        {title}
                        <OverlayTrigger
                            placement="auto"
                            overlay={<Tooltip>{customTitleText}</Tooltip>}
                        >
                            <img
                                src={INFOICON}
                                alt="info"
                                className="info-icon"
                            ></img>
                        </OverlayTrigger>
                    </div>
                    <div className={`card-text ${customClass}`} id={idForDom}>
                        {date}
                    </div>
                    <div
                        className={`card-text mb-0 ${customClass}`}
                        id={idForDom}
                    >
                        {time}
                    </div>
                    {isClaimed && claimed_requests?.length ? (
                        <div
                            className={`card-text card-info-text`}
                            id={idForDom}
                        >
                            Claimed by:{' '}
                            {claimed_requests.length > ConstNumber.VALUE_1 ? (
                                <>
                                    {
                                        claimed_requests[0].user.business
                                            .business_name
                                    }{' '}
                                    <span id={idForDom}>
                                        +
                                        {claimed_requests.length -
                                            ConstNumber.VALUE_1}
                                    </span>
                                </>
                            ) : (
                                claimed_requests[0].user.business.business_name
                            )}
                        </div>
                    ) : undefined}
                </div>
            </Col>
        </>
    );
};

export default EventCard;
