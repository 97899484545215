import { ChangeEvent, useRef, useState } from 'react';
import { ConstNumber } from '../constants/number.constant';
import { Crop, centerCrop, makeAspectCrop } from 'react-image-crop';
import { InitialCorp } from '../constants/data.constant';
import { Provider } from '../utils/types.elements';
import { ValidationEnums } from '../constants/application.constant';
import {
    getCroppedImage,
    replaceSpecialCharIntoUnderscore,
    validateSize,
    validateType,
} from '../utils/helper';
import { UploadMediaProps } from '../types/type';
import { useGetUrlMutation } from '../slices/slice.meta';
import Toast from '../utils/toast.helper';
import { ToastEnum } from '../constants/html.constant';

const BANNER_WIDTH_RATIO = ConstNumber.VALUE_16;
const BANNER_HEIGHT_RATIO = ConstNumber.VALUE_9;

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: 'px',
                width: mediaWidth - ConstNumber.VALUE_20,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

export function useUploadMedia(props: UploadMediaProps) {
    const {
        show,
        setShow,
        document: doc,
        clear,
        fromCategory,
        isOptional,
        thumbnailUrl,
    } = props;
    const [getUrl] = useGetUrlMutation({});
    const [document, setDocument] = useState(doc);
    const [crop, setCrop] = useState<Crop>(InitialCorp);
    const [image, setImage] = useState<string | undefined>(undefined);
    const [imageFile, setImageFile] = useState<HTMLImageElement | null>(null);
    const [croppedImage, setCroppedImage] = useState<File | null>(null);
    const [video, setVideo] = useState<File | null>(null);
    const [uploadValidation, checkValidation] = useState<Provider>({
        value: [],
        valid: true,
    });
    const videoRef = useRef<HTMLVideoElement>(null);
    const aspect = BANNER_WIDTH_RATIO / BANNER_HEIGHT_RATIO;

    const noMedia = (_image: string | undefined, _video: File | null) => {
        if (!image && !video) {
            return true;
        } else {
            return false;
        }
    };

    const isActionDisabled = noMedia(image, video);

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        props.setValue(props.name, null);
        setDocument(null);
        if (file === undefined) {
            return;
        }
        checkValidation({
            valid: true,
            value: ['success'],
        });
        if (file) {
            const fileType = file.type;
            if (fileType.startsWith('video/')) {
                const renamedFile = new File(
                    [file],
                    replaceSpecialCharIntoUnderscore(file.name),
                    {
                        type: fileType,
                    }
                );
                setVideo(renamedFile);
                setCroppedImage(null);
            } else if (fileType.startsWith('image/')) {
                const type = validateType(
                    file.type,
                    ['heif', 'png', 'jpg', 'jpeg'],
                    ValidationEnums.INVALID_FILE_FORMAT
                );
                const size = validateSize(file.size, ConstNumber.VALUE_8000000);

                if (type.valid && size.valid) {
                    checkValidation({ valid: true, value: ['success'] });
                    const reader = new FileReader();
                    reader.onload = () => {
                        setImage(reader.result as string);
                    };
                    if (file) {
                        reader.readAsDataURL(file);
                    }
                } else {
                    checkValidation({
                        valid: false,
                        value: [type.value, size.value],
                    });
                }
            }
        }
    };

    const handleCropComplete = (_crop: Crop) => {
        if (image) {
            getCroppedImage(_crop, imageFile, setCroppedImage, setVideo);
        }
    };

    const saveHandler = async () => {
        props.setValue(props.name, video || croppedImage);
        setDocument(video || croppedImage);
        setShow(false);
        const res = await getUrl({
            file: (video || croppedImage) as File,
        });
        if ('data' in res) {
            props.setValue('s3MediaUrl', res.data.pre_signed_link);
        } else {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                ToastEnum.PRESIGNED_URL_ERROR
            );
        }
    };

    const deleteHandler = () => {
        props.setValue(props.name, null);
        setDocument(null);
        setVideo(null);
        setImage(undefined);
        setCroppedImage(null);
    };

    const closeHandler = () => {
        if (!document) {
            setImage(undefined);
        }
        setShow(false);
    };

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        setImageFile(e.currentTarget);
        if (aspect) {
            const { width, height } = e.currentTarget;
            const _centerPixelCrop = centerAspectCrop(width, height, aspect);
            setCrop(() => _centerPixelCrop);
            getCroppedImage(
                _centerPixelCrop,
                imageFile,
                setCroppedImage,
                setVideo,
                e.currentTarget
            );
        }
    };

    return {
        doc,
        show,
        clear,
        fromCategory,
        isOptional,
        crop,
        uploadValidation,
        videoRef,
        isActionDisabled,
        handleImageChange,
        handleCropComplete,
        saveHandler,
        closeHandler,
        deleteHandler,
        onImageLoad,
        image,
        setImage,
        video,
        setVideo,
        document,
        setDocument,
        setCrop,
        setShow,
        checkValidation,
        thumbnailUrl,
    };
}
