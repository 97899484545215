import { Navigate, useLocation } from 'react-router-dom';
import {
    Bool,
    EventActionType,
    localStorageKeys,
} from '../../../shared/constants/application.constant';
import { routeObj } from '../../../shared/constants/routes.constants';
import BackWithText from '../../../shared/elements/BackWithText';
import Loader from '../../../shared/elements/Loader';
import { FormParams } from '../../../shared/utils/types.elements';
import CreateAdvertisementForm from '../elements/CreateAdvertisementForm';
import useCreateAdvertisement from '../hooks/useCreateAdvertisement';
import { useEffect } from 'react';

const CreateAdvertisement = () => {
    const params = useLocation().state as FormParams;
    const { isLoadingMeta, isDataFethcing } = useCreateAdvertisement(params);
    const hasReloaded = localStorage.getItem(localStorageKeys.HAS_RELOADED);

    useEffect(() => {
        if (hasReloaded !== Bool.true) {
            // we have added falg reload the page forcefully to get the acceptCore.js correctly in our application
            localStorage.setItem(localStorageKeys.HAS_RELOADED, Bool.true);
            window.location.reload();
        }
        return () => {
            localStorage.setItem(localStorageKeys.HAS_RELOADED, Bool.false);
        };
    }, []);

    if (!params) {
        return <Navigate to={routeObj.BUSINESS_ADVERTISING} />;
    }

    if (isLoadingMeta || isDataFethcing) {
        return <Loader />;
    }

    return (
        <>
            <BackWithText
                to={routeObj.BUSINESS_ADVERTISING}
                label={
                    params?.id && params?.type === EventActionType.EDIT
                        ? 'Update Advertisement'
                        : 'Add New Advertisement'
                }
                state={{
                    activeTab: params.activeTab,
                }}
            />
            <CreateAdvertisementForm params={params} />
        </>
    );
};

export default CreateAdvertisement;
