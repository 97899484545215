import { Button, Col, Form, Row } from 'react-bootstrap';
import CustomInput, { TextArea } from '../../../shared/elements/CustomInput';
import { ConstNumber } from '../../../shared/constants/number.constant';
import MultiSelect from '../../../shared/elements/MultiSelect';
import {
    EventActionType,
    TabKeys,
} from '../../../shared/constants/application.constant';
import { useCreateSubscriptionPlan } from '../hooks/useCreateSubscriptionPlan';
import { ToastEnum } from '../../../shared/constants/html.constant';
import { SubmitButton } from '../../../shared/elements/Buttons';
import { useEffect } from 'react';
import Toast from '../../../shared/utils/toast.helper';
import { routeObj } from '../../../shared/constants/routes.constants';
import { ErrorObj } from '../../../shared/types/type';
import { useNavigate } from 'react-router-dom';
import {
    getButtonLabel,
    getLoadingLabel,
    getPriceLabel,
} from '../utils/subscriptionsUtils';
import DetailChip from './DetailChip';
import { orOrLogic } from '../../../shared/utils/orLogic';

const SubscriptionPlanForm = () => {
    const {
        handleSubmit,
        isValid,
        register,
        errors,
        categoryOptions,
        optionSelected,
        handleChange,
        trigger,
        watch,
        onSubmitForm,
        params,
        onClickAdd,
        features,
        onClickRemove,
        isLoading,
        paymentPlanDataById,
        setInitialValues,
        setDropdownVaue,
        createResponse,
        createError,
    } = useCreateSubscriptionPlan();
    const planDetails = watch('planDetails')?.trim();
    const navigate = useNavigate();

    useEffect(() => {
        setInitialValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentPlanDataById]);

    useEffect(() => {
        if (
            params.id &&
            paymentPlanDataById?.plan_type === ConstNumber.VALUE_1
        ) {
            setDropdownVaue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentPlanDataById]);

    useEffect(() => {
        if (createResponse?.message) {
            Toast.success(
                ToastEnum.PAYMENT_PLAN_CREATED,
                createResponse?.message
            );
            navigate(routeObj.ADMIN_SUBSCRIPTIONS, {
                state: {
                    active_tab: params?.activeTab,
                },
            });
        } else if (createError) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (createError as ErrorObj)?.message
            );
        }
    }, [createResponse?.message, createError]);

    return (
        <div className="outer-border-container">
            <Form
                className="modal-form demo-form"
                onSubmit={handleSubmit(onSubmitForm)}
            >
                <Row>
                    <Col md={6}>
                        <CustomInput
                            label="Name"
                            type="text"
                            name={'name'}
                            register={register}
                            errorMsg={errors.name?.message}
                            placeholder="Plan Name"
                            maxLength={ConstNumber.VALUE_100}
                            onBlur={() => trigger('name')}
                        />
                    </Col>
                    <Col md={6}>
                        <CustomInput
                            label={getPriceLabel(params)}
                            type="text"
                            name={'price'}
                            placeholder="00.00"
                            register={register}
                            errorMsg={errors.price?.message}
                            onBlur={() => trigger('price')}
                            disabled={Boolean(params.id)}
                        />
                    </Col>
                    {params.activeTab === TabKeys.PLATFORM_SUBSCRIPTION ? (
                        <Col md={6}>
                            <Row>
                                <Col sm={12}>
                                    <TextArea
                                        name={'description'}
                                        register={register}
                                        errorMsg={errors.description?.message}
                                        placeholder="Subscription Description"
                                        label="Description*"
                                        rows={ConstNumber.VALUE_10}
                                        maxLength={ConstNumber.VALUE_1000}
                                        optional={false}
                                        onBlur={() => trigger('description')}
                                    />
                                </Col>
                                <Col sm={12}>
                                    <div className="input-with-button">
                                        <CustomInput
                                            label="Details"
                                            type="text"
                                            name={'planDetails'}
                                            placeholder="Plan Details"
                                            register={register}
                                        />
                                        {planDetails && (
                                            <Button
                                                variant="link"
                                                onClick={onClickAdd}
                                            >
                                                Add
                                            </Button>
                                        )}
                                    </div>
                                    <div className="chip-container mt-0">
                                        {features.map((value, index) => (
                                            <DetailChip
                                                value={value}
                                                onClickRemove={onClickRemove}
                                            />
                                        ))}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ) : undefined}
                    <Col md={6}>
                        {params.activeTab === TabKeys.PLATFORM_SUBSCRIPTION ? (
                            <Form.Group className="form-group" controlId="">
                                <MultiSelect
                                    options={categoryOptions}
                                    label="Categories/Tags"
                                    isSearchable={false}
                                    isMulti
                                    isClearable={false}
                                    isSelectAll={true}
                                    value={optionSelected}
                                    changeHandler={handleChange}
                                    errorMsg={
                                        errors.level_1_categories?.message
                                    }
                                    placeholder={
                                        categoryOptions.length
                                            ? 'Plan Categories'
                                            : 'No un-linked categories available'
                                    }
                                    isDisabled={
                                        Boolean(
                                            params?.type ===
                                                EventActionType.EDIT
                                        ) || !categoryOptions.length
                                    }
                                />
                            </Form.Group>
                        ) : (
                            <CustomInput
                                label="Views"
                                type="text"
                                name={'plan_views'}
                                placeholder="e.g. 100"
                                register={register}
                                errorMsg={(errors as any)?.plan_views?.message}
                                onBlur={() => trigger('plan_views' as any)}
                                disabled={Boolean(params.id)}
                            />
                        )}
                    </Col>
                </Row>
                <div className="button-footer">
                    <SubmitButton
                        isDisable={orOrLogic(!isValid, isLoading)}
                        isLoading={isLoading}
                        customClass="mw-130"
                        label={getButtonLabel(params)}
                        loadingLabel={getLoadingLabel(params)}
                    />
                </div>
            </Form>
        </div>
    );
};

export default SubscriptionPlanForm;
