import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { CALENDARICONBLUE } from '../constants/image.constant';
interface DateRangeElementProps {
    dateHandler: (date: [Date | null, Date | null]) => void;
    startDate: Date | null;
    endDate: Date | null;
}

export const DateRangeElement = (props: DateRangeElementProps) => {
    const { dateHandler, endDate, startDate } = props;

    return (
        <div className="custom-datepicker data-range-picker">
            <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={dateHandler}
                isClearable={true}
                placeholderText="Filter by Date"
            />
            {!startDate && !endDate && (
                <img
                    src={CALENDARICONBLUE}
                    className="calender-icon"
                    alt="icon"
                ></img>
            )}
        </div>
    );
};
