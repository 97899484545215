import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TabKeys } from '../../../shared/constants/application.constant';
import ExternalEvents from '../screen/ExternalEvents';
import ExternalBusiness from '../screen/ExternalBusiness';
import { FilterArg, ActiveTabParams } from '../../../shared/types/type';
import { DataWarehouseParam } from '../utils/types.apiEvents';

interface Props extends DataWarehouseParam {
    filter: FilterArg;
    active_tab?: string | null;
}

const useAPIEvents = (props: Props) => {
    const { isDataWareHouse } = props;
    const location = useLocation();
    const params = location?.state as ActiveTabParams;
    const [activeTab, setActiveTab] = useState<string | null>(
        params?.active_tab || TabKeys.EXTERNAL_EVENT
    );

    const tabs = [
        {
            eventKey: TabKeys.EXTERNAL_EVENT,
            title: 'Events',
            content: (
                <ExternalEvents
                    filters={props.filter}
                    isDataWareHouse={isDataWareHouse}
                />
            ),
        },
        {
            eventKey: TabKeys.EXTERNAL_BUSINESS,
            title: 'Businesses',
            content: <ExternalBusiness filter={props.filter} />,
        },
    ];

    const handleTabClick = useCallback((tab: string | null): void => {
        setActiveTab(tab);
    }, []);

    return {
        tabs,
        activeTab,
        handleTabClick,
    };
};

export default useAPIEvents;
