import { useEffect, useState } from 'react';
import { NotificationListData } from '../utils/types.notificationList';
import {
    notificationListApi,
    useGetNotificationListQuery,
} from '../slices/slice.notificationList';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ConstNumber } from '../../../shared/constants/number.constant';
import { useUserDropdown } from '../../../shared/hooks/useUserDropdown';
import { resetScreen, setScreen } from '../../../shared/slices/slice.notification';

const useNotificationList = () => {
    const dispatch = useAppDispatch();
    const [page, setPage] = useState<number>(ConstNumber.VALUE_1);
    const [notifications, setNotifications] = useState<NotificationListData[]>(
        []
    );
    const [isLoadingNotifications, setIsLoadingNotifications] =
        useState<boolean>(true);

    const { data, isLoading, isFetching, refetch } = useGetNotificationListQuery({
        page,
    });
    const hasMore = data && data?.data.length > ConstNumber.VALUE_0;
    const { refetchNotificationCount } = useUserDropdown();
    const { doRedirect, isNotificationScreen } = useAppSelector((state) => state.notificationStates);

    const handleFetchNextPage = () => {
        if (!isFetching && hasMore) {
            setPage((prevPage) => prevPage + ConstNumber.VALUE_1);
        }
    };

    const resetData = () => {
        setPage(ConstNumber.VALUE_1);
        setNotifications([]);
        dispatch(notificationListApi.util.resetApiState());
    };

    useEffect(() => {
        if (data) {
            setNotifications((prevData) => [...prevData, ...data.data]);
            setIsLoadingNotifications(false);
            if (page === ConstNumber.VALUE_1) {
                refetchNotificationCount();
            }
        }
    }, [data]);

    useEffect(() => {
        dispatch(setScreen());
        // Reset data when component unmounts or changes
        return () => {
            dispatch(resetScreen());
            resetData();
        };
    }, []);

    useEffect(() => {
        if (isLoading) {
            setIsLoadingNotifications(true);
        }
    }, [isLoading]);

    useEffect(() => {
        if (doRedirect && isNotificationScreen) {
            resetData();
            setTimeout(() => {
                refetch();
            }, ConstNumber.VALUE_100);
        }
    }, [doRedirect, isNotificationScreen]);

    return {
        notifications,
        hasMore,
        handleFetchNextPage,
        isLoading,
        isLoadingNotifications,
    };
};

export default useNotificationList;
