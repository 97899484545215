import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { default as ReactSelect, ActionMeta } from 'react-select';
import { ConstNumber } from '../constants/number.constant';
import { customStyles } from '../constants/style.constant';
import { orOrLogic, ternaryLogic } from '../utils/orLogic';
import { Option, SelectDropDownProps } from '../utils/types.elements';
import OptionComponent from './OptionComponent';

const MultiSelect = (props: SelectDropDownProps) => {
    const {
        label,
        options,
        getOptionLabel,
        getOptionValue,
        changeHandler,
        isClearable,
        isSearchable,
        placeholder,
        errorMsg,
        isMulti,
        isOptionDisabled,
        value,
        isOptional,
    } = props;
    const isAllSelected = useRef<boolean>(false);
    const allOption = { value: '*', label: 'Select all' };
    const [selectedOptions, setSelectedOptions] = useState<Option[]>(
        orOrLogic(value, [])
    );

    const filterOptions = (optionsValue: Option[]) =>
        optionsValue?.filter(({ label: labelValue }: Option) =>
            labelValue.toLowerCase()
        );

    const comparator = (v1: Option, v2: Option) =>
        (v1.value as number) - (v2.value as number);

    const filteredOptions = filterOptions(options);
    const filteredSelectedOptions = filterOptions(value);

    const OptionComponentElement = (propsData: any) =>
        OptionComponent(propsData, isAllSelected, filteredSelectedOptions);

    const handleChange = (selected: any, actionMeta: ActionMeta<any>) => {
        if (
            selected.length > ConstNumber.VALUE_0 &&
            !isAllSelected.current &&
            (selected[selected.length - ConstNumber.VALUE_1].value ===
                allOption.value ||
                JSON.stringify(filteredOptions) ===
                    JSON.stringify(selected.sort(comparator)))
        ) {
            const optionSelectedData = [
                ...(value ?? []),
                ...options.filter(
                    ({ label: labelData }: Option) =>
                        labelData.toLowerCase() &&
                        (value ?? []).filter(
                            (opt: Option) => opt.label === labelData
                        ).length === ConstNumber.VALUE_0
                ),
            ].sort(comparator);
            setSelectedOptions(optionSelectedData);
            return changeHandler(optionSelectedData);
        } else if (
            selected.length > ConstNumber.VALUE_0 &&
            selected[selected.length - ConstNumber.VALUE_1].value !==
                allOption.value &&
            JSON.stringify(selected.sort(comparator)) !==
                JSON.stringify(filteredOptions)
        ) {
            setSelectedOptions(selected);
            return changeHandler(selected);
        } else {
            const optionSelectedData = [
                ...props.value?.filter(
                    ({ label: labelVal }: Option) => !labelVal.toLowerCase()
                ),
            ];
            setSelectedOptions(optionSelectedData);
            return changeHandler(optionSelectedData);
        }
    };

    useEffect(() => {
        setSelectedOptions(orOrLogic(value, []));
    }, [value]);

    useEffect(() => {
        const selectedValues = selectedOptions.map(
            (option: Option) => option.value
        );
        const allValues = filteredOptions.map((option: Option) => option.value);
        isAllSelected.current =
            selectedValues.length === allValues.length &&
            selectedValues.every((valueData) => allValues.includes(valueData));
    }, [selectedOptions, filteredOptions, filteredSelectedOptions]);

    useEffect(() => {
        isAllSelected.current =
            selectedOptions.length === filteredOptions.length;
    }, [selectedOptions, filteredOptions]);

    return (
        <>
            <Form.Label>{`${label}${ternaryLogic(isOptional, '', '*')}`}</Form.Label>
            <ReactSelect
                {...props}
                options={[allOption, ...options]}
                onChange={handleChange}
                components={{
                    Option: OptionComponentElement,
                }}
                styles={customStyles}
                isMulti={isMulti}
                closeMenuOnSelect={false}
                tabSelectsValue={false}
                backspaceRemovesValue={false}
                hideSelectedOptions={false}
                blurInputOnSelect={false}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                placeholder={placeholder}
                isSearchable={isSearchable}
                isClearable={isClearable}
                className="custom-select"
                aria-invalid={ternaryLogic(errorMsg, 'true', 'false')}
                isOptionDisabled={isOptionDisabled}
                value={value}
                // menuIsOpen // this key is used to open menu, used for development purpose.
            />
            {errorMsg && (
                <Form.Control.Feedback type="invalid">
                    {errorMsg}
                </Form.Control.Feedback>
            )}
        </>
    );
};

export default MultiSelect;
