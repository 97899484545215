import { useRef, useState } from 'react';
import { BUSINESS_IMAGE_PLACEHOLDER } from '../constants/image.constant';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';

interface Props {
    imageUrl?: string | null;
    defaultImage?: string;
    id?: string;
    disableDetail?: boolean;
}

export function Image({
    imageUrl,
    defaultImage = BUSINESS_IMAGE_PLACEHOLDER,
    id,
    disableDetail = true,
}: Props) {
    const ref = useRef<HTMLImageElement | null>(null);
    const [show, setShow] = useState(false);
    const handleError = () => {
        if (ref && ref.current) {
            ref.current.src = defaultImage;
        }
    };
    return (
        <>
            <img
                ref={ref}
                src={imageUrl || ''}
                alt="bg"
                className="card-img"
                onError={handleError}
                role="button"
                id={id}
                onClick={() => disableDetail && setShow(true)}
            />
            <Modal show={show} size="lg" centered className="image-modal">
                <ModalHeader
                    closeButton
                    onClick={() => setShow(false)}
                    className="big-header"
                />
                <ModalBody className="text-center">
                    <img
                        ref={ref}
                        src={imageUrl || ''}
                        alt="bg"
                        className="card-img"
                        onError={handleError}
                    />
                </ModalBody>
            </Modal>
        </>
    );
}
