import React, { useRef, useState } from 'react';
import { PromoCodeInputProps } from '../types/type';

export function usePromoCodeInput(props: PromoCodeInputProps) {
    const { isApplied, removeCoupon, applyCode, isLoading } = props;
    const ref = useRef<HTMLInputElement | null>(null);
    const [value, setValue] = useState('');

    const applyHandler = () => {
        applyCode(value);
    };

    const removeCouponHandler = () => {
        setValue('');
        removeCoupon();
    };

    const changeHandler = (code: string) => {
        setValue(code);
    };

    return {
        isApplied,
        removeCouponHandler,
        applyHandler,
        ref,
        value,
        isLoading,
        disabled: !Boolean(value),
        changeHandler,
    };
}
