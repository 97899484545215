import { SignIn } from '../../modules/onBoarding/utils/slice.types';
import { localStorageKeys } from '../constants/application.constant';
import { routeObj } from '../constants/routes.constants';
import { StorageKey } from './types.elements';

export const getStorageData = (key?: StorageKey) => {
    const localData = localStorage.getItem(localStorageKeys.USER);
    let userData: Record<StorageKey, string> = {
        role: '',
        access_token: '',
        username: '',
        expirationTime: '',
    };
    if (localData) {
        userData = JSON.parse(localData);
        return key ? userData[key] : userData;
    }
    return userData;
};

export const isLoggedIn = () => {
    return getStorageData();
};

/**
 *
 * @param key that u want to store in localstorage
 * @param data store data against the key
 * @param isString if data is already a string please pass this as true
 */
export const setDataIntoLocalStorage = (
    key: string,
    data: any,
    isString = false
) => {
    localStorage.setItem(key, isString ? data : JSON.stringify(data));
};

export const clearStorage = () => {
    localStorage.clear();
};

export const redirectToIndex = () => {
    clearStorage();
    window.location.href = routeObj.DEFAULT;
};

export const getTempLogin = () => {
    const localCred = localStorage.getItem(localStorageKeys.TEMP_LOGIN);
    if (localCred) {
        return JSON.parse(localCred) as SignIn;
    } else {
        return {
            password: '',
            username: '',
        } as SignIn;
    }
};
