import CustomTabs from '../../../shared/elements/CustomTabs';
import { TabKeys } from '../../../shared/constants/application.constant';
import { AddButton } from '../../../shared/elements/Buttons';
import { AddButtonPrimary } from '../../../shared/elements/ButtonsPrimary';
import useEventTabs from '../hooks/useEventTabs';

const Events = () => {
    const {
        tabs,
        activeTab,
        handleTabClick,
        navigateToCreate,
        handleShowUpload,
    } = useEventTabs();

    return (
        <>
            <div
                className={`spacial-tab-container event-tab-container events-tab-container-event ${
                    activeTab === TabKeys.CLAIMED_EVENTS ? 'less-top-margin' : ''
                }`}
            >
                <CustomTabs
                    defaultActiveKey={TabKeys.MY_EVENTS}
                    tabs={tabs}
                    activeTab={activeTab}
                    handleTabClick={handleTabClick}
                />
                {activeTab === TabKeys.MY_EVENTS && (
                    <div className="absolute-btn-container">
                        <AddButtonPrimary
                            handleClick={navigateToCreate}
                            label="Add Event"
                        />
                        <AddButton
                            handleClick={handleShowUpload}
                            label="Upload CSV"
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default Events;
