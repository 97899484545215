import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkIsAdmin, isUserAuthorized } from '../../utils/helper';
import { routesConfig } from './PrivateRoutesConfig';
import MapAllowedRoutes from './MapAllowedRoutes';
import { getStorageData } from '../../utils/storage.helpers';
import { getAllowedRoutes } from '../../utils/routes.filterRole';
import { routeObj } from '../../constants/routes.constants';
import {
    UserRole,
    localStorageKeys,
} from '../../constants/application.constant';

function PrivateRoutes() {
    const navigate = useNavigate();
    const roleData = getStorageData(localStorageKeys.ROLE);
    const path = window.location.pathname;
    const allowedRoutes = React.useMemo(
        () => getAllowedRoutes(routesConfig),
        []
    );

    React.useEffect(() => {
        if (isUserAuthorized() && path === '/') {
            if (checkIsAdmin()) {
                navigate(routeObj.ADMIN);
            } else if (roleData === UserRole.BUSINESS) {
                navigate(routeObj.BUSINESS_DASHBOARD);
            } else {
                navigate('/');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleData]);

    return (
        <Fragment>
            <MapAllowedRoutes
                routes={allowedRoutes}
                basePath="/"
                isAddNotFound
            />
        </Fragment>
    );
}

export default PrivateRoutes;
