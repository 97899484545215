import { Button } from 'react-bootstrap';
import { VERIFICATION } from '../../../shared/constants/image.constant';
import { useVerifySuccess } from '../hooks/useVerifySuccess';
import { useLocation } from 'react-router-dom';
import {
    ButtonLabel,
    ToastEnum,
} from '../../../shared/constants/html.constant';
import {
    Params as ParamsEnum,
    localStorageKeys,
} from '../../../shared/constants/application.constant';
import { useEffect } from 'react';
import { useCompleteUserMutation } from '../slices/slice.authApi';
import { setDataIntoLocalStorage } from '../../../shared/utils/storage.helpers';
import Toast from '../../../shared/utils/toast.helper';
import { useAppDispatch } from '../../../../store/hooks';
import { signInUser } from '../slices/slice.auth';
import { SignIn } from '../utils/slice.types';

interface Params {
    from: string;
}

const VerifySuccess = () => {
    const { from } = useLocation().state as Params;
    const {
        state,
        clickHandler,
        getLabel,
        disable: isUserRegistering,
        localStorageData,
        listenToAutoSignInEvent,
    } = useVerifySuccess(from);

    useEffect(() => {
        if (Boolean(localStorageData.password)) {
            listenToAutoSignInEvent();
        }
    }, []);

    return (
        <div className="login-inner success">
            <img src={VERIFICATION} alt="Verify" className="verify-image" />
            <h1 className="login-title">
                {from === ParamsEnum.RESET_PASSWORD
                    ? 'Password Reset'
                    : 'Verification Successful'}
            </h1>
            <p className="verify-subtext">
                {from === ParamsEnum.RESET_PASSWORD
                    ? `Your Password has been reset. To log in with the new password click on Back to Sign In`
                    : `Your account has been created and successfully verified. Get a subscription plan to proceed further.`}
            </p>
            <div className="btn-container">
                {isUserRegistering ? (
                    <Button variant="primary" disabled>
                        {ButtonLabel.WAIT}
                    </Button>
                ) : (
                    <Button onClick={clickHandler} variant="primary">
                        {getLabel()}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default VerifySuccess;
