import React from 'react';

type Props = {
    showDiscount: boolean;
    originalPrice: string;
    discountedPrice: string | number;
};

const SubscriptionPrice = (props: Props) => {
    const { discountedPrice, originalPrice, showDiscount } = props;
    return (
        <>
            <div className={`price ${showDiscount ? 'price-cut' : ''}`}>
                <span>{`$${originalPrice}`}</span>
                /month
            </div>
            {showDiscount && (
                <div className="price">
                    <span>{`$${discountedPrice}`}</span>
                    /month
                </div>
            )}
        </>
    );
};

export default SubscriptionPrice;
