export enum HttpStatusCode {
    BAD_REQUEST = 400,
    UNAUTHORISED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    UNPROCESSABLE_ENTITY = 422,
    NOT_EXIST = 406,
    SUCCESS_REQUEST = 200,
    SUCCESS_CODE_202 = 202,
    SUCCESS_CODE_201 = 201,
    SUCCESS_CODE_204 = 204,
    SUCCESS_CODE_205 = 205,
    ERR_NETWORK = 'ERR_NETWORK',
    FETCH_ERROR = 'FETCH_ERROR',
}

export enum CognitoExceptions {
    USER_NOT_CONFIRMED = 'UserNotConfirmedException',
    USER_DEACTIVATED = 'NotAuthorizedException',
    USER_NOT_FOUND = 'UserNotFoundException',
    LIMIT_EXCEPTION = 'LimitExceededException',
}

export enum ValidationEnums {
    REQUIRED = 'This field is required.',
    CHOOSE_ADDRESS = 'Please select the address from suggestions in dropdown.',
    VALID_WEBSITE = 'Please enter a valid website URL.',
    END_DATE_ERROR = "The event's end date and time must be after the start date and time.",
    START_TIME_ERROR = "Event can't be before current time.",
    SAME_START_END_ERROR = "Event can't be end on the start time.",
    INVALID_FILE_SIZE = 'The image must be less than',
    INVALID_VIDEO_SIZE = 'The Video must be less than',
    INVALID_VIDEO_DURATION = 'The Video must be less than',
    INVALID_FILE_FORMAT = 'The file of type: .jpg, .jpeg, .png, .heic allowed.',
    INVALID_VIDEO_FORMAT = 'The Video of type: .mp4, .mov allowed.',
    INVALID_MOBILE = 'Business phone number is not valid.',
    MAX_1000 = 'Max 1000 characters are allowed.',
    EMAIL_FORMAT = 'Please enter valid Email Id.',
    CLOSE_TIME_ERROR = 'Close time will be greater than start time.',
    PASSWORDS_MATCH = 'Passwords must match.',
    OTP_MATCH = 'Code should be exactly 6 digits.',
    OTP_NUMERIC = 'Code should only contain numeric characters.',
    INCORRECT_PASSWORD = 'Password must meet the following criteria:',
    MAX_14 = 'Max 14 characters are allowed.',
    MAX_30 = 'Max 30 characters are allowed.',
    MAX_50 = 'Max 50 characters are allowed.',
    MAX_100 = 'Max 100 characters are allowed.',
    MAX_150 = 'Max 150 characters are allowed.',
    MAX_200 = 'Max 200 characters are allowed.',
    MAX_255 = 'Max 255 characters are allowed.',
    SAME_CURRENT_PASSWORD = 'New Password must not be the same as Current Password.',
    VALID_PRICE = 'Please enter a valid price.',
    VALID_NUMBER = 'Please enter a valid number.',
    VALID_PERCENTAGE = 'Please enter a valid percentage.',
    MAX_8 = 'Max 8 characters are allowed.',
    MAX_6 = 'Max 6 characters are allowed.',
    ONLY_CSV = 'Only CSV files are allowed.',
    NO_INTERNET = 'There is no internet connection.',
}

export enum TabKeys {
    LOCAL_SPECIAL = 'localSpecial',
    HAPPY_HOURS = 'happyHours',
    MY_EVENTS = 'myEvents',
    CLAIMED_EVENTS = 'claimedEvents',
    BUSINESS_CATEGORIES = 'businessCategories',
    EVENT_CATEGORIES = 'eventCategories',
    BUSINESS_DETAIL = 'businessDetail',
    EVENT = 'Events',
    SPECIALS = 'Specials',
    ADVERTISEMENTS = 'Advertisements',
    SUBSCRIPTIONINFORMATION = 'subscriptionInformation',
    ONGOING_ADS = 'ongoingAds',
    PAST_ADS = 'pastAds',
    PLATFORM_SUBSCRIPTION = 'platformSubscription',
    ADVERTISEMENT_SUBSCRIPTION = 'advertisementSubscription',
    EXTERNAL_EVENT = 'externalEvents',
    EXTERNAL_BUSINESS = 'externalBusiness',
    SUBSCRIPTION_PLANS = 'subscriptionPlans',
    ADVERTISEMENT_PLANS = 'advertisementPlans',
    WAREHOUSE_EVENT = 'warehouseEvents',
    WAREHOUSE_BUSINESS = 'warehouseBusiness',
}

export enum UserRole {
    BUSINESS = '2', // this will be consider as a custom property
    SUB_ADMIN = '3', // this will be consider as a custom property
    SUPER_ADMIN = '4', // this will be consider as a custom property
}

export enum localStorageKeys {
    TOKEN = 'access_token',
    USERNAME = 'username',
    ROLE = 'role',
    EXPTIME = 'expirationTime',
    UUID = 'uuid',
    USER = 'userInfo',
    LOCATION = 'location',
    HAS_RELOADED = 'hasReloaded',
    FCM_TOKEN = 'fcm_token',
    TEMP_LOGIN = 'temp_login',
}

export enum DateFormats {
    DATE = 'DD MMM yyyy',
    TIME = 'hh:mm A',
    YYYY_MM_DD = 'YYYY-MM-DD',
    HH_MM = 'HH:mm',
    TIME_WITH_MARIDIAN = 'h:mm aa',
    TIME_SECOND = 'HH:mm:ss',
    DD_MMM_YYYY = 'DD MMM YYYY',
    DD_MMM_TIME = 'DD MMM hh:mm A',
    MM_DD_YYYY = 'MM/DD/YYYY hh:mm A',
}

export enum SpecialEnum {
    LOCAL_SPECIAL = 2,
    HAPPY_HOURS = 1,
}

export enum HttpMethods {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export enum CountryCode {
    US = '+1',
}

export enum Country {
    US = 'us',
}

export enum ConfirmPopup {
    NO = 'no',
    YES = 'yes',
}

export enum GooglePlaceFields {
    ICON = 'icon',
    NAME = 'name',
    ADDRESS_COMPONENTS = 'address_components',
    ADR_ADDRESS = 'adr_address',
    FORMATTED_ADDRESS = 'formatted_address',
    PLACE_ID = 'place_id',
    GEOMETRY_LOCATION = 'geometry.location',
}

export enum GooglePlaceTypes {
    STATE = 'administrative_area_level_1',
    POSTAL_CODE = 'postal_code',
    CITY = 'locality',
}

export enum CognitoAuth {
    ALLOWED = 'allowed',
    NOT_ALLOWED = 'notAllowed',
    NOT = 'not',
}

export enum CommonEnum {
    FORM_CLASS = 'form-group',
    SEPARATOR = '\n',
    ONE_MONTH = 'one-month',
    TWO_MONTH = 'two-months',
    THREE_MONTH = 'three-months',
    FEW_MONTH = 'few-months',
}

export enum EntertainmentTypeEnum {
    BUSINESS = 2,
    EVENT = 1,
}

export enum EventActionType {
    EDIT = 'edit',
    DELETE = 'delete',
    DUPLICATE = 'duplicate',
    ACTIVATE = 'activate',
    REFRESH = 'refresh',
}

export enum Bool {
    TRUE = 'True',
    true = 'true',
    FALSE = 'False',
    false = 'false',
}

export enum AWS_STATES {
    SIGN_IN_USER = 'signIn',
    EVENT_AUTH = 'auth',
    AUTO_SIGNIN = 'autoSignIn',
    SIGNIN_FAILED = 'autoSignIn_failure',
}

export enum PromotionTypeEnum {
    SUBSCRIPTION = 1,
    ADVERTISEMENT = 2,
}

export enum DiscountCategoryEnum {
    MONTHLY = 1,
    PERCENTAGE = 2,
}

export enum Params {
    OTP_SCREEN = 'verify-otp',
    RESET_PASSWORD = 'reset-password',
}

export enum ClaimEventEnum {
    ACCEPT = 2,
    REJECT = 3,
}

export enum NotificationTypeEnum {
    END_USER = 1,
    BUSINESS_USER = 2,
}

export enum PromoCodeType {
    LIFETIME_FREE = 1,
    DURATION = 2,
    DISCOUNT = 3,
    TRAIL = 4,
    DISCOUNT_ONBOARD = 5,
    TRAIL_ONBOARD = 6,
}

export enum EventStatus {
    PUBLISHED = 1,
    UN_PUBLISHED = 2,
}

export enum ApiParamEnum {
    LIMIT = 24,
    OFFSET = 24,
}
