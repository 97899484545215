import { useEffect, useState } from 'react';
import { ConstNumber } from '../../../shared/constants/number.constant';
import { EventV2 } from '../../../modules/events/constants/types.events';
import {
    apiEventsApi,
    useDeleteExternalEventByIdMutation,
    useGetExternalEventsQuery,
} from '../slices/slice.apiEvents';
import { useNavigate } from 'react-router-dom';
import { routeObj } from '../../../shared/constants/routes.constants';
import { useAppDispatch } from '../../../../store/hooks';
import { ErrorObj, FilterArg } from '../../../shared/types/type';
import {
    DateFormats,
    EventActionType,
} from '../../../shared/constants/application.constant';
import moment from 'moment';
import { DataWarehouseParam } from '../utils/types.apiEvents';
import { ToastEnum } from '../../../shared/constants/html.constant';
import Toast from '../../../shared/utils/toast.helper';
import { andAndLogic, ternaryLogic } from '../../../shared/utils/orLogic';

interface Props extends DataWarehouseParam {
    filter: FilterArg;
}

const useExternalEvents = (props: Props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState<number>(ConstNumber.VALUE_1);
    const [externalEvents, setExternalEvents] = useState<EventV2[]>([]);
    const [isLoadingEvents, setIsLoadingEvents] = useState<boolean>(true);
    const { data, isLoading, isFetching, refetch } = useGetExternalEventsQuery({
        page,
        filters: {
            ...props.filter,
            endDate: ternaryLogic(
                props.filter.endDate,
                moment(props.filter.endDate).format(DateFormats.YYYY_MM_DD),
                ''
            ),
            startDate: ternaryLogic(
                props.filter.startDate,
                moment(props.filter.startDate).format(DateFormats.YYYY_MM_DD),
                ''
            ),
            city: props.filter?.city,
            is_published: props.filter?.is_published,
        },
        isWarehouse: props.isDataWareHouse,
    });
    const [
        deleteEvent,
        {
            isLoading: isDeleting,
            error: deleteExternalEventError,
            data: deleteExternalEventResponse,
        },
    ] = useDeleteExternalEventByIdMutation();
    const hasMore = data && data?.data.length > ConstNumber.VALUE_0;

    const [deletedEvent, setDeletedEvent] = useState<EventV2 | undefined>(
        undefined
    );
    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow((prev) => !prev);
    };
    const actionHandler = (id: number, type: string) => {
        // -- code commented for future use --
        if (type === EventActionType.EDIT) {
            navigate(
                routeObj.ADMIN_WAREHOUSE_EVENT_DETAIL.replace(
                    ':id',
                    id.toString()
                ),
                {
                    state: {
                        id,
                        type,
                        isWarehouse: props.isDataWareHouse,
                    },
                }
            );
        } else if (type === EventActionType.DELETE) {
            const deleteEventValue = externalEvents.find(
                (event) => event.id === id
            );
            setDeletedEvent(deleteEventValue);
            handleShow();
        }
    };
    const handleConfirm = () => {
        deletedEvent &&
            deleteEvent({
                id: deletedEvent.id,
                isWarehouse: props.isDataWareHouse,
            });
    };
    const handleFetchNextPage = () => {
        if (andAndLogic(!isFetching, hasMore)) {
            setPage((prevPage) => prevPage + ConstNumber.VALUE_1);
        }
    };

    useEffect(() => {
        setPage(ConstNumber.VALUE_1);
        setExternalEvents([]);
        refetch();
    }, [
        props.filter.city,
        props.filter.endDate,
        props.filter.startDate,
        props.filter?.is_published,
    ]);

    const resetData = () => {
        setPage(ConstNumber.VALUE_1);
        setExternalEvents([]);
        dispatch(apiEventsApi.util.resetApiState());
    };

    useEffect(() => {
        if (data) {
            setExternalEvents((prevData) => [...prevData, ...data.data]);
            setIsLoadingEvents(false);
        }
    }, [data]);

    useEffect(() => {
        // Reset data when component unmounts or changes
        return () => {
            resetData();
        };
    }, []);

    useEffect(() => {
        isLoading && setIsLoadingEvents(true);
    }, [isLoading]);

    const navigateToDetail = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const target = e.target as HTMLElement;
        target?.id &&
            navigate(
                ternaryLogic(
                    props.isDataWareHouse,
                    routeObj.ADMIN_WAREHOUSE_EVENT_DETAIL,
                    routeObj.ADMIN_API_EVENTS_DETAIL
                ).replace(':id', target.id),
                {
                    state: {
                        isDataWareHouse: props.isDataWareHouse,
                    },
                }
            );
    };

    useEffect(() => {
        if (deleteExternalEventResponse?.message) {
            Toast.success(
                ToastEnum.EVENT_DELETED,
                deleteExternalEventResponse?.message
            );
            handleShow();
            resetData();
            setTimeout(() => {
                refetch();
            }, ConstNumber.VALUE_100);
        } else if (deleteExternalEventError) {
            handleShow();
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (deleteExternalEventError as ErrorObj)?.message
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteExternalEventResponse?.message, deleteExternalEventError]);

    return {
        externalEvents,
        hasMore,
        handleFetchNextPage,
        isLoading,
        isLoadingEvents,
        navigateToDetail,
        actionHandler,
        resetData,
        isDeleting,
        handleConfirm,
        show,
        deletedEvent,
        handleShow,
        isFetching,
    };
};

export default useExternalEvents;
