import { routeObj } from '../../../shared/constants/routes.constants';
import { useCreateEvent } from '../hooks/useCreateEvent';
import Loader from '../../../shared/elements/Loader';
import CreateEventForm from '../elements/CreateEventForm';
import BackWithText from '../../../shared/elements/BackWithText';
import { useEffect } from 'react';
import Toast from '../../../shared/utils/toast.helper';
import { ToastEnum } from '../../../shared/constants/html.constant';
import { ErrorObj } from '../../../shared/types/type';
import { EventActionType } from '../../../shared/constants/application.constant';

const CreateEvent = () => {
    const {
        isLoadingGetCategories,
        isLoadingGetBusinessUser,
        createEventResponse,
        apiError,
        params,
        isDataFethcing,
    } = useCreateEvent();

    useEffect(() => {
        if (createEventResponse?.message) {
            Toast.success(
                ToastEnum.EVENT_CREATED,
                createEventResponse?.message
            );
        } else if (apiError) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (apiError as ErrorObj)?.message
            );
        }
    }, [createEventResponse?.message, apiError]);

    if (isLoadingGetCategories || isLoadingGetBusinessUser || isDataFethcing) {
        return <Loader />;
    }

    return (
        <>
            <BackWithText
                to={routeObj.BUSINESS_EVENTS}
                label={
                    params?.id && params?.type === EventActionType.EDIT
                        ? 'Update Event'
                        : 'Add New Event'
                }
                state={{
                    activeTab: params?.activeTab,
                }}
            />
            <CreateEventForm />
        </>
    );
};

export default CreateEvent;
