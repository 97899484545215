import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConstNumber } from '../../../shared/constants/number.constant';
import {
    Category,
    FormParams,
    Option,
} from '../../../shared/utils/types.elements';
import { useGetCategoriesQuery } from '../../../modules/businessInfo/slices/slice.businessInfo';
import {
    advertisementPlanSchema,
    getInitialData,
    getPaymentCategoryData,
    getPaymentPlanIntialValues,
    getUpdatedData,
    subscriptionPlanSchema,
} from '../utils/subscriptionsUtils';
import { getCategoryOptionsData } from '../../../modules/businessInfo/utils/businessUtils';
import {
    CommonEnum,
    EventActionType,
    TabKeys,
} from '../../../shared/constants/application.constant';
import Toast from '../../../shared/utils/toast.helper';
import { ToastEnum } from '../../../shared/constants/html.constant';
import {
    useCreatePaymentPlanMutation,
    useGetPaymentPlanByIdQuery,
    useUpdatePaymentPlanMutation,
} from '../slices/slice.subscriptions';
import { routeObj } from '../../../shared/constants/routes.constants';
import { ErrorObj } from '../../../shared/types/type';
import { orOrLogic, ternaryLogic } from '../../../shared/utils/orLogic';

let globalObject: any;

export function useCreateSubscriptionPlan() {
    const navigate = useNavigate();
    const params = useLocation().state as FormParams;
    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
        setValue,
        getValues,
        trigger,
        watch,
        resetField,
    } = useForm({
        defaultValues: getPaymentPlanIntialValues(params.activeTab),
        resolver: yupResolver(
            ternaryLogic(
                params.activeTab === TabKeys.PLATFORM_SUBSCRIPTION,
                subscriptionPlanSchema,
                advertisementPlanSchema
            )
        ),
        mode: 'onChange',
    });
    const [categories, setCategories] = useState<Category[]>([]);
    const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);
    const [optionSelected, setOptionSelected] = useState<Option[]>([]);
    const [features, setFeatures] = useState<string[]>([]);
    const {
        data: categoryData,
        isLoading: isLoadingCategories,
        refetch,
    } = useGetCategoriesQuery(
        {
            categoryType: ConstNumber.VALUE_2,
        },
        {
            skip: !Boolean(params.activeTab === TabKeys.PLATFORM_SUBSCRIPTION),
        }
    );
    const [
        createPaymentPlan,
        { isLoading: createLoading, error: createError, data: createResponse },
    ] = useCreatePaymentPlanMutation();
    const { data: paymentPlanDataById, isLoading: isDataFethcing } =
        useGetPaymentPlanByIdQuery(params?.id, {
            skip: !Boolean(params?.id),
        });
    const [
        updatePaymentPlan,
        { isLoading: isUpdating, error: updateError, data: updateResponse },
    ] = useUpdatePaymentPlanMutation();

    useEffect(() => {
        params.activeTab === TabKeys.PLATFORM_SUBSCRIPTION && refetch();
    }, []);

    useEffect(() => {
        categoryData && setCategories(categoryData.unlinked_payment_plan_categories);
    }, [categoryData]);

    useEffect(() => {
        if (categories.length) {
            const categoryOptionsData: Option[] =
                getCategoryOptionsData(categories);
            setCategoryOptions(categoryOptionsData);
        } else {
            setCategoryOptions([]);
        }
    }, [categories]);

    useEffect(() => {
        if (updateResponse?.message) {
            Toast.success(
                ToastEnum.PAYMENT_PLAN_UPDATED,
                updateResponse?.message
            );
            navigate(routeObj.ADMIN_SUBSCRIPTIONS, {
                state: {
                    active_tab: params?.activeTab,
                },
            });
        } else if (updateError) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (updateError as ErrorObj)?.message
            );
        }
    }, [updateResponse?.message, updateError]);

    const setInitialValues = () => {
        if (paymentPlanDataById) {
            let obj: any = getInitialData(paymentPlanDataById);
            setValue('id', paymentPlanDataById.id as any);
            setValue('name', paymentPlanDataById.name);
            setValue('price', paymentPlanDataById.price);
            setValue('plan_type', paymentPlanDataById.plan_type);
            if (params.activeTab === TabKeys.PLATFORM_SUBSCRIPTION) {
                obj = getUpdatedData(obj, paymentPlanDataById);
                setValue('description', paymentPlanDataById.description);
                setValue('features', paymentPlanDataById.features);
                const featureData = paymentPlanDataById.features.split('\n');
                setFeatures(featureData);
            } else {
                obj = {
                    ...obj,
                    plan_views: paymentPlanDataById.plan_views,
                };
                setValue(
                    'plan_views' as any,
                    paymentPlanDataById.plan_views.toString()
                );
            }
            globalObject = obj;
            trigger();
        }
    };

    const setDropdownVaue = () => {
        const _categoryData = getPaymentCategoryData(paymentPlanDataById);
        const categoryField = _categoryData?.map((val) => val.value);
        setOptionSelected(orOrLogic(_categoryData, []));
        setCategoryOptions(orOrLogic(_categoryData, []));
        setValue(
            'level_1_categories',
            orOrLogic(
                _categoryData?.map((val) => val.value as never),
                []
            )
        );
        trigger('level_1_categories');
        if (paymentPlanDataById) {
            globalObject.level_1_categories = JSON.stringify(categoryField);
        }
    };

    const handleChange = (selected: Option[]) => {
        setOptionSelected(selected);
        setValue(
            'level_1_categories',
            orOrLogic(
                selected?.map((val) => val.value as never),
                []
            )
        );
        trigger('level_1_categories');
    };

    const onClickAdd = () => {
        const detailValue = getValues('planDetails');
        if (features.includes(detailValue)) {
            Toast.error(
                ToastEnum.ALREADY_ADDED,
                ToastEnum.DETAIL_ALREADY_ADDED
            );
        } else {
            const updatedDetails = [...features, detailValue];
            setFeatures(updatedDetails);
            setValue('features', updatedDetails.join(CommonEnum.SEPARATOR));
            resetField('planDetails');
            trigger('features');
        }
    };

    const onClickRemove = (value: string) => {
        const updatedArray = features.filter((item) => item !== value);
        setFeatures(updatedArray);
        setValue('features', updatedArray.join('\n'));
        trigger('features');
    };

    const onSubmitForm = async (values: any) => {
        if (params?.id) {
            if (params?.type === EventActionType.ACTIVATE) {
                values = { ...values, is_active: true };
            }
            await updatePaymentPlan({
                data: values,
                initialValues: globalObject,
            });
        } else {
            await createPaymentPlan(values);
        }
    };

    return {
        register,
        handleSubmit,
        onSubmitForm,
        setValue,
        trigger,
        errors,
        isValid,
        getValues,
        categoryOptions,
        optionSelected,
        handleChange,
        watch,
        isLoadingCategories,
        isLoading: createLoading || isUpdating,
        createError,
        createResponse,
        params,
        onClickAdd,
        features,
        onClickRemove,
        isDataFethcing,
        paymentPlanDataById,
        setInitialValues,
        setDropdownVaue,
    };
}
