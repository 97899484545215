import { routeObj } from '../../../shared/constants/routes.constants';
import Loader from '../../../shared/elements/Loader';
import BackWithText from '../../../shared/elements/BackWithText';
import { TabKeys } from '../../../shared/constants/application.constant';
import { useCreateSubscriptionPlan } from '../hooks/useCreateSubscriptionPlan';
import SubscriptionPlanForm from '../elements/SubscriptionPlanForm';

const CreateSubscriptionPlan = () => {
    const { isLoadingCategories, params, isDataFethcing } = useCreateSubscriptionPlan();

    if (isLoadingCategories || isDataFethcing) {
        return <Loader />;
    }

    return (
        <>
            <div className="pb-3">
                <BackWithText
                    to={routeObj.ADMIN_SUBSCRIPTIONS}
                    state={{ active_tab: params?.activeTab }}
                    label={
                        params?.activeTab &&
                        params.activeTab === TabKeys.PLATFORM_SUBSCRIPTION
                            ? `${params?.id ? 'Edit' : 'Add'} Subscription Plan`
                            : `${params?.id ? 'Edit' : 'Add'} Advertisement Plan`
                    }
                />
            </div>
            <SubscriptionPlanForm />
        </>
    );
};

export default CreateSubscriptionPlan;
