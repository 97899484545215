import CreateSpecialForm from '../elements/CreateSpecialForm';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { BACK } from '../../../shared/constants/image.constant';
import { routeObj } from '../../../shared/constants/routes.constants';
import { FormParams } from '../../../shared/utils/types.elements';

const CreateSpecial = () => {
    const params = useLocation().state as FormParams;

    if (!params) {
        return <Navigate to={routeObj.BUSINESS_SPECIALS} />;
    }

    return (
        <>
            <div className="back-with-text">
                <Link
                    to={routeObj.BUSINESS_SPECIALS}
                    state={{
                        activeTab: params.activeTab,
                    }}
                    title="Back"
                    className="back-btn"
                >
                    <img src={BACK} alt="back" />
                </Link>
                <span>Set Special Details</span>
            </div>
            <CreateSpecialForm params={params} />
        </>
    );
};

export default CreateSpecial;
