import { Button } from 'react-bootstrap';
import { PLUS } from '../constants/image.constant';
import {
    AddButtonProps,
    CustomButtonProps,
    DangerLinkProps,
    SubmitButtonProps,
} from '../types/type';
import { ButtonLabel } from '../constants/html.constant';

export const AddButton = (props: AddButtonProps) => {
    const { label = 'Add New', icon = PLUS, handleClick } = props;
    return (
        <Button variant="outline-primary absolute-button" onClick={handleClick}>
            <img src={icon} alt="add icon" />
            {label}
        </Button>
    );
};

export const SubmitButton = (props: SubmitButtonProps) => {
    const {
        isDisable,
        isLoading,
        label,
        customClass = '',
        loadingLabel,
        ref,
    } = props;
    return (
        <Button
            type="submit"
            className={customClass}
            disabled={isDisable}
            variant="primary"
            ref={ref}
        >
            {isLoading ? loadingLabel || ButtonLabel.LOADING : label}
        </Button>
    );
};

export const CustomButton = (props: CustomButtonProps) => {
    const {
        isDisable,
        isLoading,
        label,
        customClass = '',
        handleClick,
        loadingLabel,
        isdanger,
    } = props;
    return (
        <Button
            variant={isdanger ? 'danger' : 'primary'}
            className={customClass}
            onClick={handleClick}
            disabled={isDisable}
        >
            {isLoading ? loadingLabel || ButtonLabel.LOADING : label}
        </Button>
    );
};

export const DangerLink = (props: DangerLinkProps) => {
    return (
        <div className="text-center">
            <Button variant="link" onClick={props.handleClick}>
                {props.label}
            </Button>
        </div>
    );
};
