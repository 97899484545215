import { Accordion } from 'react-bootstrap';
import { NoDataEnums } from '../../../shared/constants/html.constant';
import Loader from '../../../shared/elements/Loader';
import useEventDetails from '../hooks/useEventDetails';
import AccordianCard from '../../businessInfo/elements/AccordianCard';
import { routeObj } from '../../../shared/constants/routes.constants';
import { CustomButton } from '../../../shared/elements/Buttons';
import BackWithText from '../../../shared/elements/BackWithText';
import { EventActionType } from '../../../shared/constants/application.constant';

const EventDetails = () => {
    const {
        eventDetails,
        selectedEventLevels,
        onChangeDetail,
        onSubmitDetails,
        isLoadingGetEventDetails,
        isLoadingGetEventLevel3,
        isLoading,
        state,
    } = useEventDetails();

    if (isLoadingGetEventDetails || isLoadingGetEventLevel3) {
        return <Loader />;
    }

    return (
        <>
            <BackWithText
                to={routeObj.BUSINESS_EVENT_CREATE}
                label="Set Event Uniqueness"
                state={{
                    id: state?.id,
                    type: EventActionType.EDIT,
                }}
            />
            <Accordion alwaysOpen>
                {eventDetails?.map((details, index) => (
                    <AccordianCard
                        details={details}
                        key={details.id}
                        selectedLevels={selectedEventLevels}
                        onChangeDetail={onChangeDetail}
                    />
                ))}
            </Accordion>
            {eventDetails.length ? (
                <div className="button-footer">
                    <CustomButton
                        label={'Publish Event'}
                        isDisable={isLoading}
                        isLoading={isLoading}
                        handleClick={onSubmitDetails}
                        customClass="medium"
                    />
                </div>
            ) : (
                <p>{NoDataEnums.NO_RESULT_FOUND}</p>
            )}
        </>
    );
};

export default EventDetails;
