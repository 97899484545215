import { initializeApp } from 'firebase/app';
import {
    deleteToken,
    getMessaging,
    getToken,
    onMessage,
} from 'firebase/messaging';
import { firebaseConfig, vapidKey } from './firebaseConfig';
import { RegisterFCMToken } from './NotificationApi';
import { ConstNumber } from '../constants/number.constant';
import { localStorageKeys } from '../constants/application.constant';
import Toast from '../utils/toast.helper';
import { useAppDispatch } from '../../../store/hooks';
import { resetRedirect, setRedirect } from '../slices/slice.notification';

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
let timeout;

export const getTokenFunction = () => {
    return getToken(messaging, {
        vapidKey: vapidKey,
    })
        .then((currentToken) => {
            if (currentToken) {
                if (localStorage.getItem(localStorageKeys.USER)) {
                    RegisterFCMToken(currentToken);
                }
            }
        })
        .catch((err) => {});
};

export const getRegistrationToken = () => {
    return localStorage.getItem(localStorageKeys.FCM_TOKEN);
};

initializeApp(firebaseConfig);

export const onMessageListener = (reloadFunction) =>
    new Promise((resolve) => {
        const dispatch = useAppDispatch();

        onMessage(messaging, (payload) => {
                        reloadFunction && reloadFunction();
            resolve(payload);
            dispatch(setRedirect());
            Toast.info(
                payload.data.title,
                payload.data.body
            );
            timeout = setTimeout(
                () => dispatch(resetRedirect()),
                ConstNumber.VALUE_2500
            );
        });
    });

export const removeToken = async () => {
    deleteToken(messaging);
};
