import { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventUploadSchema } from '../utils/utils';
import { EventUploadRequest } from '../constants/types.events';
import { useEventUploadMutation } from '../slices/slice.events';

export function useUploadEvents() {
    const {
        handleSubmit,
        formState: { errors, isValid },
        control,
        reset,
    } = useForm({
        defaultValues: {
            document: undefined,
        },
        resolver: yupResolver(eventUploadSchema),
        mode: 'onChange',
    });
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [
        eventUpload,
        { isLoading, error: apiUploadError, data: uploadEventResponse },
    ] = useEventUploadMutation();

    const onSubmitForm = async (values: EventUploadRequest) => {
        const formData = new FormData();
        formData.append('document', values.document[0]);
        await eventUpload(formData);
    };

    return {
        control,
        errors,
        isValid,
        handleSubmit,
        onSubmitForm,
        reset,
        isLoading,
        apiUploadError,
        uploadEventResponse,
        fileInputRef,
    };
}
