import { apiUrlShared } from '../constants/apiUrl.constant';
import { UserRole, localStorageKeys } from '../constants/application.constant';
import { getToken } from '../services/interceptor.service';
import { getMachineId } from '../utils/helper';
import { RegisterDeviceRequest } from '../utils/types.elements';

export const RegisterFCMToken = async (token: string) => {
    const request: RegisterDeviceRequest = {
        device_id: getMachineId(),
        device_type: 'web',
        registration_id: token,
        role: UserRole.BUSINESS,
    };
    const accessToken = (await getToken()).access_token;
    fetch(
        `${process.env.REACT_APP_BASE_API_URL}${apiUrlShared.DEVICE_REGISTER}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
                // You can add other headers if required, such as authorization headers
            },
            body: JSON.stringify(request),
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            localStorage.setItem(localStorageKeys.FCM_TOKEN, token);
            return response.json();
        });
};
