import * as yup from 'yup';
import { ConstNumber } from '../../../shared/constants/number.constant';
import {
    Bool,
    CognitoExceptions,
    CommonEnum,
    UserRole,
    ValidationEnums,
} from '../../../shared/constants/application.constant';
import {
    EMAIL_REGEX,
    NUMBER_REGEX,
    PASSWORD_REGEX,
    PHONE_REGEXP,
} from '../../../shared/constants/regex.constant';
import Toast from '../../../shared/utils/toast.helper';
import { ToastEnum } from '../../../shared/constants/html.constant';

export function getSignupIntialValues() {
    return {
        address: '',
        phone_number: '',
        name: '',
        email: '',
        password: '',
        checkBox: false,
        referral_code: '',
        place: undefined,
        newsletter_enabled: true,
    };
}

export function getSignInIntialValues() {
    return {
        username: '',
        password: '',
    };
}

export const signupFormSchema = yup.object().shape({
    name: yup
        .string()
        .max(ConstNumber.VALUE_150, ValidationEnums.MAX_150)
        .required(ValidationEnums.REQUIRED),
    address: yup.string().required(ValidationEnums.CHOOSE_ADDRESS),
    phone_number: yup
        .string()
        .required(ValidationEnums.REQUIRED)
        .matches(PHONE_REGEXP, ValidationEnums.INVALID_MOBILE),
    email: yup
        .string()
        .required(ValidationEnums.REQUIRED)
        .matches(EMAIL_REGEX, ValidationEnums.EMAIL_FORMAT),
    password: yup
        .string()
        .required(ValidationEnums.REQUIRED)
        .matches(PASSWORD_REGEX, ValidationEnums.INCORRECT_PASSWORD)
        .max(ConstNumber.VALUE_14, ValidationEnums.MAX_14),
    place: yup.object().optional(),
    checkBox: yup
        .boolean()
        .oneOf([true], '')
        .required(ValidationEnums.REQUIRED),
    newsletter_enabled: yup.boolean().required(ValidationEnums.REQUIRED),
    referral_code: yup.string().max(ConstNumber.VALUE_6, ValidationEnums.MAX_6),
});

export const signinFormSchema = yup.object().shape({
    username: yup
        .string()
        .email(ValidationEnums.EMAIL_FORMAT)
        .required(ValidationEnums.REQUIRED)
        .matches(EMAIL_REGEX, ValidationEnums.EMAIL_FORMAT),
    password: yup.string().required(ValidationEnums.REQUIRED),
});

export function getForgotPasswordIntialValues() {
    return {
        email: '',
    };
}

export const ForgotPasswordFormSchema = yup.object().shape({
    email: yup
        .string()
        .email(ValidationEnums.EMAIL_FORMAT)
        .matches(EMAIL_REGEX, ValidationEnums.EMAIL_FORMAT)
        .required(ValidationEnums.REQUIRED),
});

export function getResetPasswordIntialValues() {
    return {
        otp: '',
        password: '',
    };
}

export const ResetPasswordFormSchema = yup.object().shape({
    otp: yup
        .string()
        .length(ConstNumber.VALUE_6, ValidationEnums.OTP_MATCH)
        .matches(NUMBER_REGEX, ValidationEnums.OTP_NUMERIC)
        .required(ValidationEnums.REQUIRED),
    password: yup
        .string()
        .matches(PASSWORD_REGEX, ValidationEnums.INCORRECT_PASSWORD)
        .max(ConstNumber.VALUE_14, ValidationEnums.MAX_14)
        .required(ValidationEnums.REQUIRED),
});

export const VerifiedOTPSchema = yup.object().shape({
    otp: yup
        .string()
        .length(ConstNumber.VALUE_6, ValidationEnums.OTP_MATCH)
        .matches(NUMBER_REGEX, ValidationEnums.OTP_NUMERIC)
        .required(ValidationEnums.REQUIRED),
});

export const BusinessTypeSchema = yup.object().shape({
    level1_id: yup.number().required(ValidationEnums.REQUIRED),
});

export const setRole = (
    role: string,
    isAuthAllowed: boolean,
    isProfileCompleted: boolean,
    isSubscribed: boolean
) => {
    return isAuthAllowed
        ? isProfileCompleted && isSubscribed
            ? role
            : ''
        : role;
};

export const checkProfile = (completed: string) => {
    return completed === Bool.true;
};

export const checkSubscribed = (isSubscribed: string, role: string) => {
    if (role === UserRole.SUPER_ADMIN || role === UserRole.SUB_ADMIN) {
        return true;
    }
    return isSubscribed === Bool.true;
};

export const getMonth = (period?: number | null) => {
    if (period) {
        switch (period) {
            case ConstNumber.VALUE_1:
                return `${CommonEnum.ONE_MONTH}`;
            case ConstNumber.VALUE_2:
                return `${CommonEnum.TWO_MONTH}`;
            case ConstNumber.VALUE_3:
                return `${CommonEnum.THREE_MONTH}`;
            default:
                return `${CommonEnum.FEW_MONTH}`;
        }
    }
    return `${CommonEnum.ONE_MONTH}`;
};

export const showLoginError = (_error: any) => {
    if (_error.code === CognitoExceptions.USER_DEACTIVATED) {
        Toast.error(
            ToastEnum.SOMETHING_WENT_WRONG,
            ToastEnum.INCORRECT_EMAIL_PASSWORD
        );
    } else {
        Toast.error(ToastEnum.SOMETHING_WENT_WRONG, _error.message);
    }
};
