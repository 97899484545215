import { Tabs, Tab } from 'react-bootstrap';
import { ICustomTabsProps } from '../utils/types.elements';

const CustomTabs = (props: ICustomTabsProps) => {
    const { defaultActiveKey, activeTab, handleTabClick, tabs } = props;

    return (
        <Tabs
            defaultActiveKey={defaultActiveKey}
            id="special-tab"
            activeKey={activeTab as string}
            onSelect={(tab) => handleTabClick(tab)}
        >
            {tabs.map((tab) => {
                return (
                    <Tab
                        key={tab.eventKey}
                        eventKey={tab.eventKey}
                        title={tab.title}
                    >
                        {tab.eventKey === activeTab ? tab.content : <div></div>}
                    </Tab>
                );
            })}
        </Tabs>
    );
};

export default CustomTabs;
