import * as yup from 'yup';
import {
    EntertainmentTypeEnum,
    TabKeys,
    ValidationEnums,
} from '../../../shared/constants/application.constant';
import { ConstNumber } from '../../../shared/constants/number.constant';
import { Option } from '../../../shared/utils/types.elements';
import {
    CategoryLevelOne,
    CategoryLevelOneRequest,
    CategoryLevelOneResponse,
    CategoryLevelThreeFormRequest,
    CategoryLevelTwoFormRequest,
    CategoryLevelTwoRequest,
    CategorySubLevelThreeFormRequest,
    EntertainmentTypeResponse,
} from '../constants/types.categories';

export const getCreateLevelOneIntialValues = (): CategoryLevelOneRequest => {
    return {
        name: '',
        entertainment_type: '',
    };
};

export const createLevelOneSchema = yup.object().shape({
    name: yup
        .string()
        .max(ConstNumber.VALUE_30, ValidationEnums.MAX_30)
        .required(ValidationEnums.REQUIRED),
    entertainment_type: yup.string().required(ValidationEnums.REQUIRED),
});

export const getCreateLevelTwoIntialValues =
    (): CategoryLevelTwoFormRequest => {
        return {
            name: '',
            value: '',
        };
    };

export const createLevelTwoSchema = yup.object().shape({
    name: yup
        .string()
        .max(ConstNumber.VALUE_30, ValidationEnums.MAX_30)
        .required(ValidationEnums.REQUIRED),
    value: yup
        .string()
        .max(ConstNumber.VALUE_30, ValidationEnums.MAX_30)
        .required(ValidationEnums.REQUIRED),
});

export const getCreateLevelThreeIntialValues = (
    id: number
): CategoryLevelThreeFormRequest => {
    return {
        heading: '',
        level_2: id,
    };
};

export const createLevelThreeSchema = yup.object().shape({
    heading: yup
        .string()
        .max(ConstNumber.VALUE_30, ValidationEnums.MAX_30)
        .required(ValidationEnums.REQUIRED),
    level_2: yup.number().required(ValidationEnums.REQUIRED),
});

export const getCreateSubLevelThreeIntialValues =
    (): CategorySubLevelThreeFormRequest => {
        return {
            id: undefined,
            name: '',
            heading: null,
            icon: null,
        };
    };

export const createSubLevelThreeSchema = yup.object().shape({
    id: yup.number().optional(),
    name: yup
        .string()
        .max(ConstNumber.VALUE_30, ValidationEnums.MAX_30)
        .required(ValidationEnums.REQUIRED),
    heading: yup.number().required(ValidationEnums.REQUIRED).nullable(),
});

export const getLevelTwoOptions = (
    getAllCategoryLevelTwo: CategoryLevelOneResponse
): Option[] => {
    return getAllCategoryLevelTwo?.data?.map((levelTwo) => {
        return {
            value: levelTwo.id,
            label: levelTwo.name,
        };
    });
};

export const getEntertainmentOptionsOptions = (
    getEntertainmentTypes: EntertainmentTypeResponse,
    activeTab?: string | null
): Option[] => {
    const typeEvent =
        activeTab === TabKeys.BUSINESS_CATEGORIES
            ? EntertainmentTypeEnum.BUSINESS
            : EntertainmentTypeEnum.EVENT;
    const filteredTypes = getEntertainmentTypes?.entertainment_type.filter(
        (type) => type.entertainment_type === typeEvent
    );
    return filteredTypes.map((filteredType) => {
        return {
            value: filteredType.id,
            label: filteredType.name,
        };
    });
};

export const getEntertainmentType = (activeTab?: string | null) => {
    return {
        entertainment_type:
            activeTab === TabKeys.BUSINESS_CATEGORIES
                ? EntertainmentTypeEnum.BUSINESS
                : EntertainmentTypeEnum.EVENT,
    };
};

export const getRequestData = (
    selectedCategoryLevelTwo: CategoryLevelOne | null,
    values: CategoryLevelTwoFormRequest
): CategoryLevelTwoRequest => {
    const requestData: CategoryLevelTwoRequest = {
        level_1: selectedCategoryLevelTwo?.id,
    };
    if (values.name === values.value) {
        requestData.name = values.value;
    } else {
        requestData.level_2 = Number(values.value);
    }
    return requestData;
};

export const getStateData = (
    value: CategoryLevelOne | null,
    selectedCategory: CategoryLevelOne | null,
    activeTab?: string | null
) => {
    return {
        id: value?.id,
        name: value?.name,
        level_one: selectedCategory,
        active_tab: activeTab,
    };
};

export const getDeleteData = (
    deletedCategory: CategoryLevelOne,
    isLevelOne: boolean,
    selectedCategory: CategoryLevelOne | null
) => {
    return {
        id: deletedCategory.id,
        type: isLevelOne,
        levelOneId: selectedCategory?.id,
    };
};

export const setLevelTwo = (selected: Option, setValueLevelTwo: any) => {
    if (selected) {
        setValueLevelTwo('name', selected.label);
        setValueLevelTwo('value', selected.value.toString());
    } else {
        setValueLevelTwo('name', '');
        setValueLevelTwo('value', '');
    }
};
