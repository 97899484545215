import { Button, Col, Form } from 'react-bootstrap';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { DateType } from '../../modules/specials/utils/types.specials';
import { DateFormats } from '../constants/application.constant';
import { OPENHOURS } from '../constants/data.constant';
import { CLOSE } from '../constants/image.constant';
import { ConstNumber } from '../constants/number.constant';
import { Option, TimeSlot } from '../utils/types.elements';
import { CustomDatePicker } from './CustomInput';
import MultiSelect from './MultiSelect';

interface IOpenHoursProps {
    daysSelected: Option[];
    handleDaysChange: (days: Option[]) => void;
    handleTimeChange: (date: Date | null, dateType: DateType | string) => void;
    register: UseFormRegister<any>;
    watch: UseFormWatch<any>;
    errorMsgStartTime?: string;
    errorMsgEndTime?: string;
    onClickSaveSlot: () => void;
    start_time?: Date | null;
    end_time?: Date | null;
    startAtName?: string;
    endAtName?: string;
    timeSlot: TimeSlot[];
    isOptionDisabled: (option: Option) => boolean;
    removeTimeSlot: (id: number) => void;
}

const OpenHours = (props: IOpenHoursProps) => {
    const {
        daysSelected,
        handleDaysChange,
        handleTimeChange,
        register,
        watch,
        errorMsgStartTime,
        errorMsgEndTime,
        onClickSaveSlot,
        start_time,
        end_time,
        timeSlot,
        isOptionDisabled,
        removeTimeSlot,
        startAtName = 'start_time',
        endAtName = 'end_time',
    } = props;

    return (
        <>
            <Col md={12}>
                <h4 className="inner-header">Open Hours</h4>
            </Col>
            <Col md={6}>
                <Form.Group className="form-group" controlId="opening_hours">
                    <MultiSelect
                        options={OPENHOURS}
                        label="Select Days"
                        isSearchable={false}
                        isMulti
                        isClearable={false}
                        isSelectAll={true}
                        value={daysSelected}
                        changeHandler={handleDaysChange}
                        isOptional
                        isOptionDisabled={isOptionDisabled}
                        placeholder="Select Days"
                    />
                </Form.Group>
            </Col>
            <Col md={3}>
                <Form.Group className="form-group" controlId="">
                    <Form.Label>Starts At</Form.Label>
                    <CustomDatePicker
                        name={startAtName}
                        timeCaption="Time"
                        timeIntervals={ConstNumber.VALUE_15}
                        dateFormat={DateFormats.TIME_WITH_MARIDIAN}
                        register={register}
                        watch={watch}
                        handleDateChange={handleTimeChange}
                        errorMsg={errorMsgStartTime}
                        showTimeSelect
                        showTimeSelectOnly
                        placeholderText="Starts At"
                    />
                </Form.Group>
            </Col>
            <Col md={3}>
                <Form.Group className="form-group" controlId="">
                    <Form.Label>Closes At</Form.Label>
                    <CustomDatePicker
                        name={endAtName}
                        timeCaption="Time"
                        timeIntervals={ConstNumber.VALUE_15}
                        dateFormat={DateFormats.TIME_WITH_MARIDIAN}
                        register={register}
                        watch={watch}
                        handleDateChange={handleTimeChange}
                        errorMsg={errorMsgEndTime}
                        showTimeSelect
                        showTimeSelectOnly
                        placeholderText="Closes At"
                    />
                </Form.Group>
            </Col>
            <Col md={12} className="add-slot-btn">
                <Button
                    variant="link"
                    className="add-slot"
                    onClick={onClickSaveSlot}
                    disabled={
                        Boolean(errorMsgEndTime) ||
                        !daysSelected.length ||
                        !start_time ||
                        !end_time
                    }
                >
                    Save & Add Slot
                </Button>
            </Col>
            <Col md={12}>
                <div className="chip-outer-container">
                    {timeSlot.map((slot, index) => (
                        <div className="chip" key={slot.value}>
                            {`${slot.slug} (${slot.start_time} to ${slot.end_time})`}
                            <img
                                src={CLOSE}
                                alt="Close"
                                className="close"
                                onClick={() =>
                                    removeTimeSlot(Number(slot.value))
                                }
                            />
                        </div>
                    ))}
                </div>
            </Col>
        </>
    );
};

export default OpenHours;
