import { routeObj } from '../../../shared/constants/routes.constants';
import { LOGO, HAND } from '../../../shared/constants/image.constant';
import { Form } from 'react-bootstrap';
import { Amplify } from 'aws-amplify';
import AMPLIFY_CONFIG from '../../../shared/services/cognito.amplify';
import { Link } from 'react-router-dom';
import { useLogin } from '../hooks/useLogin';
import CustomInput, {
    PasswordInput,
} from '../../../shared/elements/CustomInput';
import VerificationOTP from './VerificationOTP';
import { SubmitButton } from '../../../shared/elements/Buttons';
import { ButtonLabel } from '../../../shared/constants/html.constant';

Amplify.configure(AMPLIFY_CONFIG);

const Login = () => {
    const {
        errors,
        handleSubmit,
        isValid,
        onSubmitForm,
        register,
        isDataLoading,
        trigger,
        showOtp,
        setShowOtp,
        getValues,
    } = useLogin();

    return (
        <>
            {/* sign in container */}
            <div className="login-inner">
                {showOtp ? (
                    <VerificationOTP
                        isSignIn={true}
                        setShowOtp={setShowOtp}
                        username={getValues('username')}
                    />
                ) : (
                    <>
                        <img src={LOGO} alt="Logo" className="logo" />
                        <h1 className="login-title">
                            Hi, Welcome! <img src={HAND} alt="Hand" />
                        </h1>
                        <Form
                            onSubmit={handleSubmit(onSubmitForm)}
                            className="login-form"
                        >
                            <CustomInput
                                label="Email ID"
                                type="email"
                                name={'username'}
                                placeholder="Email ID"
                                errorMsg={errors.username?.message}
                                onBlur={() => trigger('username')}
                                register={register}
                            />
                            <PasswordInput
                                label="Password*"
                                type="password"
                                name={'password'}
                                placeholder="Password"
                                register={register}
                                errorMsg={errors.password?.message}
                                onBlur={() => trigger('password')}
                                formGroupClass="form-group input-with-icon"
                                required
                            />

                            <div className="forgot-container">
                                <Link
                                    to={routeObj.FORGOT_PASSWORD}
                                    title="Forgot Password?"
                                >
                                    Forgot Password?
                                </Link>
                            </div>
                            <div className="btn-container">
                                <SubmitButton
                                    isDisable={!isValid || isDataLoading}
                                    isLoading={isDataLoading}
                                    loadingLabel={ButtonLabel.SIGNIN_IN}
                                    label={ButtonLabel.SIGN_IN}
                                />
                            </div>
                        </Form>
                        <div className="signin-bottom">
                            Don’t have an account yet?
                            <Link to={routeObj.SIGN_UP} title="Sign up">
                                Sign up
                            </Link>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Login;
