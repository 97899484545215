import React, { useState } from 'react';
import { PLAYBUTTON } from '../constants/image.constant';
import ReactPlayer from 'react-player';
import { NoDataEnums } from '../constants/html.constant';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';

interface Props {
    videoSrc: string;
    videoTitle?: string;
    thumbnail?: string;
    id?: string;
    disableDetail?: boolean;
}

const VideoPlayer: React.FC<Props> = ({
    videoSrc,
    videoTitle,
    thumbnail,
    id,
    disableDetail = true,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="video-container">
            <span
                role="button"
                className={`cursor-pointer ${
                    !thumbnail ? 'play-container' : ''
                }`}
                onClick={() => disableDetail && setIsOpen(true)}
            >
                <img
                    height={48}
                    width={48}
                    className="play-icon"
                    src={PLAYBUTTON}
                    alt="play icon"
                    id={id}
                />
                {thumbnail ? (
                    <img
                        className="full-width"
                        height="100%"
                        width="100%"
                        src={thumbnail}
                        alt="thumbnail"
                        id={id}
                    />
                ) : (
                    <video src={videoSrc} height="275" width="100%" />
                )}
            </span>
            {isOpen && (
                <Modal
                    show={isOpen}
                    className="video-player"
                    size="lg"
                    centered
                >
                    <ModalHeader
                        closeButton
                        onClick={() => setIsOpen(false)}
                        className="big-header"
                    >
                        <h2>{videoTitle}</h2>
                    </ModalHeader>
                    <ModalBody>
                        <div className="video-wrap">
                            <ReactPlayer
                                url={videoSrc}
                                width="100%"
                                height="auto"
                                controls
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: 'nodownload',
                                            disablePictureInPicture: true,
                                        },
                                    },
                                }}
                                playing
                                playsinline
                            >
                                {NoDataEnums.BROWSER_DOES_NOT_SUPPORT}
                            </ReactPlayer>
                        </div>
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
};

export default React.memo(VideoPlayer);
