import React from 'react';
import PaymentPlanFeatures from '../../../shared/elements/PaymentPlanFeatures';
import Note from './Note';
import SubscriptionPrice from '../../../shared/elements/SubscriptionPrice';
import { PromoCodeType } from '../../../shared/constants/application.constant';

type Props = {
    name?: string;
    price?: string;
    discountedPrice?: number;
    description?: string;
    features?: string;
    children?: React.ReactNode;
    trialMonths?: number | null; // containg valid months for the offer
};

const SubscriptionPlanCard = (props: Props) => {
    const {
        children,
        description,
        price,
        discountedPrice,
        features,
        name,
        trialMonths,
    } = props;
    return (
        <div className="subs-container">
            <div className="heading">{name}</div>
            <SubscriptionPrice
                discountedPrice={discountedPrice as number}
                originalPrice={price || ''}
                showDiscount={Boolean(price !== discountedPrice)}
            />
            {trialMonths ? (
                <Note
                    promotionPlanType={PromoCodeType.TRAIL_ONBOARD}
                    trialMonths={trialMonths}
                />
            ) : Boolean(price !== discountedPrice) ? (
                <Note
                    promotionPlanType={PromoCodeType.DISCOUNT_ONBOARD}
                    trialMonths={trialMonths}
                />
            ) : null}
            <p>{description}</p>
            <div className="divider"></div>
            <PaymentPlanFeatures features={features} />
            <div className="text-center">{children}</div>
        </div>
    );
};

export default SubscriptionPlanCard;
