import { Button, Modal } from 'react-bootstrap';
import { CustomButton } from './Buttons';

interface IConfirmationPopup {
    handleClose: () => void;
    onConfirm: () => void;
    visible: boolean;
    heading: string;
    subHeading: string;
    CloseBtnText: string;
    ConfirmBtnText: string;
    isLoading?: boolean;
    loadingLabel?: string;
    isdanger?: boolean;
}

export const ConfirmationPopup = (props: IConfirmationPopup) => {
    const {
        handleClose,
        onConfirm,
        visible,
        heading,
        subHeading,
        CloseBtnText,
        ConfirmBtnText,
        isLoading = false,
        loadingLabel,
        isdanger,
    } = props;

    return (
        <Modal show={visible} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p
                    dangerouslySetInnerHTML={{ __html: subHeading }}
                    className="text-center"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={handleClose}>
                    {CloseBtnText}
                </Button>
                <CustomButton
                    label={ConfirmBtnText}
                    isDisable={isLoading}
                    isLoading={isLoading}
                    handleClick={onConfirm}
                    loadingLabel={loadingLabel}
                    isdanger={isdanger}
                />
            </Modal.Footer>
        </Modal>
    );
};
