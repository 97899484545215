import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    getPromotionFormIntialValues,
    promotionFormSchema,
} from '../utils/util';
import { yupResolver } from '@hookform/resolvers/yup';
import { PromotionForm, PromotionRequest } from '../utils/types.promotion';
import { FormParams, Option } from '../../../shared/utils/types.elements';
import { useGetMetaDataQuery } from '../../../modules/advertising/slices/slice.advertising';
import {
    ErrorObj,
    SubscriptionPromotionPlans,
} from '../../../shared/types/type';
import {
    DiscountCategoryEnum,
    PromotionTypeEnum,
} from '../../../shared/constants/application.constant';
import { useCreatePromotionMutation } from '../slices/slice.promotion';
import { ToastEnum } from '../../../shared/constants/html.constant';
import Toast from '../../../shared/utils/toast.helper';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeObj } from '../../../shared/constants/routes.constants';

export function usePromotion() {
    const params = useLocation().state as FormParams;
    const navigate = useNavigate();
    const [subscriptionTypes, setSubscriptionTypes] = useState<
        SubscriptionPromotionPlans[]
    >([]);
    const [advertisementTypes, setAdvertisementTypes] = useState<
        SubscriptionPromotionPlans[]
    >([]);
    const [planTypes, setPlanTypes] = useState<Option[]>([]);
    const [selectedPlanType, setSelectedPlanType] = useState<Option | null>(
        null
    );
    const [selectedType, setSelectedType] =
        useState<SubscriptionPromotionPlans | null>(null);
    const [selectedMonth, setSelectedMonth] = useState<Option | null>(null);
    const {
        handleSubmit,
        register,
        formState: { errors, isValid, isSubmitting },
        setValue,
        getValues,
        trigger,
        watch,
        control,
    } = useForm({
        defaultValues: getPromotionFormIntialValues(params),
        resolver: yupResolver(promotionFormSchema),
        mode: 'onChange',
    });
    const {
        data: metaData,
        isLoading: isLoadingMeta,
        refetch,
    } = useGetMetaDataQuery({});
    const [
        createPromotion,
        {
            isLoading: isLoadingCreate,
            error: apiErrorCreate,
            data: createResponse,
        },
    ] = useCreatePromotionMutation();
    const promotionType = watch('promotion_type');
    const discount = watch('discount');
    const validMonths = watch('valid_months');

    useEffect(() => {
        if (metaData?.subscription_promotion_plan_type.length) {
            setSubscriptionTypes(metaData.subscription_promotion_plan_type);
            promotionType === PromotionTypeEnum.SUBSCRIPTION &&
                getPromotionPlanType(metaData.subscription_promotion_plan_type);
        }
        if (metaData?.advertisement_promotion_plan_type.length) {
            setAdvertisementTypes(metaData.advertisement_promotion_plan_type);
            promotionType === PromotionTypeEnum.ADVERTISEMENT &&
                getPromotionPlanType(
                    metaData.advertisement_promotion_plan_type
                );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metaData]);

    useEffect(() => {
        if (subscriptionTypes.length && advertisementTypes.length) {
            getPromotionPlanType(
                promotionType === PromotionTypeEnum.SUBSCRIPTION
                    ? subscriptionTypes
                    : advertisementTypes
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promotionType]);

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (createResponse?.message) {
            Toast.success(ToastEnum.PROMOTION_CREATED, createResponse?.message);
            navigate(routeObj.ADMIN_PROMOTION_CODE, {
                state: {
                    activeTab: params?.activeTab,
                },
            });
        } else if (apiErrorCreate) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (apiErrorCreate as ErrorObj)?.message
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createResponse?.message, apiErrorCreate]);

    const getPromotionPlanType = (planValues: SubscriptionPromotionPlans[]) => {
        const plansData: Option[] = planValues.map((target) => {
            return {
                value: target.id,
                label: target.name,
            };
        });
        setPlanTypes(plansData);
        setSelectedPlanType(null);
        setSelectedType(null);
        setSelectedMonth(null);
        setValue('promotion_plan_type', '');
        setValue('valid_months', '');
    };

    const handleChange = (selected: Option) => {
        setSelectedPlanType(selected);
        const plansData =
            promotionType === PromotionTypeEnum.SUBSCRIPTION
                ? subscriptionTypes
                : advertisementTypes;
        const planData = plansData.find((plan) => plan.id === selected.value);
        planData && setSelectedType(planData);
        setSelectedMonth(null);
        setValue('valid_months', '');
        setValue('discount', '');
        setValue('promotion_plan_type', selected.value.toString());
        trigger('promotion_plan_type');
    };

    const handleMonths = (selected: Option) => {
        setSelectedMonth(selected);
        setValue('valid_months', selected.value.toString());
        trigger('valid_months');
    };

    const onSubmitForm = useCallback(
        async (values: PromotionForm): Promise<void> => {
            const requestData: PromotionRequest = {
                name: values.name,
                description: values.description,
                email: values.email || undefined,
                promotion_type: values.promotion_type,
                promotion_plan_type: Number(values.promotion_plan_type),
            };
            if (
                selectedType?.discount_category ===
                    DiscountCategoryEnum.MONTHLY &&
                values.valid_months
            ) {
                requestData.valid_months = Number(values.valid_months);
            } else if (
                selectedType?.discount_category ===
                    DiscountCategoryEnum.PERCENTAGE &&
                values.discount
            ) {
                requestData.discount = Number(values.discount);
            }
            await createPromotion(requestData);
        },
        [selectedType]
    );

    const isDiscountMonthRequired = () => {
        let isRequired = false;
        if (
            (selectedType?.discount_category === DiscountCategoryEnum.MONTHLY &&
                !validMonths) ||
            (selectedType?.discount_category ===
                DiscountCategoryEnum.PERCENTAGE &&
                !discount)
        ) {
            isRequired = true;
        }
        return isRequired;
    };

    return {
        register,
        handleSubmit,
        onSubmitForm,
        setValue,
        trigger,
        watch,
        errors,
        isValid,
        getValues,
        isLoading: isSubmitting || isLoadingCreate,
        control,
        planTypes,
        handleChange,
        selectedPlanType,
        handleMonths,
        selectedMonth,
        selectedType,
        isDiscountMonthRequired,
    };
}
