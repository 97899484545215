import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { reducerEnums } from '../constants/enums.onBoarding';
import {
    signUpWithCognito,
    signIn,
    confirmSignUp,
    signOutWithCognito,
    resendConfirmationCode,
    forgotPasswordCognito,
    resetPasswordCognito,
    changePasswordCognito,
} from '../../../shared/utils/aws.methods';
import {
    SignInParameters,
    ConfirmSignUpParameters,
    ResendConfCodeParameters,
    ConfirmResetParameters,
    ChangePassword,
} from '../../../shared/utils/types.elements';

interface State {
    data: {};
    isDataLoading: boolean;
    username: string;
    showOTP: boolean;
    code: string;
    promo_id?: number;
    card_required: boolean;
}

export const initialState: State = {
    data: {},
    isDataLoading: false,
    username: '',
    showOTP: false,
    card_required: false,
    code: '',
};

export const signUpUser = createAsyncThunk(
    reducerEnums.SIGN_UP,
    async (params: any, { rejectWithValue }) => {
        try {
            return signUpWithCognito(params);
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const signInUser = createAsyncThunk(
    reducerEnums.SIGN_IN,
    async (params: SignInParameters, { rejectWithValue }) => {
        try {
            return signIn(params);
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const verifyOTP = createAsyncThunk(
    reducerEnums.VERIFY_OTP,
    async (
        { username, code }: ConfirmSignUpParameters,
        { rejectWithValue }
    ) => {
        try {
            return confirmSignUp({
                code,
                username,
            });
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const resendOTP = createAsyncThunk(
    reducerEnums.RESEND_OTP,
    async ({ username }: ResendConfCodeParameters, { rejectWithValue }) => {
        try {
            return resendConfirmationCode({
                username,
            });
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const signOutUser = createAsyncThunk(reducerEnums.SIGN_OUT, async () => {
    return signOutWithCognito();
});

export const forgotPassword = createAsyncThunk(
    reducerEnums.FORGOT_PASSWORD,
    async ({ username }: ResendConfCodeParameters, { rejectWithValue }) => {
        try {
            return forgotPasswordCognito({ username });
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const resetPassword = createAsyncThunk(
    reducerEnums.RESET_PASSWORD,
    async (
        { username, code, password }: ConfirmResetParameters,
        { rejectWithValue }
    ) => {
        try {
            return resetPasswordCognito({
                code,
                username,
                password,
            });
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const changePassword = createAsyncThunk(
    reducerEnums.CHANGE_PASSWORD,
    async (values: ChangePassword, { rejectWithValue }) => {
        try {
            return changePasswordCognito(values);
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const slice = createSlice({
    name: reducerEnums.DATA,
    initialState,
    reducers: {
        selectCategory: (state, action) => {
            state.data = action.payload;
        },
        setShowOtp: (state, action) => {
            state.showOTP = action.payload;
        },
        setCouponCode: (state, action) => {
            state.code = action.payload.code;
            state.promo_id = action.payload.promoId;
            state.card_required = action.payload.cardRequired;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signUpUser.pending, (state) => {
                state.isDataLoading = true;
            })
            .addCase(signUpUser.fulfilled, (state, action) => {
                state.username = action.payload;
                state.showOTP = true;
                state.isDataLoading = false;
            })
            .addCase(signUpUser.rejected, (state) => {
                state.isDataLoading = false;
            })
            .addCase(verifyOTP.pending, (state) => {
                state.isDataLoading = true;
            })
            .addCase(verifyOTP.fulfilled, (state, action) => {
                state.isDataLoading = false;
                state.showOTP = false;
            })
            .addCase(verifyOTP.rejected, (state, action) => {
                state.isDataLoading = false;
            })
            .addCase(signInUser.pending, (state) => {
                state.isDataLoading = true;
            })
            .addCase(signInUser.fulfilled, (state, action) => {
                state.isDataLoading = false;
            })
            .addCase(signInUser.rejected, (state) => {
                state.isDataLoading = false;
            })
            .addCase(signOutUser.pending, (state) => {
                state.isDataLoading = true;
            })
            .addCase(signOutUser.fulfilled, (state, action) => {
                state.isDataLoading = false;
            })
            .addCase(signOutUser.rejected, (state) => {
                state.isDataLoading = false;
            })
            .addCase(forgotPassword.pending, (state) => {
                state.isDataLoading = true;
            })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                state.isDataLoading = false;
            })
            .addCase(forgotPassword.rejected, (state) => {
                state.isDataLoading = false;
            })
            .addCase(resetPassword.pending, (state) => {
                state.isDataLoading = true;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.isDataLoading = false;
            })
            .addCase(resetPassword.rejected, (state) => {
                state.isDataLoading = false;
            })
            .addCase(changePassword.pending, (state) => {
                state.isDataLoading = true;
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.isDataLoading = false;
            })
            .addCase(changePassword.rejected, (state) => {
                state.isDataLoading = false;
            });
    },
});

export const { selectCategory, setShowOtp, setCouponCode } = slice.actions;
export default slice.reducer;
