import TermsLink from './TermsLink';

const TermsAndCondition = () => {
    return (
        <span>
            I agree to the&nbsp;
            <TermsLink
                title="terms & conditions"
                link={process.env.REACT_APP_TERMS_AND_CONDITIONS}
            />
        </span>
    );
};

export default TermsAndCondition;
