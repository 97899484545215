import { Button, Form } from 'react-bootstrap';
import {
    Control,
    Controller,
    RegisterOptions,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
    ValidationRule,
} from 'react-hook-form';
import { ConstNumber } from '../constants/number.constant';
import ErrorMessage from './ErrorMessage';
import DatePicker from 'react-datepicker';
import { CLOCK, EYECLOSE, EYEOPEN } from '../constants/image.constant';
import { useRef, useState } from 'react';
import { formatMobileNumber } from '../utils/helper';
import {
    CommonEnum,
    Country,
    GooglePlaceFields,
} from '../constants/application.constant';
import 'react-datepicker/dist/react-datepicker.css';
import AutoCompleteDropdown from './AutoCompleteDropdown';

interface TextInputProps {
    label?: string;
    placeholder?: string;
    name: string;
    type?: string;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    register: UseFormRegister<any>;
    required?: RegisterOptions['required'];
    disabled?: boolean;
    pattern?: ValidationRule<RegExp>;
    min?: number;
    max?: number;
    customClass?: string;
    id?: string;
    errorMsg?: string;
    maxLength?: number;
    readOnly?: boolean;
    optional?: boolean;
    formGroupClass?: string;
    value?: string;
    requiredText?: string;
    duplicateTextClass?: string;
}

interface TextAreaProps extends TextInputProps {
    rows?: number;
    isClear?: boolean;
    onClickClear?: () => void;
    isClearDisabled?: boolean;
}

interface RadioInputProps<T> {
    radioClass?: string;
    options: T[];
    name: string;
    defaultValue?: number;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    mapOption: (option: T) => { label: string; value: string | number };
}

interface DatePickerProps {
    name: string;
    register: UseFormRegister<any>;
    icon?: string;
    timeCaption?: string;
    timeIntervals?: number;
    dateFormat?: string;
    showTimeSelect?: boolean;
    showTimeSelectOnly?: boolean;
    setValue?: UseFormSetValue<any>;
    watch: UseFormWatch<any>;
    disablePastDate?: boolean;
    handleDateChange: (date: Date | null, name: string) => void;
    errorMsg?: string;
    placeholderText?: string;
    isDisabled?: boolean;
}

interface PhoneInputProps extends TextInputProps {
    watch: UseFormWatch<any>;
}

const CustomInput: React.FC<TextInputProps> = ({
    register,
    name,
    type = 'text',
    required,
    label,
    placeholder,
    disabled,
    pattern,
    min,
    max,
    errorMsg,
    customClass = 'form-control',
    maxLength,
    onBlur,
    readOnly = false,
    optional,
    requiredText,
    duplicateTextClass,
    ...otherProps
}: TextInputProps) => {
    return (
        <Form.Group
            className={`${otherProps.formGroupClass || CommonEnum.FORM_CLASS}`}
            controlId={name}
        >
            <Form.Label>
                {label}
                {optional ? (
                    <span className="text-muted"> (Optional)</span>
                ) : requiredText ? (
                    <span
                        className={`${duplicateTextClass || ''}`}
                    >{` ${requiredText}`}</span>
                ) : (
                    '*'
                )}
            </Form.Label>
            <Form.Control
                placeholder={placeholder}
                type={type}
                className={customClass}
                {...register(name, { required, pattern, min, max })}
                onBlur={onBlur}
                maxLength={maxLength}
                readOnly={readOnly}
                isInvalid={Boolean(errorMsg)}
                disabled={disabled}
                {...otherProps}
            />
            {errorMsg && (
                <Form.Control.Feedback type="invalid">
                    {errorMsg}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
};

export const TextArea: React.FC<TextAreaProps> = ({
    name,
    rows = ConstNumber.VALUE_4,
    placeholder,
    label,
    register,
    required,
    disabled,
    pattern,
    max,
    min,
    errorMsg,
    maxLength,
    optional = true,
    isClear,
    onClickClear,
    isClearDisabled,
    ...otherProps
}) => {
    return (
        <Form.Group className="form-group" controlId={name}>
            <Form.Label>
                {label}{' '}
                {optional && <span className="text-muted">(Optional)</span>}
            </Form.Label>
            {isClear && (
                <Button
                    className="label-with-clear"
                    variant="link"
                    onClick={onClickClear}
                    disabled={isClearDisabled}
                >
                    Clear
                </Button>
            )}
            <Form.Control
                as="textarea"
                rows={rows}
                maxLength={maxLength}
                placeholder={placeholder}
                {...register(name, { required, disabled, pattern, min, max })}
                {...otherProps}
                isInvalid={Boolean(errorMsg)}
                onBlur={otherProps?.onBlur}
            />
            <div className="char-limit">Character limit: {maxLength}</div>
            {errorMsg && (
                <Form.Control.Feedback type="invalid">
                    {errorMsg}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
};

export const RadioInput = <T extends {}>({
    radioClass,
    options,
    name,
    defaultValue,
    handleChange,
    mapOption,
}: RadioInputProps<T>) => {
    return (
        <>
            {options?.map((option) => {
                const { label, value } = mapOption(option);
                return (
                    <div key={value} className={radioClass}>
                        <Form.Check
                            type="radio"
                            id={label}
                            label={label}
                            name={name}
                            value={value}
                            onChange={handleChange}
                            checked={defaultValue === value}
                        />
                    </div>
                );
            })}
        </>
    );
};

export const CheckBox = ({
    register,
    name,
    id,
    label = '',
    customClass = 'custom-checkbox small',
}: TextInputProps) => {
    return (
        <div className={customClass}>
            <Form.Check
                type={'checkbox'}
                id={id}
                {...register(name)}
                label={label}
            />
        </div>
    );
};

export const CustomDatePicker: React.FC<DatePickerProps> = ({
    name,
    icon = CLOCK,
    register,
    dateFormat,
    showTimeSelect,
    showTimeSelectOnly,
    timeCaption,
    timeIntervals,
    setValue,
    watch,
    disablePastDate,
    handleDateChange,
    errorMsg,
    placeholderText,
    isDisabled,
}) => {
    return (
        <label className="d-block">
            <div className="date-picker-wrapper">
                <DatePicker
                    {...register(name)}
                    name={name}
                    selected={watch(name)}
                    onChange={(date) => handleDateChange(date, name)}
                    className="form-control"
                    timeCaption={timeCaption}
                    timeIntervals={timeIntervals}
                    dateFormat={dateFormat}
                    showTimeSelect={showTimeSelect}
                    showTimeSelectOnly={showTimeSelectOnly}
                    minDate={disablePastDate ? new Date() : null}
                    placeholderText={placeholderText}
                    shouldCloseOnSelect={true}
                    disabled={isDisabled}
                />
                <img
                    src={icon}
                    alt="date-picker"
                    className="icon"
                    role={'button'}
                    height={24}
                    width={24}
                />
                {errorMsg && <ErrorMessage errorMsg={errorMsg} />}
            </div>
        </label>
    );
};

export const PasswordInput = ({
    label,
    name,
    placeholder,
    type,
    customClass,
    register,
    onBlur,
    maxLength,
    readOnly,
    errorMsg,
    disabled,
    required,
    pattern,
    min,
    max,
    optional,
    ...otherProps
}: TextInputProps) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Form.Group
            className={`${otherProps.formGroupClass || CommonEnum.FORM_CLASS}`}
            controlId={name}
        >
            <Form.Label>{label}</Form.Label>
            <Form.Control
                placeholder={placeholder}
                type={showPassword ? 'text' : 'password'}
                className={customClass}
                {...register(name, { required, pattern, min, max })}
                onBlur={onBlur}
                {...otherProps}
                maxLength={maxLength}
                readOnly={readOnly}
                isInvalid={Boolean(errorMsg)}
                disabled={disabled}
                autoComplete="off"
            />
            <img
                src={showPassword ? EYEOPEN : EYECLOSE}
                onClick={() => setShowPassword(!showPassword)}
                alt="Eye open"
                className="icon"
                height={24}
                width={24}
            />
            {errorMsg && (
                <Form.Control.Feedback type="invalid">
                    {errorMsg}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
};

export const PhoneInput = ({
    label,
    name,
    placeholder,
    type,
    customClass,
    register,
    onBlur,
    maxLength,
    readOnly,
    errorMsg,
    disabled,
    required,
    pattern,
    min,
    max,
    optional,
    value,
    watch,
    ...otherProps
}: PhoneInputProps) => {
    const phoneNumWatch = watch(name);
    return (
        <Form.Group
            className={`${otherProps.formGroupClass || CommonEnum.FORM_CLASS}`}
            controlId={name}
        >
            <Form.Label>{`${label}${required ? '*' : ''}`}</Form.Label>
            <Form.Control
                placeholder={placeholder}
                type={'text'}
                value={formatMobileNumber(phoneNumWatch)}
                className={customClass}
                {...register(name, { required, pattern, min, max })}
                onBlur={onBlur}
                {...otherProps}
                maxLength={maxLength}
                readOnly={readOnly}
                isInvalid={Boolean(errorMsg)}
                disabled={disabled}
            />
            {errorMsg && (
                <Form.Control.Feedback type="invalid">
                    {errorMsg}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
};

interface GooglePlacePickerInputProps {
    control: Control<any>;
    autoCompleteRef: React.RefObject<HTMLInputElement>;
    errorMsg?: string;
    name: string;
    onBlurValidate: (name: string) => void;
    handlePlaceSelect: (place: any, onChange: (event: any) => void) => void;
    handleChange?: (value: string) => void;
    strictSetting?: boolean;
    label: string;
    placeholder: string;
    defaultValue?: string;
}

export const GooglePlacePickerInput = ({
    control,
    autoCompleteRef,
    errorMsg,
    name,
    onBlurValidate,
    handlePlaceSelect,
    handleChange = (value = '') => {},
    strictSetting,
    label,
    placeholder,
    defaultValue,
}: GooglePlacePickerInputProps) => {
    return (
        <Form.Group className="form-group">
            <label>{label}</label>
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange } }) => (
                    <AutoCompleteDropdown
                        autoCompleteRef={autoCompleteRef}
                        componentRestrictions={{ country: Country.US }}
                        fields={[
                            GooglePlaceFields.ICON,
                            GooglePlaceFields.NAME,
                            GooglePlaceFields.ADDRESS_COMPONENTS,
                            GooglePlaceFields.ADR_ADDRESS,
                            GooglePlaceFields.FORMATTED_ADDRESS,
                            GooglePlaceFields.PLACE_ID,
                            GooglePlaceFields.GEOMETRY_LOCATION,
                        ]}
                        defaultValue={defaultValue}
                        types={['geocode', 'establishment']}
                        placeholder={placeholder}
                        customClass="form-control input"
                        handlePlaceSelect={(place) =>
                            handlePlaceSelect(place, onChange)
                        }
                        handleChange={handleChange}
                        errorMsg={errorMsg}
                        strictSetting={strictSetting}
                        onBlur={() => onBlurValidate(name)}
                    />
                )}
            />
        </Form.Group>
    );
};

export default CustomInput;
