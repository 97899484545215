import { useRef, useState } from 'react';
import { TabKeys } from '../../../shared/constants/application.constant';
import CustomTabs from '../../../shared/elements/CustomTabs';
import useAPIEvents from '../hooks/useAPIEvents';
import Filter from '../../../shared/elements/Filter';
import { Col, Row } from 'react-bootstrap';
import { debounce } from '../../../shared/utils/helper';
import { ConstNumber } from '../../../shared/constants/number.constant';

const APIEvents = () => {
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
        null,
        null,
    ]);
    const [city, setCity] = useState('');
    const timeoutId = useRef<NodeJS.Timeout>();
    const [startDate, endDate] = dateRange;
    const { tabs, activeTab, handleTabClick } = useAPIEvents({
        filter: {
            endDate,
            startDate,
            city,
        },
        isDataWareHouse: false,
    });
    const debouncedCitySearchHandler = debounce(
        (value: string) => {
            setCity(value);
        },
        timeoutId,
        ConstNumber.HALF_SECOND
    );
    const changeHandler = (search: string) => {
        debouncedCitySearchHandler(search);
    };

    return (
        <>
            <Row className="filter-header">
                <Col md={3}>
                    <h2 className="page-header">All Events</h2>
                </Col>
                <Col md={9}>
                    <Filter
                        searchHandler={changeHandler}
                        dateHandler={function (
                            dates: [Date | null, Date | null]
                        ): void {
                            setDateRange(dates);
                        }}
                    />
                </Col>
            </Row>
            <div className="admin-categories">
                <CustomTabs
                    defaultActiveKey={TabKeys.EXTERNAL_EVENT}
                    tabs={tabs}
                    activeTab={activeTab}
                    handleTabClick={handleTabClick}
                />
            </div>
        </>
    );
};

export default APIEvents;
