import React from 'react';
import ErrorLayout from './ErrorLayout';
import { Button } from 'react-bootstrap';
import { INTERNAL_ERROR } from '../constants/image.constant';
import { routeObj } from '../constants/routes.constants';
import { NoDataEnums } from '../constants/html.constant';
import { isLoggedIn } from '../utils/storage.helpers';
import { StorageDataResponse } from '../utils/types.elements';

const InternalError: React.FC = ({}) => {
    const isLogged = isLoggedIn() as StorageDataResponse;
    function clickHandler(): void {
        window.location.pathname = routeObj.DEFAULT;
    }

    return (
        <ErrorLayout img={INTERNAL_ERROR}>
            <h3>500 error</h3>
            <p>{NoDataEnums.INTERNAL_ERROR}</p>
            <Button onClick={() => clickHandler()} variant="primary">
                {isLogged.role ? 'Back to Dashboard' : 'Back to Home'}
            </Button>
        </ErrorLayout>
    );
};

export default InternalError;
