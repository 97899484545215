import { useEffect, useState } from 'react';
import { useLogout } from './useLogout';
import { useGetNotificationCountQuery } from '../../modules/notificationList/slices/slice.notificationList';
import { checkIsAdmin } from '../utils/helper';

export function useUserDropdown() {
    const { logout, isLoading } = useLogout();
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [showChange, setShowChange] = useState(false);
    const { data: notificationCount, refetch: refetchNotificationCount } =
        useGetNotificationCountQuery({});

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleShow = () => {
        setShow(!show);
    };

    const onClickLogout = () => {
        setIsOpen(!isOpen);
        handleShow();
    };

    const handleLogout = async () => {
        await logout();
        handleShow();
    };

    const toggleChange = () => {
        setShowChange(!showChange);
    };

    async function checkIsSubscribed() {
        if (
            notificationCount?.data &&
            notificationCount?.data.is_subscribed === false &&
            !checkIsAdmin()
        ) {
            await logout();
        }
    }

    useEffect(() => {
        checkIsSubscribed();
    }, [notificationCount]);

    return {
        isOpen,
        handleToggle,
        handleLogout,
        show,
        handleShow,
        onClickLogout,
        showChange,
        toggleChange,
        isLoading,
        notificationCount,
        refetchNotificationCount,
    };
}
