import { Form } from 'react-bootstrap';
import { RadioInput } from '../../../shared/elements/CustomInput';
import { SubmitButton } from '../../../shared/elements/Buttons';
import { useBusinessType } from '../hooks/useBusinessType';
import { Controller } from 'react-hook-form';
import { SubCategory } from '../../../shared/utils/types.elements';
import Loader from '../../../shared/elements/Loader';
import ApplyPromoCode from '../../../shared/elements/ApplyPromoCode';

const BusinessType = () => {
    const {
        isValid,
        handleSubmit,
        onSubmit,
        control,
        getValues,
        data,
        isLoading,
        couponHandler,
        isPromoApplied,
        promoData,
        promoLoading,
        resetHandler,
    } = useBusinessType();

    return (
        <div className="gradient-container">
            <div className="container">
                <h2>Select Your Business Type</h2>
                <p>Select a category best suited for your business</p>
                <div className="white-box-outer">
                    {data?.data && data?.data.length ? (
                        <Form
                            className="modal-form demo-form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="white-box">
                                <h5>Choose one category to proceed further</h5>
                                <div className="radio-container">
                                    <Controller
                                        control={control}
                                        name="level1_id"
                                        render={({ field: { onChange } }) => (
                                            <RadioInput
                                                name={'level1_id'}
                                                mapOption={(
                                                    option: SubCategory
                                                ) => ({
                                                    label: option.name,
                                                    value: option.id,
                                                })}
                                                defaultValue={getValues(
                                                    'level1_id'
                                                )}
                                                radioClass="custom-radio-chip"
                                                handleChange={(e) =>
                                                    onChange(
                                                        Number(e.target.value)
                                                    )
                                                }
                                                options={data.data}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="promocode-container">
                                <ApplyPromoCode
                                    applyHandler={couponHandler}
                                    label="Promotion Code"
                                    isLoading={promoLoading}
                                    isSuccess={isPromoApplied}
                                    resetHandler={resetHandler}
                                    successMessage={promoData?.message}
                                />
                                <SubmitButton
                                    label={'Proceed'}
                                    customClass="medium"
                                    isLoading={isLoading}
                                    loadingLabel={'Proceeding'}
                                    isDisable={!isValid || isLoading}
                                />
                            </div>
                        </Form>
                    ) : isLoading ? (
                        <Loader />
                    ) : (
                        <p className="no-data">No category found !!</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BusinessType;
