import React, { ReactNode } from 'react';

interface Props {
    children: ReactNode;
    img: string;
}
const ErrorLayout: React.FC<Props> = ({ children, img }) => {
    return (
        <div className="center-content">
            <div className="error-container">
                <img
                    src={img}
                    alt="error"
                    height={238}
                    width={240}
                    className="error-image"
                />
                {children}
            </div>
        </div>
    );
};

export default ErrorLayout;
