import React from 'react';
import { NOTFOUND } from '../constants/image.constant';
import { NoDataEnums } from '../constants/html.constant';

type Props = {
    heading: string;
    subHeading?: string;
};

const NoDataFound = (props: Props) => {
    return (
        <div className="center-content">
            <div className="error-container">
                <img src={NOTFOUND} alt="No Result" height={180} width={180} className='no-result' />
                <h3>{props.heading}</h3>
                <p>{props.subHeading || NoDataEnums.NO_RESULT_FOUND}</p>
            </div>
        </div>
    );
};

export default NoDataFound;
