import {
    DateFormats,
    EventActionType,
    SpecialEnum,
    TabKeys,
    ValidationEnums,
} from '../../../shared/constants/application.constant';
import * as yup from 'yup';
import moment from 'moment';
import { ConstNumber } from '../../../shared/constants/number.constant';
import {
    CreateSpecial,
    DateTimeParam,
    DateType,
    LocalSpecials,
} from './types.specials';
import {
    compareStartAndCurrentTime,
    formatChangeDateTime,
    formatDateAndTime,
    getAdjustedStartDate,
} from '../../../shared/utils/helper';
import { routeObj } from '../../../shared/constants/routes.constants';
import { NavigateFunction } from 'react-router-dom';
import Toast from '../../../shared/utils/toast.helper';
import {
    ButtonLabel,
    ToastEnum,
} from '../../../shared/constants/html.constant';
import {
    UseFormGetValues,
    UseFormSetValue,
    UseFormTrigger,
} from 'react-hook-form';
import { orOrLogic, ternaryLogic } from '../../../shared/utils/orLogic';
import { Option, TimeSlot } from '../../../shared/utils/types.elements';
import { ErrorObj } from '../../../shared/types/type';

interface IntitialProp {
    activeTab: string | null;
    address: number;
}

export function getSpecialFormIntialValues(params: IntitialProp) {
    return {
        id: undefined,
        name: '',
        address: ConstNumber.VALUE_0,
        start_date: getAdjustedStartDate().toDate(),
        end_date: getAdjustedStartDate().toDate(),
        start_time: moment().add(ConstNumber.VALUE_15, 'minutes').toDate(),
        end_time: moment().add(ConstNumber.VALUE_30, 'minutes').toDate(),
        description: '',
        price_range: undefined,
        offer_type:
            params.activeTab === TabKeys.LOCAL_SPECIAL
                ? SpecialEnum.LOCAL_SPECIAL
                : SpecialEnum.HAPPY_HOURS,
        document: null,
        oh_start_time: undefined,
        oh_end_time: undefined,
        opening_hours_list: null,
    };
}

export const specialFormSchema = yup.object().shape({
    id: yup.number().optional(),
    offer_type: yup.number().required(ValidationEnums.REQUIRED),
    name: yup
        .string()
        .max(ConstNumber.VALUE_100, ValidationEnums.MAX_100)
        .required(ValidationEnums.REQUIRED),
    address: yup
        .number()
        .min(ConstNumber.VALUE_1, ValidationEnums.REQUIRED)
        .required(ValidationEnums.REQUIRED),
    start_date: yup
        .date()
        .typeError(ValidationEnums.REQUIRED)
        .required(ValidationEnums.REQUIRED),
    end_date: yup
        .date()
        .min(yup.ref('start_date'), ValidationEnums.END_DATE_ERROR)
        .typeError(ValidationEnums.REQUIRED)
        .required(ValidationEnums.REQUIRED),
    start_time: yup
        .date()
        .test(
            'is-min',
            ValidationEnums.START_TIME_ERROR,
            compareStartAndCurrentTime
        )
        .typeError(ValidationEnums.REQUIRED)
        .required(ValidationEnums.REQUIRED),
    end_time: yup
        .date()
        .test(
            'is-same',
            ValidationEnums.SAME_START_END_ERROR,
            function (value) {
                const { start_time } = this.parent;
                const endDateIntoString = JSON.stringify(value);
                const startDateIntoString = JSON.stringify(start_time);
                return endDateIntoString !== startDateIntoString;
            }
        )
        .required(ValidationEnums.REQUIRED),
    price_range: yup.number().nullable(),
    description: yup
        .string()
        .max(ConstNumber.VALUE_1000, ValidationEnums.MAX_1000)
        .optional(),
    oh_start_time: yup.date().notRequired(),
    oh_end_time: yup.date().notRequired(),
    opening_hours_list: yup.string().nullable(),
});

export function getChangedValues(
    initialData: CreateSpecial,
    _data: CreateSpecial
) {
    type UpdatedFields = { [key: string]: string };
    const changedValues: UpdatedFields = {};

    for (const key in _data) {
        const keyString = key as keyof CreateSpecial;
        // this will run for date fields
        if (keyString.endsWith('_date') || keyString.endsWith('_time')) {
            const newData = moment(_data[keyString] as Date); // changed date
            const initialDataDate = moment(initialData[keyString] as Date); // intial date from api
            if (!newData.isSame(initialDataDate)) {
                // if date will change, will push it to object
                changedValues['end_datetime'] = formatDateAndTime(
                    _data.end_date,
                    _data.end_time
                );
                changedValues['start_datetime'] = formatDateAndTime(
                    _data.start_date,
                    _data.start_time
                );
                changedValues['display_end_datetime'] = formatChangeDateTime(
                    _data.end_date,
                    _data.end_time
                );
                changedValues['display_start_datetime'] = formatChangeDateTime(
                    _data.start_date,
                    _data.start_time
                );
            }
        } else if (_data[keyString] !== initialData[keyString]) {
            // this if will run for rest of the fields
            changedValues[keyString] = _data[keyString] as any;
        }
    }
    return changedValues;
}

export const navigateActionHandler = (
    id: number,
    type: string,
    navigate: NavigateFunction,
    activeTab: string
) => {
    if (type === EventActionType.EDIT || type === EventActionType.DUPLICATE) {
        navigate(routeObj.BUSINESS_SPECIAL_EDIT, {
            state: {
                id,
                type,
                activeTab,
            },
        });
    }
};

export const showMessage = (
    heading: string,
    message?: string,
    errorMsg?: string
) => {
    if (message) {
        Toast.success(heading, message);
    } else if (errorMsg) {
        Toast.error(ToastEnum.SOMETHING_WENT_WRONG, errorMsg);
    }
};

function mapDateAndTime(
    date: Date | null,
    time: Date,
    fieldName: DateType,
    dependentFieldName: DateType,
    _type: DateTimeParam,
    setValue: UseFormSetValue<any>,
    trigger: UseFormTrigger<any>
) {
    if (date !== null) {
        const newDate = moment(date).format(
            _type === 'date' ? DateFormats.YYYY_MM_DD : DateFormats.HH_MM
        );
        const newTime = moment(time).format(
            _type === 'date' ? DateFormats.HH_MM : DateFormats.YYYY_MM_DD
        );
        const updatedDateTime = moment(
            `${_type === 'date' ? newDate : newTime} ${
                _type === 'date' ? newTime : newDate
            }`,
            `${DateFormats.YYYY_MM_DD} ${DateFormats.HH_MM}`
        ).toDate();
        setValue(fieldName, updatedDateTime);
        setValue(dependentFieldName, updatedDateTime);
        trigger(['start_date', 'end_date', 'start_time', 'end_time']);
    } else {
        setValue(fieldName, null);
        setValue(dependentFieldName, time);
        trigger(['start_date', 'end_date', 'start_time', 'end_time']);
    }
}

export const handleDate = (
    date: Date | null,
    fieldName: DateType | string,
    setValue: UseFormSetValue<any>,
    getValues: UseFormGetValues<any>,
    trigger: UseFormTrigger<any>
) => {
    if (fieldName === 'start_date' || fieldName === 'end_date') {
        const dependentFieldName =
            fieldName === 'start_date' ? 'start_time' : 'end_time';
        const dependentTime = getValues(dependentFieldName);
        if (dependentTime) {
            mapDateAndTime(
                date,
                dependentTime,
                fieldName,
                dependentFieldName,
                'date',
                setValue,
                trigger
            );
        }
    } else if (fieldName === 'start_time' || fieldName === 'end_time') {
        const dependentFieldName =
            fieldName === 'start_time' ? 'start_date' : 'end_date';
        const dependentDate = getValues(dependentFieldName);
        if (dependentDate) {
            mapDateAndTime(
                date, // this date is actually used for getting time from the Date obj
                dependentDate,
                fieldName,
                dependentFieldName,
                'time',
                setValue,
                trigger
            );
        }
    }
};

export const navigateToListingScreen = (
    navigate: NavigateFunction,
    tab: number
) => {
    navigate(routeObj.BUSINESS_SPECIALS, {
        state: {
            activeTab:
                tab === SpecialEnum.HAPPY_HOURS
                    ? TabKeys.HAPPY_HOURS
                    : TabKeys.LOCAL_SPECIAL,
        },
    });
};

export function getSpecialName(name: string, type: string) {
    return ternaryLogic(
        type === EventActionType.DUPLICATE,
        `Duplicate-${name}`,
        name
    );
}

export const setIntialObject = (_data: LocalSpecials) => {
    return {
        address: _data.address.id,
        end_date: new Date(_data.end_datetime),
        end_time: new Date(_data.end_datetime),
        name: _data.name,
        offer_type: _data.offer_type,
        start_date: new Date(_data.start_datetime),
        start_time: new Date(_data.start_datetime),
        description: _data.description || '',
        document: _data.document as any,
        id: _data.id,
        price_range: _data.price_range,
    };
};

/**
 *
 * @param type Edit mode or create mode
 * @param isLoading label loading
 * @returns label
 */
export function getLabel(type: string, isLoading: boolean) {
    if (isLoading) {
        return ternaryLogic(
            type === EventActionType.EDIT,
            ButtonLabel.UPDATING,
            ButtonLabel.SAVING
        );
    }
    return ternaryLogic(
        type === EventActionType.EDIT,
        ButtonLabel.UPDATE,
        ButtonLabel.SAVE
    );
}

export function apiMessage(
    dataMsg?: string,
    apiError?: ErrorObj,
    updateDataMessage?: string,
    updateError?: ErrorObj
) {
    if (orOrLogic(updateDataMessage, updateError)) {
        showMessage(
            ToastEnum.SPECIALS_UPDATED,
            updateDataMessage,
            updateError?.message
        );
    }
    if (orOrLogic(dataMsg, apiError)) {
        showMessage(
            ToastEnum.SPECIALS_CREATED,
            dataMsg,
            (apiError as ErrorObj)?.message
        );
    }
}
