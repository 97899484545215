import React from 'react';
import { convertArrIntoString } from '../utils/helper';

type Props = {
    features?: string;
};

const PaymentPlanFeatures = (props: Props) => {
    return (
        <ul className="check-list">
            {convertArrIntoString(props.features)?.map((feature, idx) => (
                <li key={idx}>{feature}</li>
            ))}
        </ul>
    );
};

export default PaymentPlanFeatures;
