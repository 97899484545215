import React from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BACK } from '../../../shared/constants/image.constant';
import { SubmitButton } from '../../../shared/elements/Buttons';
import CustomInput from '../../../shared/elements/CustomInput';
import { useForgotPassword } from '../hooks/useForgotPassword';

const ForgotPassword = () => {
    const {
        errors,
        handleSubmit,
        isValid,
        onSubmitForm,
        register,
        isDataLoading,
        trigger,
    } = useForgotPassword();

    return (
        <div className="login-inner forgot-pass-container">
            <div className="back-btn-container">
                <Link to={'/'} title="Back" className="back-btn">
                    <img src={BACK} alt="back" />
                </Link>
            </div>
            <h1 className="login-title">Forgot Your Password?</h1>
            <p className="verify-subtext">Enter your registered Email Id</p>
            <Form className="login-form" onSubmit={handleSubmit(onSubmitForm)}>
                <CustomInput
                    label=""
                    type="email"
                    name={'email'}
                    placeholder="Email Id"
                    errorMsg={errors.email?.message}
                    onBlur={() => trigger('email')}
                    register={register}
                />
                <div className="btn-container">
                    <SubmitButton
                        isDisable={!isValid || isDataLoading}
                        isLoading={isDataLoading}
                        label="Send OTP"
                    />
                </div>
            </Form>
        </div>
    );
};

export default ForgotPassword;
