import { memo } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Check } from '../../../assets/icons/Check';
import { usePromoCodeInput } from '../hooks/usePromoCodeInput';
import { PromoCodeInputProps } from '../types/type';
import { ConstNumber } from '../constants/number.constant';

const PromoCodeInput = (props: PromoCodeInputProps) => {
    const {
        applyHandler,
        isApplied,
        removeCouponHandler,
        isLoading,
        disabled,
        value,
        changeHandler,
    } = usePromoCodeInput(props);

    return (
        <>
            <div className="input-and-button">
                <div className="input-with-icon">
                    <Form.Control
                        disabled={isApplied}
                        type="text"
                        value={value}
                        placeholder="Enter code"
                        maxLength={ConstNumber.VALUE_8}
                        onChange={(e) => changeHandler(e.target.value)}
                    />
                    <div className="icon">{isApplied && <Check />}</div>
                </div>
                {isApplied ? (
                    <Button
                        variant="link"
                        type="button"
                        onClick={removeCouponHandler}
                    >
                        Remove
                    </Button>
                ) : (
                    <Button
                        disabled={disabled}
                        variant="link"
                        className={
                            disabled || isLoading ? 'disabled' : 'enabled'
                        }
                        type="button"
                        onClick={applyHandler}
                    >
                        Apply
                    </Button>
                )}
            </div>
        </>
    );
};

export default memo(PromoCodeInput);
