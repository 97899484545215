import React from 'react';
import VideoPlayer from './VideoPlayer';
import { Form } from 'react-bootstrap';
import {
    BUSINESS_IMAGE_PLACEHOLDER,
    EDITIMAGE,
} from '../../shared/constants/image.constant';
import { Trash } from '../../../assets/icons/Trash';
import { useMediaType } from '../hooks/useMediaType';

interface Props {
    media: File | null | string;
    setShow: (value: boolean) => void;
    deleteHandler: () => void;
    fromCategory?: boolean;
    isOptional?: boolean;
    isDisabled?: boolean;
    thumbnailUrl?: string;
}

const ViewUploadedMedia = (props: Props) => {
    const {
        media,
        setShow,
        deleteHandler,
        fromCategory,
        isOptional,
        isDisabled,
        thumbnailUrl,
    } = props;

    return (
        <>
            <Form.Label>
                {fromCategory ? 'Upload Image' : 'Upload Image or Video'}
                {isOptional ? '*' : ''}{' '}
                {!isOptional && <span className="text-muted">(Optional)</span>}
            </Form.Label>
            <div className="custom-upload-image">
                {typeof media === 'string' ? (
                    <>
                        <ShowMedia media={media} thumbnailUrl={thumbnailUrl} />
                        {!isDisabled && (
                            <span
                                role="button"
                                onClick={deleteHandler}
                                className="edit-btn circle"
                            >
                                <Trash stroke="#ffffff" />
                            </span>
                        )}
                    </>
                ) : (
                    <>
                        {media && media.type.startsWith('video/') ? (
                            <VideoPlayer
                                videoSrc={URL.createObjectURL(media)}
                                videoTitle={media.name}
                            />
                        ) : (
                            <img
                                src={
                                    media
                                        ? URL.createObjectURL(media)
                                        : undefined
                                }
                                alt="venue"
                                className="main-image"
                            />
                        )}
                        <span
                            role="button"
                            onClick={() => setShow(true)}
                            className="edit-btn circle"
                        >
                            <img src={EDITIMAGE} alt="edit" />
                        </span>
                    </>
                )}
            </div>
        </>
    );
};

type ShowMediaProps = {
    media: string;
    thumbnailUrl?: string;
};

function ShowMedia(params: ShowMediaProps) {
    const {media, thumbnailUrl} = params;
    // const { mediaImage, mediaVideo } = useMediaType(media); This may be used later.

    return (
        <>
            {!thumbnailUrl ? (
                <img src={media} className="main-image" alt="uploaded" />
            ) : thumbnailUrl ? (
                <VideoPlayer videoSrc={media} videoTitle="Video" thumbnail={thumbnailUrl} />
            ) : (
                <img
                    src={BUSINESS_IMAGE_PLACEHOLDER}
                    className="main-image"
                    alt="uploaded"
                />
            )}
        </>
    );
}

export default ViewUploadedMedia;
