import { Col, Form, Row } from 'react-bootstrap';
import CustomInput, {
    RadioInput,
    TextArea,
} from '../../../shared/elements/CustomInput';
import { PromotionTypeArray } from '../../../modules/specials/constants/data.specials';
import { ConstNumber } from '../../../shared/constants/number.constant';
import { usePromotion } from '../hooks/usePromotion';
import { PromotionTypeEnum } from '../../../shared/constants/application.constant';
import { Controller } from 'react-hook-form';
import SingleSelect from '../../../shared/elements/SingleSelect';
import { PLAN_MONTHS } from '../../../shared/constants/data.constant';
import { SubmitButton } from '../../../shared/elements/Buttons';
import { ButtonLabel } from '../../../shared/constants/html.constant';
import BackWithText from '../../../shared/elements/BackWithText';
import { useLocation } from 'react-router-dom';
import { FormParams } from '../../../shared/utils/types.elements';
import { routeObj } from '../../../shared/constants/routes.constants';

const Promotion = () => {
    const params = useLocation().state as FormParams;
    const {
        handleSubmit,
        onSubmitForm,
        register,
        errors,
        isValid,
        isLoading,
        trigger,
        getValues,
        control,
        planTypes,
        handleChange,
        selectedPlanType,
        handleMonths,
        selectedMonth,
        selectedType,
        isDiscountMonthRequired,
    } = usePromotion();

    return (
        <>
            <div className="promo-container">
                <BackWithText
                    to={routeObj.ADMIN_PROMOTION_CODE}
                    label="Promotion Code"
                    state={{
                        activeTab: params?.activeTab,
                    }}
                />
                <div className="promo-box">
                    <div className="promo-title">Create coupon for</div>
                    <Form onSubmit={handleSubmit(onSubmitForm)}>
                        <Controller
                            control={control}
                            name="promotion_type"
                            render={({ field: { onChange } }) => (
                                <div className="radio-heading-wrapper">
                                    <RadioInput
                                        name={'promotion_type'}
                                        mapOption={(option: {
                                            label: string;
                                            value: PromotionTypeEnum;
                                        }) => ({
                                            label: option.label,
                                            value: option.value,
                                        })}
                                        options={PromotionTypeArray}
                                        defaultValue={getValues(
                                            'promotion_type'
                                        )}
                                        radioClass="custom-radio"
                                        handleChange={(e) =>
                                            onChange(Number(e.target.value))
                                        }
                                    />
                                </div>
                            )}
                        />
                        <Row>
                            <Col md={6}>
                                <CustomInput
                                    label="Coupon Code"
                                    type="text"
                                    name={'name'}
                                    placeholder="Coupon Code"
                                    register={register}
                                    errorMsg={errors.name?.message}
                                    onBlur={() => trigger('name')}
                                />
                            </Col>
                            <Col md={6}>
                                <Form.Group
                                    className="form-group"
                                    controlId="promotion_plan_type"
                                >
                                    <SingleSelect
                                        options={planTypes}
                                        label="Plan Type"
                                        isSearchable={false}
                                        isClearable={false}
                                        changeHandler={handleChange}
                                        errorMsg={
                                            errors.promotion_plan_type?.message
                                        }
                                        placeholder="Choose Plan Type"
                                        value={selectedPlanType}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <CustomInput
                                    label="Email ID"
                                    type="text"
                                    name={'email'}
                                    placeholder="Enter Email ID"
                                    register={register}
                                    errorMsg={errors.email?.message}
                                    onBlur={() => trigger('email')}
                                    optional
                                />
                            </Col>
                            <Col md={6}>
                                {selectedType?.discount_category ===
                                    ConstNumber.VALUE_1 && (
                                    <Form.Group
                                        className="form-group"
                                        controlId="valid_months"
                                    >
                                        <SingleSelect
                                            options={PLAN_MONTHS}
                                            label="Number of Months"
                                            isSearchable={false}
                                            isClearable={false}
                                            changeHandler={handleMonths}
                                            errorMsg={
                                                errors.valid_months?.message
                                            }
                                            placeholder="Choose Month(s)"
                                            value={selectedMonth}
                                        />
                                    </Form.Group>
                                )}
                                {selectedType?.discount_category ===
                                    ConstNumber.VALUE_2 && (
                                    <CustomInput
                                        label="Discount %"
                                        type="text"
                                        name={'discount'}
                                        placeholder="Discount %"
                                        register={register}
                                        errorMsg={errors.discount?.message}
                                        onBlur={() => trigger('discount')}
                                    />
                                )}
                            </Col>
                            <Col md={6}>
                                <TextArea
                                    name={'description'}
                                    register={register}
                                    errorMsg={errors.description?.message}
                                    placeholder="Description"
                                    label="Description*"
                                    rows={ConstNumber.VALUE_6}
                                    maxLength={ConstNumber.VALUE_1000}
                                    optional={false}
                                    onBlur={() => trigger('description')}
                                />
                            </Col>
                        </Row>
                        <div className="button-footer">
                            <SubmitButton
                                label={ButtonLabel.CREATE}
                                isDisable={
                                    !isValid ||
                                    isLoading ||
                                    isDiscountMonthRequired()
                                }
                                isLoading={isLoading}
                                loadingLabel={ButtonLabel.CREATING}
                                customClass="mw-130"
                            />
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default Promotion;
