import React from 'react';
import { Navigate } from 'react-router-dom';
import { routeObj } from '../../../shared/constants/routes.constants';

/**@description its a container of admin screens, to keep the business search component independent */
const AdminContainer = () => {
    return <Navigate to={routeObj.ADMIN_BUSINESSES} />;
};

export default AdminContainer;
