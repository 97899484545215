import * as yup from 'yup';
import {
    EventActionType,
    TabKeys,
    ValidationEnums,
} from '../../../shared/constants/application.constant';
import { ButtonLabel } from '../../../shared/constants/html.constant';
import { ConstNumber } from '../../../shared/constants/number.constant';
import {
    POSITIVE_WHOLE_NUMBER,
    PRICE,
} from '../../../shared/constants/regex.constant';
import { FormParams } from '../../../shared/utils/types.elements';
import { PaymentPlan } from '../constants/types';

export function getPaymentPlanIntialValues(activeTab: string) {
    const initialValues = {
        id: undefined,
        name: '',
        price: '',
        plan_type:
            activeTab === TabKeys.PLATFORM_SUBSCRIPTION
                ? ConstNumber.VALUE_1
                : ConstNumber.VALUE_2,
    };
    if (activeTab === TabKeys.PLATFORM_SUBSCRIPTION) {
        return {
            ...initialValues,
            level_1_categories: [],
            description: '',
            features: '',
            planDetails: '',
        };
    } else {
        return { ...initialValues, plan_views: '' };
    }
}

export const paymentPlanBaseSchema = yup.object().shape({
    id: yup.number().optional(),
    name: yup.string().trim().required(ValidationEnums.REQUIRED),
    price: yup
        .string()
        .matches(PRICE, ValidationEnums.VALID_PRICE)
        .required(ValidationEnums.REQUIRED),
    plan_type: yup.number().required(ValidationEnums.REQUIRED),
});

export const subscriptionPlanSchema = paymentPlanBaseSchema.shape({
    description: yup.string().trim().required(ValidationEnums.REQUIRED),
    features: yup.string().required(ValidationEnums.REQUIRED),
    planDetails: yup.string().trim().optional(),
    level_1_categories: yup
        .array()
        .of(yup.number().required())
        .min(ConstNumber.VALUE_1, ValidationEnums.REQUIRED)
        .required(ValidationEnums.REQUIRED),
});

export const advertisementPlanSchema = paymentPlanBaseSchema.shape({
    plan_views: yup
        .string()
        .matches(POSITIVE_WHOLE_NUMBER, ValidationEnums.VALID_NUMBER)
        .required(ValidationEnums.REQUIRED),
});

export const getPaymentCategoryData = (
    paymentPlan: PaymentPlan | undefined
) => {
    return paymentPlan?.level_1_categories.map((item) => {
        return {
            label: item.level_1_name,
            value: Number(item.level_1_id),
        };
    });
};

export const getUpdatedValues = (previous: any, latest: any) => {
    type UpdatedFields = { [key: string]: string | number[] | File };
    const updatedValues: UpdatedFields = {};
    for (const key in latest) {
        if (!(key in previous) || latest[key] !== previous[key]) {
            updatedValues[key] = latest[key] as any;
        }
    }

    return updatedValues;
};

export const getDataRequest = (data: any) => {
    const dataValue = {
        name: data.name,
        price: data.price,
        plan_type: data.plan_type,
        description: data.description || '',
        features: data.features || '',
        plan_views: data.plan_views ? Number(data.plan_views) : undefined,
        level_1_categories: data.level_1_categories?.length
            ? JSON.stringify(data.level_1_categories)
            : undefined,
    };
    if (data.is_active) {
        return {
            ...dataValue,
            is_active: true,
        };
    }
    return dataValue;
};

export const getInitialData = (paymentPlanDataById: PaymentPlan) => {
    return {
        id: paymentPlanDataById.id,
        name: paymentPlanDataById.name,
        price: paymentPlanDataById.price,
        plan_type: paymentPlanDataById.plan_type,
    };
};

export const getUpdatedData = (obj: any, paymentPlanDataById: PaymentPlan) => {
    return {
        ...obj,
        description: paymentPlanDataById.description,
        features: paymentPlanDataById.features,
        planDetails: '',
    };
};

export const getPriceLabel = (params: FormParams) => {
    return params.activeTab === TabKeys.PLATFORM_SUBSCRIPTION
        ? 'Price $/month'
        : 'Price $';
};

export const getButtonLabel = (params: FormParams) => {
    return !params.id
        ? ButtonLabel.CREATE
        : params?.type === EventActionType.EDIT
        ? ButtonLabel.UPDATE
        : ButtonLabel.ACTIVATE;
};

export const getLoadingLabel = (params: FormParams) => {
    return !params.id
        ? ButtonLabel.CREATING
        : params?.type === EventActionType.EDIT
        ? ButtonLabel.UPDATING
        : ButtonLabel.ACTIVATING;
};

export function showPrice(priceInStr: string) {
    let formattedPrice: string;
    const price = parseFloat(priceInStr);
    if (price % ConstNumber.VALUE_1 === ConstNumber.VALUE_0) {
        // If the price has no decimal places, add ".00"
        formattedPrice = price.toFixed(ConstNumber.VALUE_2);
    } else {
        // If the price has decimal places, leave it as is
        return price;
    }
    return formattedPrice;
}
