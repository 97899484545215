import { useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import {
    ButtonLabel,
    ToastEnum,
} from '../../../shared/constants/html.constant';
import { SubmitButton } from '../../../shared/elements/Buttons';
import CustomInput from '../../../shared/elements/CustomInput';
import UploadMedia from '../../../shared/elements/UploadMedia';
import { ErrorObj } from '../../../shared/types/type';
import Toast from '../../../shared/utils/toast.helper';
import {
    CategoryLevel3,
    CategoryLevelThree,
} from '../constants/types.categories';
import useAdminLevel3Categories from '../hooks/useAdminLevel3Categories';
import { andAndLogic, ternaryLogic } from '../../../shared/utils/orLogic';

interface ICreateSubLevelThreeProps {
    show: boolean;
    handleShow: () => void;
    selectedHeading: CategoryLevelThree | null;
    level3: CategoryLevel3 | null;
}

const CreateCategorySubLevelThree = (props: ICreateSubLevelThreeProps) => {
    const { show, handleShow, selectedHeading, level3 } = props;
    const {
        onSubSubmitForm,
        handleSubSubmit,
        subRegister,
        subErrors,
        isSubValid,
        isLoadingSubLevelThree,
        createSubLevelThreeResponse,
        apiSubError,
        subReset,
        refetch,
        subTrigger,
        setValue,
        getValues,
        showMedia,
        setShowMedia,
        setPreImage,
        onSubUpdateForm,
        isLoadingUpdateSubLevelThree,
        apiUpdateSubError,
        updateSubLevelThreeResponse,
    } = useAdminLevel3Categories();

    useEffect(() => {
        if (createSubLevelThreeResponse?.message) {
            refetch();
            handleShow();
            Toast.success(
                ToastEnum.SUBCATEGORY_CREATED,
                createSubLevelThreeResponse?.message
            );
        } else if (apiSubError) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (apiSubError as ErrorObj)?.message
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createSubLevelThreeResponse?.message, apiSubError]);

    useEffect(() => {
        if (updateSubLevelThreeResponse?.message) {
            refetch();
            handleShow();
            Toast.success(
                ToastEnum.SUBCATEGORY_UPDATED,
                updateSubLevelThreeResponse?.message
            );
        } else if (apiUpdateSubError) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (apiUpdateSubError as ErrorObj)?.message
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateSubLevelThreeResponse?.message, apiUpdateSubError]);

    useEffect(() => {
        if (andAndLogic(show, !level3)) {
            subReset();
        } else if (show && level3) {
            setValue('id', level3.id);
            setValue('name', level3.name);
            setValue('icon' as any, level3.icon);
            setPreImage(level3.icon);
            subTrigger();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, level3]);

    useEffect(() => {
        if (show && selectedHeading) {
            setValue('heading', selectedHeading.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <Modal
            centered
            className="admin-modals"
            show={show}
            onHide={handleShow}
        >
            <Modal.Header closeButton className="text-left">
                <Modal.Title>
                    {ternaryLogic(level3, 'Update', 'Create New')}
                </Modal.Title>
            </Modal.Header>
            <Form
                className="mt-1"
                onSubmit={handleSubSubmit(
                    ternaryLogic(level3, onSubUpdateForm, onSubSubmitForm)
                )}
            >
                <Modal.Body>
                    <UploadMedia
                        show={showMedia}
                        setShow={setShowMedia}
                        setValue={setValue}
                        name="icon"
                        document={getValues('icon' as any)}
                        clear={Boolean(andAndLogic(show, !level3))}
                        fromCategory
                    />
                    <CustomInput
                        label="Subcategory Name"
                        type="text"
                        name={'name'}
                        register={subRegister}
                        errorMsg={subErrors.name?.message}
                        placeholder="Subcategory Name"
                        onBlur={() => subTrigger('name')}
                        disabled={Boolean(level3)}
                        readOnly={Boolean(level3)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <SubmitButton
                        label={ternaryLogic(
                            level3,
                            ButtonLabel.UPDATE,
                            ButtonLabel.SAVE
                        )}
                        isDisable={
                            !isSubValid ||
                            isLoadingSubLevelThree ||
                            isLoadingUpdateSubLevelThree
                        }
                        isLoading={
                            isLoadingSubLevelThree ||
                            isLoadingUpdateSubLevelThree
                        }
                        loadingLabel={ternaryLogic(
                            level3,
                            ButtonLabel.UPDATING,
                            ButtonLabel.SAVING
                        )}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CreateCategorySubLevelThree;
