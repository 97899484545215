import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import CustomInput, {
    CheckBox,
    GooglePlacePickerInput,
    PasswordInput,
    PhoneInput,
} from '../../../shared/elements/CustomInput';
import BusinessSuggestion from './BusinessSuggestion';
import PasswordHint from '../../../shared/elements/PasswordHint';
import { useSignup } from '../hooks/useSignup';
import TermsAndCondition from './TermsAndCondition';
import { SubmitButton } from '../../../shared/elements/Buttons';
import { ConstNumber } from '../../../shared/constants/number.constant';

const SignUpForm = () => {
    const {
        businessInfo,
        handleOnBlurMobile,
        isBusinessSelected,
        errors,
        handleSubmit,
        onSubmitForm,
        register,
        control,
        showSuggestion,
        watch,
        isValid,
        trigger,
        isDataLoading,
        businessHandler,
        onBlurValidate,
        setValue,
    } = useSignup();
    const autoCompleteRef = useRef<HTMLInputElement>(null);
    const pswdWatch = watch('password');

    const handlePlaceSelect = (
        place: any,
        onChange: (...event: any[]) => void
    ) => {
        onChange(place.formatted_address);
        setValue('place', place);
        trigger('address');
    };

    return (
        <Form className="login-form" onSubmit={handleSubmit(onSubmitForm)}>
            <PhoneInput
                label="Business Phone Number"
                type="text"
                name={'phone_number'}
                placeholder="Business Phone Number"
                register={register}
                errorMsg={errors.phone_number?.message}
                onBlur={(e) => handleOnBlurMobile(e.currentTarget.value)}
                maxLength={ConstNumber.VALUE_12}
                watch={watch}
                required
            />
            <CustomInput
                label="Business Name"
                type="text"
                name={'name'}
                placeholder="Business Name"
                register={register}
                errorMsg={errors.name?.message}
                onBlur={() => onBlurValidate('name')}
                disabled={isBusinessSelected}
                required
            />
            {isBusinessSelected ? (
                <CustomInput
                    label="Add Address"
                    type="text"
                    name={'address'}
                    placeholder="Address"
                    register={register}
                    errorMsg={errors.address?.message}
                    disabled={isBusinessSelected}
                    required
                />
            ) : (
                <GooglePlacePickerInput
                    autoCompleteRef={autoCompleteRef}
                    control={control}
                    handlePlaceSelect={handlePlaceSelect}
                    strictSetting={true}
                    name="address"
                    onBlurValidate={() => onBlurValidate('address')}
                    errorMsg={errors.address?.message}
                    label="Add Address*"
                    placeholder="Address"
                />
            )}
            <CustomInput
                label="Business Email ID"
                type="text"
                name={'email'}
                placeholder="Business Email ID"
                onBlur={() => onBlurValidate('email')}
                register={register}
                errorMsg={errors.email?.message}
                required
            />
            {businessInfo?.length && showSuggestion ? (
                <div className="profile-menu active h-auto">
                    <BusinessSuggestion
                        businessInfo={businessInfo}
                        businessHandler={businessHandler}
                    />
                </div>
            ) : null}
            <PasswordInput
                label="Password*"
                type="password"
                name={'password'}
                placeholder="Password"
                register={register}
                errorMsg={errors.password?.message}
                onBlur={() => onBlurValidate('password')}
                formGroupClass="form-group input-with-icon"
                required
            />
            <PasswordHint password={pswdWatch} />
            <CustomInput
                label="Referral Code"
                type="text"
                name={'referral_code'}
                placeholder="Referral Code"
                customClass="input-with-icon"
                register={register}
                onBlur={() => onBlurValidate('referral_code')}
                errorMsg={errors.referral_code?.message}
                requiredText="(If you have one)"
            />
            <div className="terms-container">
                <CheckBox name="checkBox" id="terms" register={register} />
                <TermsAndCondition />
            </div>
            <div className="terms-container">
                <CheckBox
                    name="newsletter_enabled"
                    id="newsletter_enabled"
                    register={register}
                />
                <span>I agree to receive newsletters and email campaigns</span>
            </div>
            <div className="btn-container">
                <SubmitButton
                    label={'Sign Up'}
                    isDisable={!isValid || isDataLoading}
                    isLoading={isDataLoading}
                />
            </div>
        </Form>
    );
};

export default SignUpForm;
