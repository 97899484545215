interface ICategoryCardProps {
    subCategory: string[];
}

const CategoryCard = (props: ICategoryCardProps) => {
    const { subCategory } = props;

    return (
        <div className="chip-container">
            {subCategory.map((value, index) => (
                <div className="chip primary" key={value}>
                    {value}
                </div>
            ))}
        </div>
    );
};

export default CategoryCard;
