import { Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    ButtonLabel,
    Heading,
    NoDataEnums,
} from '../../../shared/constants/html.constant';
import { ConstNumber } from '../../../shared/constants/number.constant';
import EventCard from '../../../shared/elements/EventCard';
import {
    getFromToDateValue,
    getFromToTimeValue,
} from '../../../shared/utils/helper';
import useExternalEvents from '../hooks/useExternalEvents';
import NoDataFound from '../../../shared/elements/NoDataFound';
import { FilterArg } from '../../../shared/types/type';
import { DataWarehouseParam } from '../utils/types.apiEvents';
import { ConfirmationPopup } from '../../../shared/elements/ConfirmationPopup';
import Loader from '../../../shared/elements/Loader';
import { getEventCardClass } from '../../../shared/utils/utils';

interface Props extends DataWarehouseParam {
    filters: FilterArg;
}

const ExternalEvents = (props: Props) => {
    const {
        externalEvents,
        hasMore,
        handleFetchNextPage,
        isLoadingEvents,
        navigateToDetail,
        actionHandler,
        handleConfirm,
        isDeleting,
        show,
        deletedEvent,
        handleShow,
        isFetching,
    } = useExternalEvents({
        filter: props.filters,
        isDataWareHouse: props.isDataWareHouse,
    });

    return (
        <>
            <ConfirmationPopup
                visible={show}
                CloseBtnText={Heading.CANCEL}
                ConfirmBtnText={Heading.DELETE}
                heading={Heading.DELETE_EVENT}
                subHeading={`${Heading.DELETE_EVENT_SUBTEXT} ${deletedEvent?.name}?`}
                handleClose={handleShow}
                onConfirm={handleConfirm}
                isLoading={isDeleting}
                loadingLabel={ButtonLabel.DELETING}
            />
            {externalEvents?.length ? (
                <InfiniteScroll
                    dataLength={externalEvents?.length}
                    next={handleFetchNextPage}
                    hasMore={Boolean(hasMore)}
                    scrollThreshold={ConstNumber.VALUE_1}
                    loader={undefined}
                >
                    <Row
                        onClick={
                            props.isDataWareHouse ? undefined : navigateToDetail
                        }
                    >
                        {externalEvents?.map((externalEvent, index) => (
                            <EventCard
                                customClass={getEventCardClass(
                                    props.isDataWareHouse
                                )}
                                id={externalEvent.id}
                                key={externalEvent.id}
                                document={externalEvent.document}
                                title={externalEvent.name}
                                date={getFromToDateValue(
                                    externalEvent.display_start_datetime,
                                    externalEvent.display_end_datetime
                                )}
                                time={getFromToTimeValue(
                                    externalEvent.display_start_datetime,
                                    externalEvent.display_end_datetime
                                )}
                                thumbnail_url={externalEvent.thumbnail_url}
                                lg={ConstNumber.VALUE_4}
                                xxl={ConstNumber.VALUE_3}
                                disableDetail={props.isDataWareHouse}
                                actionHandler={
                                    props.isDataWareHouse
                                        ? actionHandler
                                        : undefined
                                }
                                isDelete={props.isDataWareHouse}
                                isEdit={props.isDataWareHouse}
                            />
                        ))}
                    </Row>
                </InfiniteScroll>
            ) : isFetching ? (
                <Row className="d-flex justify-content-center"></Row>
            ) : (
                !isLoadingEvents && (
                    <NoDataFound
                        heading={NoDataEnums.NO_EVENTS_FOUND}
                        subHeading=" "
                    />
                )
            )}
        </>
    );
};

export default ExternalEvents;
