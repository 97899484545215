import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
    errorMsg?: string;
}

const ErrorMessage: React.FC<Props> = ({ errorMsg }) => {
    return Boolean(errorMsg) ? (
        <Form.Control.Feedback type="invalid">{errorMsg}</Form.Control.Feedback>
    ) : null;
};

export default ErrorMessage;
