import { Route, Routes } from 'react-router-dom';
import Login from '../../../modules/onBoarding/screens/Login';
import WelcomeImage from '../../../modules/onBoarding/screens/WelcomeImage';
import { SignUp } from '../../../modules/onBoarding/screens/SignUp';
import { routeObj } from '../../constants/routes.constants';
import Error404 from '../../Error/Error404';
import VerifySuccess from '../../../modules/onBoarding/screens/VerifySuccess';
import ForgotPassword from '../../../modules/onBoarding/screens/ForgotPassword';
import ResetPassword from '../../../modules/onBoarding/screens/ResetPassword';
import BusinessType from '../../../modules/onBoarding/screens/BusinessType';
import SelectPlan from '../../../modules/onBoarding/screens/SelectPlan';

const PublicRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<WelcomeImage />}>
                    <Route index element={<Login />} />
                    <Route path={routeObj.SIGN_UP} element={<SignUp />} />
                    <Route
                        path={routeObj.SIGN_UP_SUCCESS}
                        element={<VerifySuccess />}
                    />
                    <Route
                        path={routeObj.FORGOT_PASSWORD}
                        element={<ForgotPassword />}
                    />
                    <Route
                        path={routeObj.RESET_PASSWORD}
                        element={<ResetPassword />}
                    />
                </Route>
                <Route
                    path={routeObj.SIGN_UP_BUSINESS_TYPE}
                    element={<BusinessType />}
                />
                <Route
                    path={routeObj.SIGN_UP_SELECT_PLAN}
                    element={<SelectPlan />}
                />
                <Route path="*" element={<Error404 />} />
            </Routes>
        </>
    );
};

export default PublicRoutes;
