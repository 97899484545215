import { default as ReactSelect, components, ActionMeta } from 'react-select';
import { ConstNumber } from '../constants/number.constant';
import { Option } from '../utils/types.elements';

const OptionComponent = (
    propsData: any,
    isAllSelected: React.MutableRefObject<boolean>,
    filteredSelectedOptions: Option[]
) => (
    <components.Option {...propsData}>
        {propsData.value === '*' &&
        !isAllSelected.current &&
        filteredSelectedOptions?.length > ConstNumber.VALUE_0 ? (
            <input
                key={propsData.value}
                type="checkbox"
                ref={(input) => {
                    if (input) {
                        input.indeterminate = true;
                    }
                }}
            />
        ) : (
            <input
                key={propsData.value}
                type="checkbox"
                checked={propsData.isSelected || isAllSelected.current}
                onChange={() => {}}
            />
        )}
        <label style={{ marginLeft: '5px' }}>{propsData.label}</label>
    </components.Option>
);

export default OptionComponent;
