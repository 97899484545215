export const firebaseConfig = {
    apiKey: 'AIzaSyDkbeQGNrFedGxR20_PB58Ldn16FhZKQOc',
    authDomain: 'socialyfe-d0909.firebaseapp.com',
    projectId: 'socialyfe-d0909',
    storageBucket: 'socialyfe-d0909.appspot.com',
    messagingSenderId: '412834948202',
    appId: '1:412834948202:web:3c243796321318ae821613',
};
export const vapidKey =
    'BE1wojU1X2Co6xkRs9NA-8Gzmc32hRkmRrIfK-mU9pOX_1hOoucuU5NxTcFpg0f9u4QUgUP75wQZLouchEH7uRw';
