import { createSlice } from '@reduxjs/toolkit';
import { reducerEnums } from '../../modules/onBoarding/constants/enums.onBoarding';

export const notificationStates = createSlice({
    name: reducerEnums.NOTIFICATION,
    initialState: {
        doRedirect: false,
        isNotificationScreen: false,
    },
    reducers: {
        setRedirect: (state) => {
            state.doRedirect = true;
        },
        resetRedirect: (state) => {
            state.doRedirect = false;
        },
        setScreen: (state) => {
            state.isNotificationScreen = true;
        },
        resetScreen: (state) => {
            state.isNotificationScreen = false;
        },
    },
});

export const { setRedirect, resetRedirect, setScreen, resetScreen } = notificationStates.actions;
export default notificationStates.reducer;
