import { useAppDispatch } from '../../../store/hooks';
import { signOutUser } from '../../modules/onBoarding/slices/slice.auth';
import { useLogoutUserMutation } from '../../modules/onBoarding/slices/slice.authApi';
import { getRegistrationToken } from '../PushNotifications/firebase';
import { routeObj } from '../constants/routes.constants';
import { clearStorage } from '../utils/storage.helpers';

export function useLogout() {
    const [logoutUser, { isLoading }] = useLogoutUserMutation();
    const dispatch = useAppDispatch();

    const logout = async () => {
        const res = await logoutUser({
            registration_id: getRegistrationToken() || '',
        });
        if ('data' in res) {
            dispatch(signOutUser())
                .unwrap()
                .then(() => {
                    clearStorage();
                    window.location.href = routeObj.DEFAULT;
                })
                .catch((_error) => {
                    alert(_error.message);
                });
        }
    };
    return {
        logout,
        isLoading,
    };
}
