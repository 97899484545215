import { useLocation } from 'react-router-dom';
import { useSelectPlan } from '../hooks/useSelectPlan';
import Loader from '../../../shared/elements/Loader';
import AuthorizeNetPayment from '../../../shared/elements/AuthorizeNetPayment';
import SubscriptionPlanCard from '../elements/SubscriptionPlanCard';
import { useEffect } from 'react';
import { ButtonLabel } from '../../../shared/constants/html.constant';

const SelectPlan = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const level1Id = parseInt(queryParams.get('id') || '');
    const promoCode = queryParams.get('promo_code');
    const promoId = queryParams.get('promo_id');
    const {
        data,
        gettingPlan,
        isError,
        isLoading,
        submitCreditCard,
        isChargingCC,
        isDisabled,
    } = useSelectPlan({
        level1_id: level1Id,
        promo_code: promoCode ? decodeURIComponent(promoCode) : '',
        promo_id: promoId ? parseInt(promoId) : undefined,
    });

    const handlePopstate = () => {
        window.location.reload();
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('popstate', handlePopstate);
    }, []);

    return (
        <div className="gradient-container">
            <div className="center-full-container">
                <div className="container">
                    <h2>Select a Subscription Plan</h2>
                    <p>
                        Select a subscription plan best suited for your business
                    </p>
                    <div className="subs-inner buy-plan">
                        {isError ? (
                            <p>Something went wrong</p>
                        ) : isLoading ? (
                            <Loader />
                        ) : (
                            <SubscriptionPlanCard
                                description={data?.description}
                                discountedPrice={data?.discounted_price}
                                features={data?.features}
                                name={data?.name}
                                price={data?.price}
                                trialMonths={data?.valid_months}
                            >
                                {isChargingCC || gettingPlan ? (
                                    <Loader />
                                ) : (
                                    <AuthorizeNetPayment
                                        buttonClass="btn btn-primary w-100"
                                        label={
                                            isDisabled
                                                ? ButtonLabel.WAIT
                                                : 'Get Monthly Plan'
                                        }
                                        handleSubmit={submitCreditCard}
                                        isDisabled={isDisabled}
                                    />
                                )}
                            </SubscriptionPlanCard>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectPlan;
