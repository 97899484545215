import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Advertisement,
    CreateAdvertisementForm,
} from '../constants/types.advertising';
import {
    formatUTCDateTime,
    getAdjustedStartDate,
    getS3MediaUrl,
} from '../../../shared/utils/helper';
import { createAdvertisementSchema } from '../utils/utils';
import { FormParams, Option } from '../../../shared/utils/types.elements';
import {
    useCreateAdvertisementMutation,
    useGetAdvertisementByIdQuery,
    useGetMetaDataQuery,
    useUpdateAdvertisementMutation,
} from '../slices/slice.advertising';
import { useNavigate } from 'react-router-dom';
import { ErrorObj } from '../../../shared/types/type';
import {
    ButtonLabel,
    ToastEnum,
} from '../../../shared/constants/html.constant';
import { routeObj } from '../../../shared/constants/routes.constants';
import Toast from '../../../shared/utils/toast.helper';
import {
    EventActionType,
    PromotionTypeEnum,
    TabKeys,
} from '../../../shared/constants/application.constant';
import { uploadTos3 } from '../../../shared/utils/api.meta';
import { getSpecialName } from '../../specials/utils/util';
import { ConstNumber } from '../../../shared/constants/number.constant';
import { ternaryLogic } from '../../../shared/utils/orLogic';
import { useApplyPromocodeMutation } from '../../../shared/slices/slice.meta';
import { useAppDispatch } from '../../../../store/hooks';
import { useChargeCreditCardMutation } from '../../onBoarding/slices/slice.authApi';
import { ChargeCCParams } from '../../onBoarding/utils/slice.types';
import { SubmitButton } from '../../../shared/elements/Buttons';

let globalObject: any;

const useCreateAdvertisement = (params: FormParams) => {
    const navigate = useNavigate();
    const submitBtnRef = useRef<HTMLButtonElement | null>(null);
    const {
        handleSubmit,
        setValue,
        register,
        trigger,
        formState: { errors, isValid, isSubmitting },
        getValues,
        watch,
    } = useForm({
        defaultValues: {
            id: undefined,
            name: '',
            start_datetime: getAdjustedStartDate().toDate(),
            age_target: '',
            views: '',
            placement: '',
        },
        resolver: yupResolver(createAdvertisementSchema),
        mode: 'onChange',
    });
    const [ageTargets, setAgeTargets] = useState<Option[]>([]);
    const [adPlacements, setAdPlacements] = useState<Option[]>([]);
    const [adViewsPack, setAdViewsPack] = useState<Option[]>([]);
    const [show, setShow] = useState(false);
    const { id, type, activeTab } = params;
    const [selectedTarget, setSelectedTarget] = useState<Option | null>(null);
    const [selectedPlacement, setSelectedPlacement] = useState<Option | null>(
        null
    );
    const [selectedViewsPack, setSelectedViewsPack] = useState<Option | null>(
        null
    );
    const [ccEncToken, setCcEncToken] = useState('');

    const { data: metaData, isLoading: isLoadingMeta } = useGetMetaDataQuery(
        {}
    );
    const [
        createAdvertisement,
        {
            isLoading: isLoadingCreate,
            error: apiErrorCreate,
            data: createResponse,
        },
    ] = useCreateAdvertisementMutation();
    const { data: advertisementDataById, isLoading: isDataFethcing } =
        useGetAdvertisementByIdQuery(
            {
                id,
                adType: ternaryLogic(
                    activeTab === TabKeys.ONGOING_ADS,
                    ConstNumber.VALUE_0,
                    ConstNumber.VALUE_1
                ),
            },
            {
                skip: !Boolean(id),
            }
        );
    const [
        updateAdvertisement,
        { isLoading: isUpdating, error: updateError, data: updateData },
    ] = useUpdateAdvertisementMutation();
    const [
        applyPromoCode,
        {
            isSuccess: isPromoApplied,
            data: promoData,
            reset: resetPromo,
            error: promoError,
            isLoading: promoLoading,
        },
    ] = useApplyPromocodeMutation({});
    const [
        chargeCreditCard,
        {
            isLoading: isChargingCC,
            isSuccess: isPaymentSuccess,
            error: paymentError,
            data: paymentData,
        },
    ] = useChargeCreditCardMutation();

    const couponHandler = async (coupon: string) => {
        await applyPromoCode({
            code: coupon,
            type: PromotionTypeEnum.ADVERTISEMENT,
        });
    };

    useEffect(() => {
        if (promoError) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (promoError as ErrorObj)?.message
            );
        }
    }, [promoError]);

    useEffect(() => {
        if (paymentError) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (paymentError as ErrorObj).message
            );
        }
    }, [paymentError]);

    const handleChange = useCallback(
        (selected: Option, key: any): void => {
            setValue(key, selected.value.toString());
            trigger(key);
            if (key === 'age_target') {
                setSelectedTarget(selected);
            } else if (key === 'placement') {
                setSelectedPlacement(selected);
            } else if (key === 'views' && metaData?.ad_plans.length) {
                setSelectedViewsPack(selected);
                const selectedView = metaData.ad_plans.find(
                    (ad) => ad.id === selected.value
                );
                if (selectedView) {
                    setValue('plan_price' as any, `$${selectedView.price}`);
                    trigger('plan_price' as any);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setValue, trigger, metaData]
    );

    const handleDateChange = (date: Date | null, dateType: string) => {
        setValue(dateType as any, date);
        trigger(dateType as any);
    };

    const onSubmitForm = async (values: CreateAdvertisementForm) => {
        let isUploaded = false;
        if (values?.document && values?.s3MediaUrl) {
            isUploaded = await uploadTos3(values.s3MediaUrl, values.document);
        }
        if (id && type === EventActionType.EDIT) {
            await updateAdvertisement({
                data: {
                    ...values,
                    s3MediaUrl: getS3MediaUrl(isUploaded, values.s3MediaUrl),
                },
                initialValues: globalObject as CreateAdvertisementForm,
            });
        } else {
            const requestObj: ChargeCCParams = {
                plan_id: selectedViewsPack?.value as number,
            };
            if (promoData?.promo_id) {
                requestObj.promo_code = promoData?.promo_id;
            }
            if (ccEncToken) {
                requestObj.token = ccEncToken;
            }
            const res = await chargeCreditCard(requestObj);
            if ('data' in res) {
                await createAdvertisement({
                    ...values,
                    user_payment_plan: res.data.data.user_payment_plan_id,
                    s3MediaUrl: getS3MediaUrl(isUploaded, values.s3MediaUrl),
                });
            }
        }
    };

    const setInitialValues = useCallback(async (_data?: Advertisement) => {
        if (_data !== undefined) {
            const obj = {
                id: _data.id,
                name: _data.name,
                start_datetime: formatUTCDateTime(
                    _data.start_datetime,
                    _data.start_datetime
                ),
                age_target: _data.age_target,
                views: _data.allow_views,
                placement: _data.placement,
                document: _data.document,
            };
            // this globalobject to keep track of the intial values fetched from the api
            globalObject = obj;
            setValue('id', obj.id);
            setValue('name', getSpecialName(obj.name, type));
            setValue('start_datetime', new Date(obj.start_datetime));
            setValue('age_target', obj.age_target);
            setValue('placement', obj.placement);
            setValue('views', obj.views?.toString() || '');
            setValue('document' as any, obj.document);
            trigger();
        }
    }, []);

    useEffect(() => {
        if (metaData?.entertainment_type.length) {
            const entertainmentData: Option[] =
                metaData?.entertainment_type?.map((typeData) => {
                    return {
                        value: typeData.id,
                        label: typeData.name,
                    };
                });
            setAdPlacements(entertainmentData);
        }
        if (metaData?.ad_plans.length) {
            const viewsData: Option[] = metaData?.ad_plans?.map((view) => {
                return {
                    value: view.id,
                    label: `${view.plan_views} ${
                        view.plan_views > ConstNumber.VALUE_1 ? 'Views' : 'View'
                    }`,
                };
            });
            setAdViewsPack(viewsData);
        }
        if (metaData?.age_target.length) {
            const ageTargetData: Option[] = metaData?.age_target?.map(
                (target) => {
                    return {
                        value: target.id,
                        label: target.name,
                    };
                }
            );
            setAgeTargets(ageTargetData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metaData]);

    useEffect(() => {
        if (createResponse?.message) {
            Toast.success(ToastEnum.AD_CREATED, createResponse?.message);
            navigate(routeObj.BUSINESS_ADVERTISING);
        } else if (apiErrorCreate) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (apiErrorCreate as ErrorObj)?.message
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createResponse?.message, apiErrorCreate]);

    useEffect(() => {
        if (ageTargets.length && advertisementDataById?.age_target) {
            const targetData = ageTargets.find(
                (terget) => terget.value === advertisementDataById?.age_target
            );
            if (targetData) {
                setSelectedTarget(targetData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ageTargets, advertisementDataById]);

    useEffect(() => {
        if (adPlacements.length && advertisementDataById?.placement) {
            const placementData = adPlacements.find(
                (placement) =>
                    placement.value === advertisementDataById?.placement
            );
            if (placementData) {
                setSelectedPlacement(placementData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adPlacements, advertisementDataById]);

    const getSelectedViewData = (packId: number | string) => {
        return metaData?.ad_plans.find((ad) => ad.id === packId);
    };

    useEffect(() => {
        if (adViewsPack.length && advertisementDataById?.allow_views) {
            const packData = adViewsPack.find(
                (pack) => pack.value === advertisementDataById?.allow_views
            );
            if (packData && metaData?.ad_plans.length) {
                setSelectedViewsPack(packData);
                const selectedViewData = getSelectedViewData(packData.value);
                setValue(
                    'plan_price' as any,
                    `$${selectedViewData?.price}` || ''
                );
                trigger('plan_price' as any);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adViewsPack, advertisementDataById]);

    useEffect(() => {
        if (updateData?.message) {
            Toast.success(ToastEnum.AD_UPDATED, updateData?.message);
            navigate(routeObj.BUSINESS_ADVERTISING);
        } else if (updateError) {
            Toast.error(
                ToastEnum.SOMETHING_WENT_WRONG,
                (updateError as ErrorObj)?.message
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData?.message, updateError]);

    const getTotalAmount = () => {
        if (selectedViewsPack) {
            const selectedViewData = getSelectedViewData(
                selectedViewsPack?.value
            );
            const price = Number(selectedViewData?.price);
            const totalAmount = promoData?.discount
                ? price - (price / ConstNumber.VALUE_100) * promoData.discount
                : price;
            return totalAmount.toFixed(ConstNumber.VALUE_2);
        }
        return '';
    };

    function checkDisable() {
        return !isValid || !getValues('document' as any) || isSubmitting;
    }

    const handlePaySubmit = (cardEncData: any) => {
        setCcEncToken(() => cardEncData.opaqueData.dataValue as string);
        setTimeout(() => {
            submitBtnRef.current?.click();
        }, ConstNumber.VALUE_1000);
    };

    const GetSubmitBtn = () => {
        return (
            <SubmitButton
                isDisable={checkDisable()}
                isLoading={isSubmitting}
                label={ternaryLogic(
                    id && type === EventActionType.EDIT,
                    ButtonLabel.UPDATE,
                    ButtonLabel.PUBLISH
                )}
                loadingLabel={ternaryLogic(
                    id && type === EventActionType.EDIT,
                    ButtonLabel.UPDATING,
                    ButtonLabel.PUBLISHING
                )}
            />
        );
    };

    return {
        handleSubmit,
        onSubmitForm,
        setValue,
        register,
        watch,
        errors,
        isValid,
        getValues,
        handleDateChange,
        setShow,
        show,
        ageTargets,
        adPlacements,
        adViewsPack,
        handleChange,
        advertisementDataById,
        setInitialValues,
        selectedTarget,
        selectedPlacement,
        selectedViewsPack,
        id,
        type,
        isSubmitting,
        couponHandler,
        isPromoApplied,
        promoData,
        promoLoading,
        setCcEncToken,
        resetPromo,
        getTotalAmount,
        duplicateCSS: type === EventActionType.DUPLICATE,
        isLoadingMeta,
        isDataFethcing,
        checkDisable,
        submitBtnRef,
        handlePaySubmit,
        GetSubmitBtn,
    };
};

export default useCreateAdvertisement;
