import {
    PromotionTypeEnum,
    TabKeys,
    ValidationEnums,
} from '../../../shared/constants/application.constant';
import * as yup from 'yup';
import { ConstNumber } from '../../../shared/constants/number.constant';
import {
    EMAIL_REGEX,
    POSITIVE_WHOLE_NUMBER,
} from '../../../shared/constants/regex.constant';
import { FormParams } from '../../../shared/utils/types.elements';
import { ternaryLogic } from '../../../shared/utils/orLogic';

export function getPromotionFormIntialValues(params: FormParams) {
    return {
        name: '',
        email: '',
        discount: '',
        description: '',
        promotion_type: ternaryLogic(
            params?.activeTab === TabKeys.SUBSCRIPTION_PLANS,
            PromotionTypeEnum.SUBSCRIPTION,
            PromotionTypeEnum.ADVERTISEMENT
        ) as number,
        valid_months: '',
        promotion_plan_type: '',
    };
}

export const promotionFormSchema = yup.object().shape({
    promotion_type: yup.number().required(ValidationEnums.REQUIRED),
    name: yup
        .string()
        .trim()
        .max(ConstNumber.VALUE_8, ValidationEnums.MAX_8)
        .required(ValidationEnums.REQUIRED),
    email: yup
        .string()
        .email(ValidationEnums.EMAIL_FORMAT)
        .test('is-email-valid', ValidationEnums.EMAIL_FORMAT, function (value) {
            if (value && !EMAIL_REGEX.test(value)) {
                return false;
            }
            return true;
        })
        .optional(),
    description: yup
        .string()
        .trim()
        .max(ConstNumber.VALUE_1000, ValidationEnums.MAX_1000)
        .required(ValidationEnums.REQUIRED),
    promotion_plan_type: yup.string().required(ValidationEnums.REQUIRED),
    discount: yup
        .string()
        .test(
            'is-discount-valid',
            ValidationEnums.VALID_PERCENTAGE,
            function (value) {
                if (value) {
                    if (!POSITIVE_WHOLE_NUMBER.test(value)) {
                        return false;
                    }
                    const numberValue = parseInt(value, ConstNumber.VALUE_10);
                    if (
                        numberValue < 1 ||
                        numberValue > ConstNumber.VALUE_100
                    ) {
                        return false;
                    }
                }
                return true;
            }
        )
        .optional(),
    valid_months: yup.string().optional(),
});
