import { ConstNumber } from '../../../shared/constants/number.constant';
import useNotificationList from '../hooks/useNotificationList';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoDataFound from '../../../shared/elements/NoDataFound';
import { NoDataEnums } from '../../../shared/constants/html.constant';
import NotificationTile from '../elements/NotificationTile';

const NotificationList = () => {
    const {
        notifications,
        hasMore,
        handleFetchNextPage,
        isLoadingNotifications,
    } = useNotificationList();

    return (
        <div className="notification-container">
            {notifications?.length ? (
                <div className="notification-inner-content">
                    <InfiniteScroll
                        dataLength={notifications?.length}
                        next={handleFetchNextPage}
                        hasMore={Boolean(hasMore)}
                        scrollThreshold={ConstNumber.VALUE_1}
                        loader={undefined}
                    >
                        {notifications?.map((notification, index) => (
                            <NotificationTile
                                notification={notification}
                                key={notification.id}
                            />
                        ))}
                    </InfiniteScroll>
                </div>
            ) : (
                !isLoadingNotifications && (
                    <NoDataFound
                        heading={NoDataEnums.NO_NOTIFICATION_FOUND}
                        subHeading=" "
                    />
                )
            )}
        </div>
    );
};

export default NotificationList;
