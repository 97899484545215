import { Col, Form } from 'react-bootstrap';
import { DateFormats } from '../../../shared/constants/application.constant';
import { DATEICON } from '../../../shared/constants/image.constant';
import { CustomDatePicker } from '../../../shared/elements/CustomInput';
import {
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';

type Props = {
    setValue: UseFormSetValue<any>;
    register: UseFormRegister<any>;
    watch: UseFormWatch<any>;
    handleDateChange: (date: Date | null, name: string) => void;
    errors: any;
};

const DateElements = (props: Props) => {
    const { errors, handleDateChange, register, watch, setValue } = props;
    return (
        <>
            <Col md={6}>
                <Form.Group className="form-group" controlId="">
                    <Form.Label>Start Date</Form.Label>
                    <CustomDatePicker
                        name="start_date"
                        register={register}
                        icon={DATEICON}
                        watch={watch}
                        disablePastDate
                        handleDateChange={handleDateChange}
                        errorMsg={errors.start_date?.message}
                    />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="form-group" controlId="">
                    <Form.Label>End Date</Form.Label>
                    <CustomDatePicker
                        name="end_date"
                        register={register}
                        icon={DATEICON}
                        watch={watch}
                        disablePastDate
                        handleDateChange={handleDateChange}
                        errorMsg={errors.end_date?.message}
                    />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="form-group" controlId="">
                    <Form.Label>Start Time</Form.Label>
                    <CustomDatePicker
                        name="start_time"
                        register={register}
                        timeCaption="Time"
                        timeIntervals={15}
                        dateFormat={DateFormats.TIME_WITH_MARIDIAN}
                        setValue={setValue}
                        watch={watch}
                        handleDateChange={handleDateChange}
                        errorMsg={errors.start_time?.message}
                        showTimeSelect
                        showTimeSelectOnly
                    />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="form-group" controlId="">
                    <Form.Label>End Time</Form.Label>
                    <CustomDatePicker
                        name="end_time"
                        register={register}
                        timeCaption="Time"
                        timeIntervals={15}
                        dateFormat={DateFormats.TIME_WITH_MARIDIAN}
                        setValue={setValue}
                        watch={watch}
                        handleDateChange={handleDateChange}
                        errorMsg={errors.end_time?.message}
                        showTimeSelect
                        showTimeSelectOnly
                    />
                </Form.Group>
            </Col>
        </>
    );
};

export default DateElements;
