import { useLocation } from 'react-router-dom';
import {
    useCreateCategoryLevelThreeMutation,
    useCreateCategorySubLevelThreeMutation,
    useDeleteLevel3Mutation,
    useGetCategoryLevelThreeQuery,
    useUpdateCategorySubLevelThreeMutation,
} from '../slices/slice.categories';
import {
    createLevelThreeSchema,
    createSubLevelThreeSchema,
    getCreateLevelThreeIntialValues,
    getCreateSubLevelThreeIntialValues,
} from '../utils/utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import {
    CategoryLevel3,
    CategoryLevelOne,
    CategoryLevelThree,
    CategoryLevelThreeFormRequest,
    CategorySubLevelThreeFormRequest,
} from '../constants/types.categories';
import { uploadTos3 } from '../../../shared/utils/api.meta';
import { getS3MediaUrl } from '../../../shared/utils/helper';

interface Params {
    id: number;
    name: string;
    level_one: CategoryLevelOne;
    active_tab?: string | null;
}

const useAdminLevel3Categories = () => {
    const params = useLocation().state as Params;
    const [show, setShow] = useState(false);
    const [showSub, setShowSub] = useState(false);
    const [selectedHeading, setSelectedHeading] =
        useState<CategoryLevelThree | null>(null);
    const [showMedia, setShowMedia] = useState(false);
    const [selectedLevel3, setSelectedLevel3] = useState<CategoryLevel3 | null>(
        null
    );
    const [preImage, setPreImage] = useState('');
    const {
        handleSubmit,
        formState: { errors, isValid },
        trigger,
        reset,
        register,
    } = useForm({
        defaultValues: getCreateLevelThreeIntialValues(params?.id),
        resolver: yupResolver(createLevelThreeSchema),
    });
    const {
        handleSubmit: handleSubSubmit,
        formState: { errors: subErrors, isValid: isSubValid },
        trigger: subTrigger,
        reset: subReset,
        register: subRegister,
        setValue,
        getValues,
    } = useForm({
        defaultValues: getCreateSubLevelThreeIntialValues(),
        resolver: yupResolver(createSubLevelThreeSchema),
    });
    const {
        data: level3Category,
        isLoading,
        refetch,
    } = useGetCategoryLevelThreeQuery(params?.id, {
        skip: !Boolean(params?.id),
    });
    const [
        createCategoryLevelThree,
        {
            isLoading: isLoadingLevelThree,
            error: apiError,
            data: createLevelThreeResponse,
        },
    ] = useCreateCategoryLevelThreeMutation();
    const [
        createCategorySubLevelThree,
        {
            isLoading: isLoadingSubLevelThree,
            error: apiSubError,
            data: createSubLevelThreeResponse,
        },
    ] = useCreateCategorySubLevelThreeMutation();
    const [
        updateCategorySubLevelThree,
        {
            isLoading: isLoadingUpdateSubLevelThree,
            error: apiUpdateSubError,
            data: updateSubLevelThreeResponse,
        },
    ] = useUpdateCategorySubLevelThreeMutation();

    // delete category level one states.
    const [deletedCategory, setDeletedCategory] = useState<number | undefined>(
        undefined
    );
    const [showDelete, setShowDelete] = useState(false);
    const [isHeading, setIsHeading] = useState<boolean>(true);
    const [
        deleteLevel3,
        {
            isLoading: isDeletingLevel3,
            error: deleteErrorLevel3,
            data: deleteResLevel3,
        },
    ] = useDeleteLevel3Mutation();

    const handleShow = () => {
        setShow(!show);
    };

    const handleShowSub = () => {
        setShowSub(!showSub);
    };

    const onClickAdd = (
        value: CategoryLevelThree,
        level3: CategoryLevel3 | null
    ) => {
        setSelectedLevel3(level3);
        setSelectedHeading(value);
        handleShowSub();
    };

    const onSubmitForm = useCallback(
        async (values: CategoryLevelThreeFormRequest): Promise<void> => {
            await createCategoryLevelThree(values);
        },
        []
    );

    const onSubSubmitForm = useCallback(
        async (values: CategorySubLevelThreeFormRequest): Promise<void> => {
            let isUploaded = false;
            if (values?.icon && values?.s3MediaUrl) {
                isUploaded = await uploadTos3(values.s3MediaUrl, values.icon);
            }
            await createCategorySubLevelThree({
                ...values,
                s3MediaUrl: getS3MediaUrl(isUploaded, values.s3MediaUrl),
            });
        },
        []
    );

    const onSubUpdateForm = useCallback(
        async (values: CategorySubLevelThreeFormRequest): Promise<void> => {
            let isUpload = false;
            if (values?.icon && values?.s3MediaUrl) {
                isUpload = await uploadTos3(values.s3MediaUrl, values.icon);
            }
            await updateCategorySubLevelThree({
                data: {
                    ...values,
                    s3MediaUrl: getS3MediaUrl(isUpload, values.s3MediaUrl),
                },
                initialValues: preImage,
            });
        },
        []
    );

    const handleDelete = () => {
        setShowDelete(!showDelete);
    };

    const deleteHandler = (value: number, type: boolean) => {
        setDeletedCategory(value);
        setIsHeading(type);
        handleDelete();
    };

    const confirmDelete = () => {
        deletedCategory &&
            deleteLevel3({
                id: deletedCategory,
                isHeading,
            });
    };

    return {
        level3Category,
        isLoading,
        params,
        handleSubmit,
        onSubmitForm,
        register,
        errors,
        isValid,
        reset,
        trigger,
        handleShow,
        show,
        isLoadingLevelThree,
        apiError,
        createLevelThreeResponse,
        refetch,
        onSubSubmitForm,
        handleSubSubmit,
        subRegister,
        subErrors,
        isSubValid,
        subReset,
        subTrigger,
        handleShowSub,
        showSub,
        onClickAdd,
        selectedHeading,
        setValue,
        isLoadingSubLevelThree,
        apiSubError,
        createSubLevelThreeResponse,
        getValues,
        setShowMedia,
        showMedia,
        showDelete,
        handleDelete,
        confirmDelete,
        isDeletingLevel3,
        deleteHandler,
        deleteErrorLevel3,
        deleteResLevel3,
        selectedLevel3,
        setPreImage,
        onSubUpdateForm,
        isLoadingUpdateSubLevelThree,
        apiUpdateSubError,
        updateSubLevelThreeResponse,
    };
};

export default useAdminLevel3Categories;
