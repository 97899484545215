import React, { FC } from 'react';
import { Spinner as LoadingIcon } from 'react-bootstrap';

interface Props {
    customClass?: string;
    size?: 'sm';
}

const Loader: FC<Props> = ({ customClass, size }) => {
    return (
        <div
            className={
                customClass ||
                'spinner d-flex justify-content-center align-items-center p-4'
            }
            data-testid="progressbar"
        >
            <LoadingIcon
                color="#47B5FF"
                animation="border"
                variant="dark"
                size={size}
            />
        </div>
    );
};

export default Loader;
