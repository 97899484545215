import React from 'react';
import { ConstNumber } from '../../app/shared/constants/number.constant';

interface IconProps {
    width?: number;
    height?: number;
    stroke?: string;
    strokeWidth?: number;
}

export const Tick = React.memo(
    ({
        width = ConstNumber.VALUE_10,
        height = ConstNumber.VALUE_8,
        stroke = '#B3B3B3',
        strokeWidth = ConstNumber.VALUE_1,
        ...otherProps
    }: IconProps) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                stroke={stroke}
                strokeWidth={strokeWidth}
                {...otherProps}
                viewBox="0 0 10 8"
                fill="none"
            >
                <path
                    d="M3.99994 5.58847L8.33298 1L9 1.70576L3.99994 7L1 3.82356L1.66655 3.11779L3.99994 5.58847Z"
                    fill="#1E1E1E"
                    stroke={stroke}
                />
            </svg>
        );
    }
);
