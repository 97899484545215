import { lazy } from 'react';
import { routeObj } from '../../constants/routes.constants';
import { UserRole } from '../../constants/application.constant';
import CreateSpecial from '../../../modules/specials/screen/CreateSpecial';
import UnderDevelopment from '../../elements/UnderDevelopment';
import Events from '../../../modules/events/screen/Events';
import CreateEvent from '../../../modules/events/screen/CreateEvent';
import EventDetails from '../../../modules/events/screen/EventDetails';
import CreateAdvertisement from '../../../modules/advertising/screens/CreateAdvertisement';
import SubCategories from '../../../adminModules/categories/screen/SubCategories';
import AdminContainer from '../../../adminModules/Businesses/screen/AdminContainer';
import CreateSubscriptionPlan from '../../../adminModules/subscriptions/screen/CreateSubscriptionPlan';
import APIEvents from '../../../adminModules/apiEvents/screen/APIEvents';
import Promotion from '../../../adminModules/promotion/screen/Promotion';
import NotificationList from '../../../modules/notificationList/screen/NotificationList';

/** Business dynamic imports */
const BusinessLayout = lazy(() => import('../../layout/BusinessLayout'));
const Dashboard = lazy(
    () => import('../../../modules/businessDashboard/screen/Dashboard')
);
const Specials = lazy(
    () => import('../../../modules/specials/screen/Specials')
);

const VenueLocation = lazy(
    () => import('../../../modules/businessInfo/screen/VenueLocation')
);

const VenueDemographics = lazy(
    () => import('../../../modules/businessInfo/screen/VenueDemographics')
);
const VenueDetails = lazy(
    () => import('../../../modules/businessInfo/screen/VenueDetails')
);
const Subscription = lazy(
    () => import('../../../modules/subscription/screen/Subscription')
);
const Advertising = lazy(
    () => import('../../../modules/advertising/screens/Advertising')
);

/**Admin dynamic imports */
const AdminLayout = lazy(() => import('../../layout/AdminLayout'));

const Categories = lazy(
    () => import('../../../adminModules/categories/screen/Categories')
);
const BusinessSearch = lazy(
    () => import('../../../adminModules/Businesses/screen/BusinessSearch')
);
const BusinessDetail = lazy(
    () => import('../../../adminModules/Businesses/screen/BusinessDetail')
);
const ExternalBusinessInformation = lazy(
    () =>
        import(
            '../../../adminModules/apiEvents/screen/ExternalBusinessInformation'
        )
);
const ExternalEventInformation = lazy(
    () => import('../../../adminModules/apiEvents/screen/ExternalEventDetail')
);
const Warehouse = lazy(
    () => import('../../../adminModules/warehouse/screen/Warehouse')
);
const EditWarehouseEvent = lazy(
    () => import('../../../adminModules/warehouse/screen/EditWarehouseEvent')
);
const PromotionCode = lazy(
    () => import('../../../adminModules/promotion/screen/PromotionCode')
);
const Notification = lazy(
    () => import('../../../adminModules/notification/screen/Notification')
);
const AdminUsers = lazy(
    () => import('../../../adminModules/users/screen/AdminUsers')
);
const Subscriptions = lazy(
    () => import('../../../adminModules/subscriptions/screen/Subscriptions')
);

const ClaimEventRequest = lazy(
    () => import('../../../adminModules/claimEvents/screens/ClaimEventRequest')
);
const ClaimEventDetail = lazy(
    () => import('../../../adminModules/claimEvents/screens/ClaimEventDetail')
);

export interface PrivateRoutes {
    component: JSX.Element | React.FC<{}>;
    exact?: boolean;
    path?: string;
    permission: Array<string>;
    children?: PrivateRoutes[];
}

export const routesConfig: PrivateRoutes[] = [
    {
        component: BusinessLayout,
        path: routeObj.BUSINESS_DASHBOARD,
        exact: true,
        permission: [UserRole.BUSINESS],
        children: [
            {
                component: Dashboard,
                permission: [UserRole.BUSINESS],
            },
            {
                component: Specials,
                permission: [UserRole.BUSINESS],
                path: routeObj.BUSINESS_SPECIALS,
            },
            {
                component: VenueLocation,
                permission: [UserRole.BUSINESS],
                path: routeObj.BUSINESS_VENUE_LOCATIONS,
            },
            {
                // Component reused for the locations
                component: VenueDetails,
                permission: [UserRole.BUSINESS],
                path: routeObj.BUSINESS_VENUE_LOCATIONS_DETAILS,
            },
            {
                component: VenueDemographics,
                permission: [UserRole.BUSINESS],
                path: routeObj.BUSINESS_VENUE_DEMOGRAPHICS,
            },
            {
                component: VenueDetails,
                permission: [UserRole.BUSINESS],
                path: routeObj.BUSINESS_VENUE_DETAILS,
            },
            {
                component: Subscription,
                permission: [UserRole.BUSINESS],
                path: routeObj.BUSINESS_SUBSCRIPTION,
            },
            {
                component: CreateSpecial,
                permission: [UserRole.BUSINESS],
                path: routeObj.BUSINESS_SPECIAL_CREATE,
            },
            {
                component: CreateSpecial,
                permission: [UserRole.BUSINESS],
                path: routeObj.BUSINESS_SPECIAL_EDIT,
            },
            {
                component: Events,
                permission: [UserRole.BUSINESS],
                path: routeObj.BUSINESS_EVENTS,
            },
            {
                component: CreateEvent,
                permission: [UserRole.BUSINESS],
                path: routeObj.BUSINESS_EVENT_CREATE,
            },
            {
                component: EventDetails,
                permission: [UserRole.BUSINESS],
                path: routeObj.BUSINESS_EVENT_DETAIL,
            },
            {
                component: Categories,
                permission: [UserRole.BUSINESS],
                path: routeObj.ADMIN_BUSINESS_CATEGORIES,
            },
            {
                component: Advertising,
                permission: [UserRole.BUSINESS],
                path: routeObj.BUSINESS_ADVERTISING,
            },
            {
                component: CreateAdvertisement,
                permission: [UserRole.BUSINESS],
                path: routeObj.BUSINESS_ADVERTISING_CREATE,
            },
            {
                component: NotificationList,
                permission: [UserRole.BUSINESS],
                path: routeObj.BUSINESS_NOTIFICATION,
            },
        ],
    },
    /**
     * Admin Role will be configured here,
     *
     */
    {
        component: AdminLayout,
        path: routeObj.ADMIN,
        exact: true,
        permission: [UserRole.SUPER_ADMIN, UserRole.SUB_ADMIN],
        children: [
            {
                component: AdminContainer,
                permission: [UserRole.SUPER_ADMIN, UserRole.SUB_ADMIN],
            },
            {
                component: BusinessSearch,
                path: routeObj.ADMIN_BUSINESSES,
                permission: [UserRole.SUPER_ADMIN, UserRole.SUB_ADMIN],
            },
            {
                component: BusinessDetail,
                path: routeObj.ADMIN_BUSINESS_DETAILS,
                permission: [UserRole.SUPER_ADMIN, UserRole.SUB_ADMIN],
            },
            {
                component: Categories,
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_BUSINESSES_CATEGORIES,
            },
            {
                component: ClaimEventRequest,
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_EVENT_CLAIM,
            },
            {
                component: APIEvents,
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_API_EVENTS,
            },
            {
                component: ExternalEventInformation,
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_API_EVENTS_DETAIL,
            },
            {
                component: ExternalBusinessInformation,
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_API_BUSINESS_DETAIL,
            },
            {
                component: Warehouse,
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_DATA_WAREHOUSE,
            },
            {
                component: EditWarehouseEvent, // lazy load
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_WAREHOUSE_EVENT_DETAIL,
            },
            {
                component: EventDetails,
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_WAREHOUSE_EVENT_VENUE,
            },
            {
                component: PromotionCode,
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_PROMOTION_CODE,
            },
            {
                component: Promotion,
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_CREATE_PROMOTION_CODE,
            },
            {
                component: Notification,
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_NOTIFICATION,
            },
            {
                component: AdminUsers,
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_USERS,
            },
            {
                component: SubCategories,
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_CATEGORIES_LEVEL3,
            },
            {
                component: Subscriptions,
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_SUBSCRIPTIONS,
            },
            {
                component: CreateSubscriptionPlan,
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_SUBSCRIPTIONS_CREATE,
            },
            {
                component: ClaimEventDetail,
                permission: [UserRole.SUPER_ADMIN],
                path: routeObj.ADMIN_CLAIM_EVENTS_DETAIL,
            },
        ],
    },
];
